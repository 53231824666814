import { httpPromise } from "services/common/httpPromise";
import { ServiceConstant } from "common/constant";
export var getVendorPaymentList = function (vendorID, vendorPaymentStatus) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/GetVendorPayments?vendorID=").concat(vendorID, "&vendorPaymentStatus=").concat(vendorPaymentStatus));
};
export var addVendorPayment = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH), postData);
};
export var deleteVendorPayment = function (vendorPaymentId) {
    return httpPromise.delete("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "?vendorPaymentId=").concat(vendorPaymentId));
};
export var addVendorPaymentDetails = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.VENDOR_DETAILS_PAYMENT_API), postData);
};
export var fetchVendorPaymentDetailsList = function (vendorPaymentId) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_DETAILS_PAYMENT_API, "/GetVendorPaymentDetails?vendorPaymentId=").concat(vendorPaymentId));
};
export var fetchVendorPaymentDetailsListVZ = function (vendorPaymentId) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_DETAILS_PAYMENT_API, "/GetVendorPaymentVZDetails?vendorPaymentId=").concat(vendorPaymentId));
};
export var deleteVendorPaymentDetail = function (vendorPaymentDetailId) {
    return httpPromise.delete("".concat(ServiceConstant.VENDOR_DETAILS_PAYMENT_API, "?vendorPaymentDetailId=").concat(vendorPaymentDetailId));
};
export var fetchVendorPaymentById = function (vendorPaymentId) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/GetVendorPaymentById?vendorPaymentId=").concat(vendorPaymentId));
};
export var postSignAndSaveVendorPayment = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/SignVendorPayment"), postData);
};
export var fetchVendorPaymentDetailsById = function (vendorPaymentDetailId) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_DETAILS_PAYMENT_API, "/GetVendorPaymentDetailById?vendorPaymentDetailId=").concat(vendorPaymentDetailId));
};
export var getVendorPaymentBackup = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_PAYMENT_FILE_API_PATH, "/GetVendorPaymentBackup?").concat(queryString));
};
export var deleteVendorPaymentFile = function (queryString) {
    return httpPromise.delete("".concat(ServiceConstant.VENDOR_PAYMENT_FILE_API_PATH, "/DeleteVendorPaymentFile?").concat(queryString));
};
export var uploadPaymentBackupReceipt = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/AddVendorPaymentFileBackup"), postData);
};
export var fetchVendorDocumentList = function (VendorID) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_DOCUMENT_API_PATH, "/GetVendorDocuments?vendorID=").concat(VendorID));
};
export var postVendorDocument = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.VENDOR_DOCUMENT_API_PATH, "/AddVendorDocument"), postData);
};
export var getVendorSearch = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/SearchVendors?").concat(queryString));
};
export var getSearchVendorPayment = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/SearchVendorPayments?").concat(queryString));
};
export var getVendorPaymentStatus = function () {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/GetVendorPaymentStatuses"));
};
export var checkVendorInsurance = function (VendorID) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_DOCUMENT_API_PATH, "/CheckVendorInsurance?vendorID=").concat(VendorID));
};
export var getApproveVendorPayment = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/GetVendorPaymentsToApprove?").concat(queryString));
};
export var vendorPaymentValidation = function (vendorPaymentID) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/ValidateVendorPayments?vendorPaymentID=").concat(vendorPaymentID));
};
export var postApproveSelectedVendorPayment = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/ApproveVendorPayments"), postData);
};
export var saveReturntoVendor = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/ReturnVendorPaymentToVendor"), postData);
};
export var getVendorPaymentStatusHistory = function (queryString) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/GetVendorPaymentStatusHistory?").concat(queryString));
};
export var recallVendorPayment = function (postData) {
    return httpPromise.post("".concat(ServiceConstant.VENDOR_PAYMENT_API_PATH, "/ReturnVendorPaymentToVendor"), postData);
};
export var saveNewAccount = function (queryString) {
    return httpPromise.put("".concat(ServiceConstant.VENDOR_DETAILS_PAYMENT_API, "/saveNewAccount?").concat(queryString));
};
export var saveNewSubAccount = function (queryString) {
    return httpPromise.put("".concat(ServiceConstant.VENDOR_DETAILS_PAYMENT_API, "/SaveNewSubAccount?").concat(queryString));
};
export var saveNewSubAccountVZ = function (queryString) {
    return httpPromise.put("".concat(ServiceConstant.VENDOR_DETAILS_PAYMENT_API, "/SaveNewSubAccountVZ?").concat(queryString));
};
export var getVendorCode = function (clientId) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/GetVendorCode?clientID=").concat(clientId));
};
export var postVendorInfo = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/PostVendorInfo"), dataObj);
};
export var getVendorInfo = function (vendorId) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/GetEditVendorInfo?vendorID=").concat(vendorId));
};
export var getDDInfo = function (vendorId) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/GetEditVendorDDInfo?vendorID=").concat(vendorId));
};
export var getAccountType = function () {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/GetAccountType"));
};
export var postVendorDDInfo = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/PostVendorDDInfo"), dataObj);
};
export var getVendorDocuments = function (vendorId) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_DOCUMENT_API_PATH, "/GetVendorDocuments?vendorID=").concat(vendorId));
};
export var deleteVendorDocument = function (dataObj) {
    return httpPromise.delete("".concat(ServiceConstant.VENDOR_DOCUMENT_API_PATH, "/DeleteVendorDocument"), {
        data: dataObj,
    });
};
export var getDocumentDetails = function (vendorDocumentID) {
    return httpPromise.get("".concat(ServiceConstant.VENDOR_DOCUMENT_API_PATH, "/GetVendorDocument?vendorDocumentID=").concat(vendorDocumentID));
};
export var updateVendorDocument = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.VENDOR_DOCUMENT_API_PATH, "/UpdateVendorDocument"), dataObj);
};
export var postVendorWebSettings = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/PostVendorWebSettings"), dataObj);
};
export var getVendorWebSettings = function (vendorId) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/GetVendorWebSettings?vendorID=").concat(vendorId));
};
export var getVendorDarwinInformation = function (vendorId) {
    return httpPromise.get("".concat(ServiceConstant.SEARCH_VENDORS_API_PATH, "/GetVendorDarwinInformation?vendorID=").concat(vendorId));
};
export var addVendorDocument = function (dataObj) {
    return httpPromise.post("".concat(ServiceConstant.VENDOR_DOCUMENT_API_PATH, "/AddVendorDocument"), dataObj);
};
