var _a;
export var ComponentConstant = {
    TOP_HEADER_PATH_LOGIN: "/login",
    TOP_HEADER_TOKEN: "token",
    TOP_HEADER_ENGLISH: "English",
    ONBOARDING_PATH: "/",
    CORP_PATH: "/pro",
    WELCOME_BACK_TEXT: "Welcome back",
    PLACEHOLDER_LABEL_EMPLOYEE_FIRST_NAME: "Employee's First Name",
    PLACEHOLDER_LABEL_EMPLOYEE_LAST_NAME: "Employee's Last Name",
    PLACEHOLDER_LABEL_FIRST_NAME: "First Name",
    PLACEHOLDER_LABEL_LAST_NAME: "Last Name",
    PLACEHOLDER_LABEL_ORGANIZATION: "Organization",
    PLACEHOLDER_LABEL_PHONE_NUMBER: "Employee's Phone Number",
    PLACEHOLDER_LABEL_EMAIL_ID: "Employee's Email Id",
    PLACEHOLDER_LABEL_POSITION: "Employee's Position",
    PLACEHOLDER_LABEL_EMPLOYEE_RATE: "Employee's Rate",
    PLACEHOLDER_LABEL_GUARDIAN_FIRST_NAME: "Parent/Guardian First Name",
    PLACEHOLDER_LABEL_GUARDIAN_LAST_NAME: "Parent/Guardian Last Name",
    PLACEHOLDER_LABEL_JOB_STATUS: "Job Status",
    PLACEHOLDER_LABEL_DOCUMENT_NAME: "Document Name",
    PLACEHOLDER_UPLOAD_DOCUMENT: "Select Document To Upload",
    PLACEHOLDER_DEFAULT_CLIENT_OPTION: "Select Client",
    PLACEHOLDER_DEFAULT_STATE_OPTION: "Select State",
    PLACEHOLDER_DEFAULT_OPTION_SELECT: "Select...",
    PLACEHOLDER_TOTAL_COMPENSATION: "Total Compensation",
    PLACEHOLDER_WEEKLY_RATES: "Weekly Rate",
    VIEW_BATCHES: "View Batch",
    COMPLETE_THIS_FORM: "Complete This Form",
    FORM_DECLINED: "Form Declined",
    DECLINE_THIS_FORM: "Decline This Form",
    ADD_NEW_JOB_BUTTON: "Add Job",
    VIEW_DOCUMENTS: "View Documents",
    VIEW_DOCUMENT: "View Document",
    UPLOAD_FORM: "Upload Form",
    EDIT_JOB: "Edit Job",
    MANAGE_JOB: "Manage Job",
    CANCEL_JOB: "Cancel Job",
    PLACEHOLDER_LABEL_AUDITOR_FIRST_NAME: "Auditor's First Name",
    PLACEHOLDER_LABEL_AUDITOR_LAST_NAME: "Auditor's Last Name",
    PLACEHOLDER_LABEL_AUDITOR_PHONE_NUMBER: "Auditor's Phone Number",
    PLACEHOLDER_LABEL_AUDITOR_EMAIL_ID: "Auditor's Email",
    PLACEHOLDER_LABEL_AUDITOR_ORGANISATION: "Auditor's Organisation",
    VIEW_THIS_FORM: "View This Form",
    EDIT_AUDITOR: "Edit",
    EDIT_DETAILS: "Edit Details",
    PLACEHOLDER_AGENT_FIRST_NAME: "Agent's First Name",
    PLACEHOLDER_AGENT_LAST_NAME: "Agent's Last Name",
    PLACEHOLDER_AGENT_PHONE_NUMBER: "Agent's Phone Number",
    PLACEHOLDER_AGENT_EMAIL: "Agent's Email",
    PLACEHOLDER_AGENT_ORGANIZATION_NAME: "Agent's Organization",
    LABEL_FIRST_NAME: "First Name",
    LABEL_MIDDLE_NAME: "Middle Name",
    LABEL_LAST_NAME: "Last Name",
    LABEL_AGENT_ORGANIZATION: "Organization",
    CANCEL_DOCUMENT: "Cancel Document",
    CANCELED_IC_ADVISOR_LABEL: "Canceled/IC Advisor",
    ADD_IC_WORKER_JOB_ANCHOR_TITLE: "Add this worker as an Onboarding Job",
    EDIT: "Edit",
    VIEW: "View",
    VIEW_FORM: "View Form",
    EDIT_DOCUMENT: "Click to Edit",
    PLACEHOLDER_USERNAME: "Username",
    PLACEHOLDER_PASSWORD: "Password",
    PLACEHOLDER_CONFIRM_PASSWORD: "Confirm Password",
    EDIT_HR_DOCUMENT: "Click to Edit",
    CHECK_ALL_DROPDOWN_VALUE: "Select All",
    SELECT_DROPDOWN_VLAUE: "Select",
    SELECT_DOCUMENT_TO_UPLOAD: "Select Document To Upload ...",
    DROP_FILES_TO_UPLOAD: "Drop files here to upload",
    SELECT_FILES: "Select files...",
    EMPTY_STRING: "",
    ERROR_MESSAGE_TERMS_CONDITION: "(It's required to agree with Terms and Conditions)",
    ERROR_MESSAGE_EMAIL_INVALID: "(Email is not in a valid format)",
    ERROR_MESSAGE_EMAIL_REQUIRED: "(Email field is required)",
    ERROR_FIELD_REQUIRED: "(This field is required)",
    ERROR_PHONE_INVALID: "(Not a valid phone number)",
    ERROR_10_DIGIT_PHONE_NUMBER: "(Please enter 10 digit phone number)",
    ERROR_ZIP_INVALID: "(Should be 5 digits)",
    ERROR_ZIP_59_INVALID: "(Should be 5 or 9 digits)",
    ERROR_SSN_INVALID: "(Not a valid SSN number)",
    ERROR_EIN_INVALID: "(Not a valid EID number)",
    ERROR_TRN_INVALID: "(Not a valid Transit Routing Number)",
    UPLOAD_FORM_INVALID_DATE: "(Invalid Date)",
    UPLOAD_FORM_INVALID_END_DATE: "(Effective end date should be greater than current date)",
    UPLOAD_DOCUMENT_SIGNATURE_REQUIRED: "(Please make sure that the uploaded document should have 'Signature' field)",
    ERROR_USERNAME: "(Username should be between 8 to 50 characters long and without whitespace)",
    ERROR_PASSWORD: "(Password must be 10 to 50 characters with no whitespace and must contain 1 uppercase, 1 lowercase, 1 number, and 1 special character from ~!#$%^&-_)",
    ERROR_CONFIRM_PASSWORD: "(Password and Confirm Password should be same)",
    ERROR_MONEY_INVALID: "(Enter a valid dollar amount)",
    CLICK_TO_COMPLETE_FORM: "Click to Complete",
    CLICK_TO_SEND_EMAIL: "Resend employee Onboarding link",
    GROUP_NAME_ALREADY_EXIST: "(Group Name already exists)",
    ATT_NAME_ALREADY_EXIST: "(Attribute Name already exists)",
    ATTR_FORMAT_SHOULD_CORRECT: "(Invalid Format)",
    ERROR_MESSAGE_END_DATE_SMALLER: "(End Date should be greater than Start Date)",
    ERROR_MESSAGE_START_DATE_MINIMUM: "(Invalid Start Date)",
    ERROR_MESSAGE_FOR_DDA_FILE_UPLOAD: "(Please upload voided check)",
    PLACEHOLDER_GROUP_NAME: "Group Name",
    PLACEHOLDER_DESCRIPTION: "Description",
    PLACEHOLDER_ATTRIBUTE_NAME: "Attribute Name",
    PLACEHOLDER_ATTRIBUTE_LABEL: "Attribute Label",
    PLACEHOLDER_ATTRIBUTE_OPTIONS: "E.g. Yes=1|No=2",
    NEWHIRE_DOCUMENT_STATUS_CANCELLED_ID: 20,
    NEWHIRE_DOCUMENT_STATUS_DECLINEDBY_EMPLOYEE_ID: 50,
    ERROR_NUM_VALUE: "(max limit is 9999999)",
    ERROR_MASK_TYPE: "(Allowed characters ), (, #, -, )",
    EXEMPT_CLIENT_ID: 329,
    ON_LOCATION_CLIENTS: [329, 369, 360],
    REQUIRED_ACCOUNT_FOR_CLIENTID: [186, 86],
    PLACEHOLDER_SECTION_NAME: "Section Name",
    PLACEHOLDER_SECTION_TYPE: "Section Type",
    PLACEHOLDER_SECTION_TITLE: "Section Title",
    PLACEHOLDER_SUB_SECTION_TITLE: "Sub-Section Title",
    INVALID_INPUT: "(Invalid input)",
    SPECIAL_CHAR_NOT_ALLOWED: "(Special character not allowed)",
    DYNAMIC_FORM: "dynamicForm",
    PLACEHOLDER_EMAIL: "Email",
    PLACEHOLDER_EMAIL_ADDRESS: "Email Address",
    LABEL_PHONE_NUMBER: "Phone Number",
    PLACEHOLDER_MIDDLE_INITIAL: "Middle Initial",
    PAYREEL_T_C_AGREEMENT: "Please agree to the PayReel HR Documents before continuing",
    I9_FEDRAL_FORM: "I9FedralForm",
    PLACEHOLDER_LABEL_MIDDLE_INITIAL: "Middle Initial",
    PLACEHOLDER_LABEL_OTHER_NAME: "Other Name",
    PLACEHOLDER_LABEL_SSN: "Social Security Number",
    PLACEHOLDER_LABEL_EMAIL: "Email",
    PLACEHOLDER_LABEL_DOB: "Date of Birth",
    PLACEHOLDER_LABEL_STREET_ADDRESS: "Street Address (Do not enter a PO Box)",
    PLACEHOLDER_LABEL_APARTMENT_NUMBER: "Apartment Number",
    PLACEHOLDER_LABEL_CITY: "City",
    PLACEHOLDER_LABEL_STATE: "State",
    PLACEHOLDER_LABEL_ZIP: "Zip Code",
    PLACEHOLDER_LABEL_PHONE: "Phone Number",
    PLACEHOLDER_LABEL_UI_VALIDATION: "Enter Validation Message",
    DEPOSIT_FORM: "Deposit Form",
    PLACEHOLDER_NAME_OF_ACC: "Name of Account",
    PLACEHOLDER_NAME_OF_TRUSTEE: "Name of Trustee",
    PLACEHOLDER_FINANCIAL_INS: "Financial Institution",
    PLACEHOLDER_BANK_STREET_ADD: "Bank Street Address",
    PLACEHOLDER_BANK_CITY: "Bank City",
    PLACEHOLDER_BANK_ZIP: "Bank Zip Code",
    PLACEHOLDER_BANK_PHONE_NUM: "Bank Phone Number",
    PLACEHOLDER_TRANSIT_NUM: "Transit Routing Number",
    PLACEHOLDER_CNF_TRANSIT_NUM: "Confirm Transit Routing Number",
    PLACEHOLDER_ACC_NUM: "Account Number",
    PLACEHOLDER_CNF_ACC_NUM: "Confirm Account Number",
    ERROR_ACC_NUM: "Account numbers do not match",
    ERROR_ROUTING_NUM: "Please enter valid bank routing number",
    ERROR_ROUTING_MATCH: "Routing numbers do not match",
    VOIDED_CHK_ERR: "Upload the voided check or bank form below",
    PLACEHOLDER_MAILING_ADDRESS: "Street Address",
    PLACEHOLDER_MAILING_APARTMENT: "Apartment Number",
    PLACEHOLDER_MAILING_CITY: "City",
    PLACEHOLDER_MAILING_ZIP: "Zip Code",
    PLACEHOLDER_EMERGENCY_CONTACT_NAME: "Emergency Contact Name",
    PLACEHOLDER_EMERGENCY_PHONE: "Emergency Phone Number",
    PLACEHOLDER_EMP_PHYSICIAN: "Physician (First and Last Name or Name of Practice)",
    PLACEHOLDER_PHYSICIAN_PHONE: "Physician Phone Number",
    PLACEHOLDER_HEALTH_INSURANCE: "Health Insurance Provider",
    PLACEHOLDER_POLICY_NUMBER: "Policy Number",
    PLACEHOLDER_MED_CONDITION: "Medications, allergies, and conditions",
    PLACEHOLDER_LABEL_ADDRESS1: "Address1",
    PLACEHOLDER_LABEL_ADDRESS2: "Address2",
    PLACEHOLDER_LABEL_ADDRESS: "Address",
    PLACEHOLDER_LABEL_CELL_PHONE: "Cell Phone Number",
    EMPLOYEE_DATE_OF_BIRTH_INVALID: "(DOB must be before Hire Date)",
    LABEL_ALIEN_AUTHORIZED_DATE: "Alien Registration Number / USCIS Number",
    LABEL_ALIEN_REGISTERATION_NUMBER: "Alien Registration Number / USCIS Number",
    LABEL_FORM_I9_ADMISSION_NUMBER: "Form I-94 Admission Number",
    PLACEHOLDER_LABEL_ISSUEING_AUTHORITY: "Issuing Authority",
    PLACEHOLDER_LABEL_Document_Number: "Document Number",
    VIEW_DATA: "View Data",
    MINOR_TRUST_ACC_ERR_MSG: "(Upload the proof of Minor Trust Account)",
    UPLOAD_RECEIPT: "Upload Receipt",
    MS_ERR_0_1: " Please type only 0 or 1",
    MS_ERR_0_1_2: " Please type only 0 or 1 or 2",
    SAME_STATE_ERR: " Please enter same residence",
    PLACEHOLDER_AMOUNT: "$0.00",
    PLACEHOLDER_LABEL_TITLE: "Title",
    ERROR_SPECIAL_CHARACTER: "(Special chars not allowed)",
    EMPTY_CHKBOX_ERR: "(Select one of the checkboxes)",
    PRI_LNG_ERR: "( Enter your primary language name. )",
    PLACEHOLDER_LABEL_DATE: "Date",
    PLACEHOLDER_LABEL_SSN_EID: "SSN/EID",
    PLACEHOLDER_LABEL_COMPANY_NAME: "Company Name",
    PLACEHOLDER_LABEL_VENDOR_ID: "Vendor ID",
    PLACEHOLDER_POSITION: "Client Position",
    PLACEHOLDER_LABEL_EIN: "Employee Identification Number",
    PLACEHOLDER_LABEL_NAME: "Name",
    BOX5: "Box 5",
    ERROR_MESSAGE_START_TIME_MINIMUM: "(Time in should be less than Time out)",
    ERROR_MESSAGE_INVALID_MEAL_HOURS: "(Enter Valid Meal Hours)",
    ERROR_MESSAGE_INVALID_HOURS: "(Enter Valid Hours)",
    LABEL_VENUE: "Venue",
    LABEL_ADDRESS_1: "Address 1",
    LABEL_ADDRESS_2: "Address 2",
    PLACEHOLDER_MESSAGE_FOR_VALIDATION: "PlaceHolder Message",
    PLACEHOLDER_REQUISITION_NUMBER: "Requisition Number",
    PLACEHOLDER_PAY_CODES_HOURS: "Hours",
    PLACEHOLER_UDF_1: "UDF 1",
    PLACEHOLER_UDF_2: "UDF 2",
    PLACEHOLER_UDF_3: "UDF 3",
    PLACEHOLDER_GUARANTEE_HOURS: "Guranateed Hours",
    PLACEHOLDER_ADDITIONAL_HOURS: "Additional Hours",
    PLACEHOLDER_MEALS: "Meals (in hours)",
    PLACEHOLDER_DARWIN_CLIENT_ID: "Darwin Client ID",
    PLACEHOLDER_LABEL_MASTER_CLIENT: "Master Client",
    ERROR_FIVE_NUM_VALUE: "(Max limit is 99999)",
    PLACEHOLDER_HOURS: "Hours",
    PLACEHOLDER_CODE: "Code",
    PLACEHOLDER_HEALTH_INSURANCE_AMOUNT: "Health Insurance Amount",
    PLACEHOLDER_EFFECTVE_DATE: "Effective Date",
    PLACEHOLDER_START_CITY: "Start City",
    PLACEHOLDER_END_CITY: "End City",
    PLACEHOLDER_TOTAL_MILES: "Total Miles",
    PLACEHOLDER_JOB_TYPE: "Job Type",
    PLACEHOLDER_GENERAL_LIABILITY_PERCENT: "General Liability Percent",
    INVOICE_NUM: "Invoice Number",
    LABEL_CHARGE_TYPE: "Charge Type",
    LABEL_DARWIN_CODE: "Darwin Code",
    MANAGE_LAYOUT_UPLOAD_FILE_FORMAT_TYPE_HINT: "Select the file in excel format to upload the invoice type template.",
    LABEL_LAYOUT_NAME: "Layout Name",
    MAP_SYSTEM_FIELDS: "Map System Fields",
    PLACEHOLDER_EMP_NUMBER: "Employee Number",
    PLACEHOLDER_LOCATION_ST_ELIGIBLE: "Location ST Eligible",
    PLACEHOLDER_LOCATION_CITY_ELIGIBLE: "Location City Eligible",
    PLACEHOLDER_DEFAULT_All_STATUS: "All Statuses",
    LABEL_EMPLOYEE_NUMBER: "Employee Number",
    PLACEHOLDER_BUDGET: "Budget",
    PLACEHOLDER_PROJECT_NUMBER: "Project Number",
    PLACEHOLDER_PROJECT_TITLE: "Project Title",
    PLACEHOLDER_CLIENT_NAME: "Client Name",
    PLACEHOLDER_CONTACT_NAME: "Contact Name",
    DEFAULT_VALUE_MESSAGE: "Enter Default value",
    ATTRIBUTES_CLIENT_NAME: "attributes.ClientName",
    ATTRIBUTES_CONTACT_NAME: "attributes.ContactName",
    ATTRIBUTES_CONTACT_CITY: "attributes.ContactCity",
    ATTRIBUTES_CONTACT_PHONE: "attributes.ContactPhone",
    ATTRIBUTES_CONTACT_EMAIL: "attributes.ContactEmail",
    WIDTH_HUNDRED_PX: "100px",
    DELETE: "Delete",
    PLACEHOLDER_BUSINESS_NAME: "Business Name",
    PLACEHOLDER_CLASSIFICATION_NAME: "Classification",
    PLACEHOLDER_PAYMENT: "Payment",
    PENDING_DOCUMENT: "Pending Documents",
    WORKER_1099_DOCUMENT_TYPE: {
        1: "W9 Document is pending",
        2: "Direct Deposit Document is pending",
        3: "IC Agreement Document is pending",
        4: "GL COI Document is pending",
        5: "WC COI Document is pending",
        6: "Client Document is pending",
    },
    CURRENCY_WITH_DECIMAL: "$0.00",
    PLACEHOLDER_STATE: "State",
    PLACEHOLDER_AR_EXPORT: "AR Export",
    CLICK_HERE_TO_DOWNLOAD: "Click here to download",
    CLICK_TO_VIEW_INVOICE: "Click here to view Invoice",
    OPEN: "Open",
    LABEL_COMMENTS: "Comments",
    PLACEHOLDER_LABEL_EMPLOYEE_ID: "Employee Id #",
    CLICK_TO_LOGIN: "Click to Login",
    PLACEHOLDER_GOC: "GOC",
    CLICK_HERE_TO_DOWNLOAD_IN_EXCEL: "Click here to download Invoice in Excel format",
    LABEL_PROJECT: "Project",
    PLACEHOLDER_LABEL_COMPANY_CODE: "Company Code",
    LABEL_RATE_PER_HOUR: "Rate per Hour",
    LABEL_RATE_PER_DAY: "Rate per Day",
    LABEL_RATE_PER_WEEK: "Rate per Week",
    PLACEHOLDER_LABEL_WORK_LOCATIONS: "Work Location",
    FLASH_POP_UP_TEXT_MESSAGE: {
        1: "Safety Guidelines",
        2: "Injury Illness Prevention Program",
    },
    LABEL_GL_ACCOUNT_NUMBER: "GL Account Number",
    PLACE_HOLDER_POSITION: "Position",
    PLACEHOLDER_GL_CODE: "GL Code",
    VIEW_UPLOAD_RECIEPTS: "View/Upload Reciepts",
    ADMIN_1099_WORKER_DASHBOARD_STATUS: {
        PENDING: "Pending",
        TO_BE_REVIEWED: "Review",
        COMPLETED: "Completed",
        ARCHIVE: "Archive",
        W9_REVALIDATION: "W9 Revalidation",
        LABEL_TO_BE_REVIEWED: "To Be Reviewed",
        PENDING_REVALIDATION: "PendingRevalidation",
        CREATED: "Created",
    },
    LABEL_EMPLOYEE_ELIGIBLE_DAYS: "Employee Eligible Days",
    LABEL_EMPLOYEE_ELIGIBLE_HOURS: "Employee Eligible Hours",
    LABEL_ACCRUAL_RATE_HOURS: "Accrual Rate Hours",
    LABEL_ACCRUAL_RATE_HOURS_WORKED: "Accrual Hours Worked",
    LABEL_ACCRUAL_BEGIN_DAYS: "Accrual Begin Days",
    LABEL_ACCRUAL_CAPS_HOURS: "Accrual Caps Hours",
    LABEL_PSL_ACCESSIBLE_DAYS: "PSL Accessible Days of Employee",
    LABEL_PSL_USAGE_CAPS_HOURS: "PSL Usage Caps Hours",
    LABEL_PSL_CARRY_OVER_HOURS: "PSL Carryover Hours",
    DROPDOWN_ROLES_ADMIN_1099_WORKER_DASHBOARD: {
        MANAGER: "manager",
        SUPERVISOR: "supervisor",
        CLIENT: "client",
        REVALIDATION_YEAR: "revalidationYear",
        STATUS: "status",
    },
    PLACEHOLDER_ENTER_PERFORMANCE_COMPLIANCE_ITEM: "Enter Performance Compliance Item",
    LABEL_ACCRUAL_RATE_FOR_EMPLOYEE_HOURS_WORKED: "Accrual Rate For Employee Hours Worked",
    LABEL_ACCRUAL_BEGIN_DOE: "Accrual Begin DOE",
    EXPENSE_REPORT_SUCCESSFUL: "Approve Expense Report Successful.",
    PLACEHOLDER_DEFAULT_EVENT_PROJECT: "Event/Project",
    PLACEHOLDER_DEFAULT_SECONDARY_PROJECT: "Secondary Project",
    PLACEHOLDER_DEFAULT_EMPLOYEE: "Employee",
    PLACEHOLDER_DEFAULT_VENDORS: "Vendors",
    HOURS_NOT_AVAILABLE_SICK_LEAVE: "(Hours Not Available)",
    VENDOR_TAB_HEADINGS: {
        VENDOR_INFO: "Vendor Info",
        DD_INFO: "DD Info",
        VENDOR_DOCUMENTS: "Vendor Documents",
        WEB_SETTINGS: "Web Settings",
        DARWIN_INFORMATION: "Darwin Information",
    },
    PLACEHOLDER_LABEL_SSN_EIN: "SSN/EIN",
    AGENCY_PERCENTAGE: "Agency %",
    AGENCY_FEE: "Agency Fees",
    PLACEHOLDER_MAS_ID: "Mas ID",
    ERROR_ALL_NUMBERS: "(Please enter only numeric value)",
    MONTH_YEAR: "MM YYYY",
    PLACEHOLDER_LABEL_COUNTRY: "Country",
    PLACEHOLDER_LABEL_SHORT_STATE: "State Short",
    NOT_VALID_TIME: "(Not a valid Time)",
    INVALID_MEAL_HOURS_MESSAGE: "(Meal hours should not be more than the hours worked)",
    TIME_IN_OUT_PLACEHOLDER: "hh:mm",
    BREADCRUMBS: {
        HOME: "Home",
        REPORTS: "Reports",
        CLIENT_REPORTS: "Client Reports",
    },
    PLACEHOLDER_MINIMUM_WAGE_RATE: "Minimum wage rate",
    PLACEHOLDER_LOCATION_OF_SERVICE: "Location of Service",
    WORK_ORDER_REPORT: {
        MARK_COMPLETE: "markComplete",
        ADD_EMPLOYEE: "addEmployee",
        EDIT_EMPLOYEE: "editEmployee",
        EMAIL_PDF: "emailPdf",
        VIEW_PDF: "veiwPdf",
        GENERATE_TIMESHEETS: "generateTimesheets",
        ADDENDUM: "addendum",
        RETURN_TO_WORK_ORDER: "returnToWorkOrder",
        EDIT_WORK_ORDER: "editWorkOrder",
        ADD_WORK_ORDER: "addWorkOrder",
    },
    QVC_WORK_ORDER: "QVCWorkOrder",
    EMAILID_WORK_ORDERS: "WorkOrders@PayReelOnline.com",
    PDF_FILE_WORK_ORDER: "WorkOrder.pdf",
    GROSS_PAYMENT_LABEL: "Gross Payment",
    NUMBER_OR_DECIMAL_TYPE_VALIDATOR: "Please enter valid integer or decimal number with 2 decimal places.",
    EMPLOYEE_EARNINGS: "Employee Earnings",
    MONTH_JANUARY: "January",
    ZERO_ONE: "01",
    VALUE_ZERO: "0",
    VALUE_ONE: "1",
    VALUE_EMPTY: "",
    DATE_FORMAT: "MM/dd/yyyy",
    ZERO: 0,
    ONE: 1,
    VALUE_TEXT: "text",
    VALUE_NAME: "name",
    VALUE: "value",
    ACCOUNT_ID: "accountID",
    FULLNAME: "fullName",
    EMPLOYEE_ID: "employeeID",
    TAXABLE_BASE_WAGES_TAB_HEADINGS: {
        STATE_UNEMPLOYMENT: " State Unemployment",
        FEDERAL_UNEMPLOYMENT: " Federal Unemployment",
        SOCIAL_SECURITY: "Social Security",
    },
    INVOICED_AMOUNT: "invoicedAmount",
    CURRENCY_DOLLAR: "$",
    EXPENSE_RATE_PERCENT: "Expense Percent",
    PLACEHOLDER_LABEL_ADDITIONAL_INFORMATION: "Additional Information",
    EQUIPMENT_RATE_PERCENT: "Equipment Percent",
    LABEL_EARNINGS_CODE: "Earnings Code",
    PLACEHOLDER_LABEL_ADMIN_PERCENT: "Admin Percent",
    PLACEHOLDER_VENDOR_PAYMENT_RATE: "Vendor Payment Rate",
    PLACEHOLDER_MARKUP_PERCENT: "Markup Percent",
    PLACEHOLDER_FICA_RATE_PERCENT: "FICA Percent",
    STATUS_FOR_ADMIN_JOB_1099_WORKER_DASHBOARD: {
        COMPLETE: "1",
        ARCHIVE: "2",
        RETRIEVE: "3",
    },
    PLACEHOLDER_FUTA_RATES_PERCENT: "FUTA Percent",
    PLACEHOLDER_SUTA_RATES_PERCENT: "SUTA Percent",
    FUTA_RATES: "futaRates",
    SUTA_RATES: "sutaRates",
    WC_RATES: "wcRates",
    PLACEHOLDER_OWNER: "Owner",
    DATE_TIME_FORMAT: "MM/dd/yyyy 00:00:00",
    YEAR_FIELD_MIN_MAX_RANGE_ERROR: "( Please enter the year between ".concat(((_a = new Date()) === null || _a === void 0 ? void 0 : _a.getFullYear()) - 4, " - 2099 )"),
    PLACEHOLDER_PERCENT: "Percent",
    SYMMETRY_PAYROLL_POINT_BATCH_EXPORT: "Symmetry Payroll Point Batch Export",
    PDF_VALIDATION_ERROR_TEXT: "(Please choose a valid PDF file type to upload)",
    PLACEHOLDER_SSN: "SSN",
    USERNAME_PASSWORD_SAME_ERROR_VALIDATION: "(Username cannot be same as Password)",
    DROP_FILE_TO_UPLOAD: "Drop file here to upload",
    SELECT_FILE: "Select file...",
    PLACEHOLDER_ZIP: "Zip",
    PLACEHOLDER_ZIP_CODE: "Zip Code",
    ERROR_MESSAGE_INVALID_GUARANTEE_HOURS: "(Enter Valid Guaranteed Hours)",
    EVENT_START_END_DATE_VALIDATOR: "(Event End Date must occur after Event Start Date)",
    LABEL_EARNING_CODE: "Earning Code",
    SELECT_ONE_DOCUMENT_TYPE: "Please select atleast one Document Type",
    ROUTING_NUMBER: "Routing Number",
    LABEL_THINKWARE_ID: "ThinkWare ID",
    LABEL_THINKWARE_DIVISION_ID: "ThinkWare Divison ID",
    LABEL_CLIENT_IDENTIFIER: "Client Identifier",
    PLACEHOLDER_LABEL_ACCOUNT: "Account",
    PLACEHOLDER_LABEL_SUB_ACCOUNT: "Sub Account",
    PLACEHOLDER_LABEL_MARKUP_PERCENT: "Markup Percent",
    SELECT_ALL_EMPLOYEES: "All Employees",
    SELECT_ALL_VENDORS: "All Vendors",
    LABEL_PHONE_ASH: "Phone #",
    PLACEHOLDER_CHECK_NUMBER: "Check Number",
    PLACEHOLDER_EMPLOYEE_FIRST_NAME: "Employee First Name",
    PLACEHOLDER_EMPLOYEE_LAST_NAME: "Employee Last Name",
    PLACEHOLDER_LOCATION: "Location",
    PLACEHOLDER_MANAGEMENT_ADMIN_FEE_TERM_CODE: "Management/Admin Fee-Term Code",
    PLACEHOLDER_SUPPLIER_NUMBER: "Supplier Number",
    PLACEHOLDER_ADDRESS_LINE_1: "Address Line 1",
    PLACEHOLDER_ADDRESS_LINE_2: "Address Line 2",
    PLACEHOLDER_CONTACT_PHONE: "Contact Phone",
    PLACEHOLDER_CONTACT_EMAIL: "Contact Email",
    PLACEHOLDER_TERMS: "Terms",
    PLACEHOLDER_PROJECT_NAME: "Project Name",
    PLACEHOLDER_SEND_TO_DELIVERY_LIST: "Send To Delivery List",
    PLACEHOLDER_EMAIL_SUBJECT: "Email Subject",
    PLACEHOLDER_EMAIL_BODY: "Email Body",
    LABEL_QVC_PA_MARKUP: "QVC PA markup",
    PLACEHOLDER_LABEL_EMP_ID: "Employee ID",
    INVALID_SSN: "Enter valid last 4 digits SSN",
    PLACEHOLDER_COMPANY_NUMBER: "Company Number",
    PLACEHOLDER_COST_CENTER: "Cost Center",
    PLACEHOLDER_ITEM_ID: "Item Id",
    CLICK_HERE_TO_INVOICE_DOWNLOAD: "Click here to download Voided Darwin Invoice",
    INVALID_THINKWARE_DIVISION_ID: "(Not a valid thinkware division ID)",
    AMOUNT_TO_FROM_INVALID: "(Amount To must be greater than Amount From)",
    INVALID_DOCUMENT: "(Please choose a valid Document/File)",
    ERROR_ALL_POSITIVE_NUMBERS: "(Please enter only positive numeric value)",
    PLACEHOLDER_WEBSITE_TRAINING_LINK: "Website Training Link",
    ERROR_DATE_FOR_EXPIRATION_DATE: "The expiration date must be after the effective Date",
    START_END_DATE_VALIDATION_MSG: "Start Date must be previous date of End Date",
    MAX_AMOUNT_VALIDATOR: "(Should be less than or equal to 9999999.99)",
    NOT_A_VALID_DATE: "(Enter a valid date)",
    DATE_BEFORE_DEC_2022: new Date(2022, 11, 1),
    REQUIRED_VALIDATION_TEXT: "(Required)",
    TIMEOUT_OVERLAP_TIMEIN: "Time Out overlaps with Time In",
    TIME_OVERLAP: "Invalid Time Entry - Overlap",
};
