var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { CITY_DROP_DOWN_DEFAULT_ITEM, COUNTRY_DROP_DOWN_DEFAULT_ITEM, PER_DIEM_LEVEL_ID, STATE_DROP_DOWN_DEFAULT_ITEM, StatusTypeDataList, ZIP_DROP_DOWN_DEFAULT_ITEM, } from "../StaticData";
import { Transform, TranslationConstant, FormButtons, ComponentConstant, AppConstant, FormInput, FormHeading, decodedURL, FormRadioGroup, getUserDetailsFromLocalStorage, useTimesheetViewContext, useSearchEmpViewContext, requiredValidator, FormDropDownListWithValue, DialogBox, } from "../index";
import { AddNewLocation } from "./AddNewLocation";
var AddOrEditWorkLocationForm = function (_a) {
    var reDirectRoute = _a.reDirectRoute;
    var _b = decodedURL(), workLocationID = _b.workLocationID, updatedFormData = _b.updatedFormData;
    var formRef = useRef(null);
    var _c = useSearchEmpViewContext(), countriesList = _c.countriesList, fetchCountriesList = _c.fetchCountriesList, stateFromCountryList = _c.stateFromCountryList, fetchStateFromCountryList = _c.fetchStateFromCountryList, resetStateFromCountryList = _c.resetStateFromCountryList, postWorkLocationForSetup = _c.postWorkLocationForSetup;
    var _d = useTimesheetViewContext(), citiesFromStatesObj = _d.citiesFromStatesObj, getCitiesFromStates = _d.getCitiesFromStates, resetCitiesFromStates = _d.resetCitiesFromStates, zipcodeFromCity = _d.zipcodeFromCity, getZipcodeFromCity = _d.getZipcodeFromCity, resetZipcodeFromCity = _d.resetZipcodeFromCity, workLocationByIdList = _d.workLocationByIdList, getWorkLocationDataByID = _d.getWorkLocationDataByID;
    var navigate = useNavigate();
    var _e = useState("23"), selectedCountry = _e[0], setSelectedCountry = _e[1];
    var _f = useState(null), selectedState = _f[0], setSelectedState = _f[1];
    var _g = useState({
        cityText: "",
        cityValue: null,
    }), selectedCity = _g[0], setSelectedCity = _g[1];
    var _h = useState(null), selectedZip = _h[0], setSelectedZip = _h[1];
    var _j = useState({
        workLocationStatusID: StatusTypeDataList[0].value,
        perDiemLevel: PER_DIEM_LEVEL_ID[0].value,
        countryID: "23",
    }), initialValues = _j[0], setInitialValues = _j[1];
    var _k = useState(false), showAddLocationDialog = _k[0], setShowAddLocationDialog = _k[1];
    var oidcUserProfile = getUserDetailsFromLocalStorage(AppConstant.CORP_TYPE_APPLICATION);
    var _l = useState(false), modalState = _l[0], changeModalState = _l[1];
    var _m = useState({}), selectedClient = _m[0], updateSelectedClient = _m[1];
    var _o = useState({
        headingText: "",
        bodyText: "",
        isErrorDialog: "",
    }), modalContent = _o[0], updatemodalContent = _o[1];
    var initAPICall = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        return __generator(this, function (_v) {
            switch (_v.label) {
                case 0:
                    updateSelectedClient((_a = JSON.parse(localStorage.getItem("selectedClient"))) === null || _a === void 0 ? void 0 : _a.clientDto);
                    return [4 /*yield*/, getWorkLocationDataByID(workLocationID)];
                case 1:
                    response = _v.sent();
                    if (response === null || response === void 0 ? void 0 : response.data) {
                        setSelectedCountry((_c = (_b = response.data) === null || _b === void 0 ? void 0 : _b.countryID) === null || _c === void 0 ? void 0 : _c.toString());
                        setSelectedState((_e = (_d = response.data) === null || _d === void 0 ? void 0 : _d.stateID) === null || _e === void 0 ? void 0 : _e.toString());
                        setSelectedCity({
                            cityText: (_f = response.data) === null || _f === void 0 ? void 0 : _f.city,
                            cityValue: (_h = (_g = response.data) === null || _g === void 0 ? void 0 : _g.cityID) === null || _h === void 0 ? void 0 : _h.toString(),
                        });
                        setSelectedZip(+((_j = response.data) === null || _j === void 0 ? void 0 : _j.zipCode));
                        setInitialValues(__assign(__assign({}, response.data), { countryID: (_l = (_k = response.data) === null || _k === void 0 ? void 0 : _k.countryID) === null || _l === void 0 ? void 0 : _l.toString(), stateID: (_o = (_m = response.data) === null || _m === void 0 ? void 0 : _m.stateID) === null || _o === void 0 ? void 0 : _o.toString(), cityID: (_q = (_p = response.data) === null || _p === void 0 ? void 0 : _p.cityID) === null || _q === void 0 ? void 0 : _q.toString(), zipCode: (_s = (_r = response.data) === null || _r === void 0 ? void 0 : _r.zipCode) === null || _s === void 0 ? void 0 : _s.toString(), perDiemLevel: ((_t = response.data) === null || _t === void 0 ? void 0 : _t.perDiemLevel)
                                ? (_u = response.data) === null || _u === void 0 ? void 0 : _u.perDiemLevel
                                : PER_DIEM_LEVEL_ID[0].value }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var newEditCellData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k;
        return __generator(this, function (_l) {
            switch (_l.label) {
                case 0:
                    if (!((updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID) &&
                        (updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID) !== "undefined" &&
                        (updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID) !== "null")) return [3 /*break*/, 2];
                    return [4 /*yield*/, getWorkLocationDataByID(updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID)];
                case 1:
                    response = _l.sent();
                    if (response === null || response === void 0 ? void 0 : response.data) {
                        setSelectedCountry((_a = updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.countryID) === null || _a === void 0 ? void 0 : _a.toString());
                        setSelectedState((_b = updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.stateID) === null || _b === void 0 ? void 0 : _b.toString());
                        setSelectedCity({
                            cityText: updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.cityName,
                            cityValue: updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.cityID,
                        });
                        setSelectedZip(+(updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.zipCode));
                        setInitialValues(__assign(__assign({}, response.data), { countryID: (_c = updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.countryID) === null || _c === void 0 ? void 0 : _c.toString(), stateID: (_d = updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.stateID) === null || _d === void 0 ? void 0 : _d.toString(), cityID: (_e = updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.cityID) === null || _e === void 0 ? void 0 : _e.toString(), zipCode: (_f = updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.zipCode) === null || _f === void 0 ? void 0 : _f.toString() }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    setSelectedCountry((_g = updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.countryID) === null || _g === void 0 ? void 0 : _g.toString());
                    setSelectedState(updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.stateID);
                    setSelectedCity({
                        cityText: updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.cityName,
                        cityValue: updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.cityID,
                    });
                    setSelectedZip(+(updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.zipCode));
                    setInitialValues(__assign(__assign({}, initialValues), { countryID: (_h = updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.countryID) === null || _h === void 0 ? void 0 : _h.toString(), stateID: updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.stateID, cityID: (_j = updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.cityID) === null || _j === void 0 ? void 0 : _j.toString(), zipCode: (_k = updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.zipCode) === null || _k === void 0 ? void 0 : _k.toString() }));
                    _l.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var formValuesDecider = function () {
        if (workLocationID) {
            initAPICall();
        }
        else if (updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID) {
            newEditCellData();
        }
        else {
            setSelectedCountry("23");
            setSelectedState(null);
            setSelectedCity({
                cityText: "",
                cityValue: null,
            });
            setInitialValues({
                workLocationStatusID: StatusTypeDataList[0].value,
                perDiemLevel: PER_DIEM_LEVEL_ID[0].value,
                countryID: "23",
            });
        }
    };
    useEffect(function () {
        formValuesDecider();
        fetchCountriesList();
    }, [workLocationID, updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID]);
    useEffect(function () {
        if (selectedCountry) {
            fetchStateFromCountryList(+selectedCountry);
        }
        else {
            resetStateFromCountryList();
        }
    }, [selectedCountry]);
    useEffect(function () {
        if (+selectedState) {
            getCitiesFromStates(+selectedState);
        }
        else {
            resetCitiesFromStates();
        }
    }, [selectedState]);
    useEffect(function () {
        if (+(selectedCity === null || selectedCity === void 0 ? void 0 : selectedCity.cityValue)) {
            getZipcodeFromCity(+(selectedCity === null || selectedCity === void 0 ? void 0 : selectedCity.cityValue));
        }
        else {
            resetZipcodeFromCity();
        }
    }, [selectedCity]);
    var handleSubmit = function (dataItem) { return __awaiter(void 0, void 0, void 0, function () {
        var cityValueExist, zipValueExists, dataObj, response, err_1;
        var _a, _b, _c, _d, _e, _f, _g;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    cityValueExist = citiesFromStatesObj.some(function (obj) { var _a; return obj.value === ((_a = selectedCity === null || selectedCity === void 0 ? void 0 : selectedCity.cityValue) === null || _a === void 0 ? void 0 : _a.toString()); });
                    if (!cityValueExist) {
                        formRef.current.onChange("cityID", { value: null });
                        setSelectedCity({
                            cityText: "",
                            cityValue: null,
                        });
                    }
                    zipValueExists = zipcodeFromCity.some(function (obj) { var _a, _b; return ((_a = obj === null || obj === void 0 ? void 0 : obj.text) === null || _a === void 0 ? void 0 : _a.toString()) === ((_b = dataItem.values) === null || _b === void 0 ? void 0 : _b.zipCode); });
                    if (!zipValueExists) {
                        formRef.current.onChange("zipCode", { value: null });
                        setSelectedZip(null);
                    }
                    if (!(!dataItem.isValid || !zipValueExists || !cityValueExist)) return [3 /*break*/, 1];
                    return [2 /*return*/, false];
                case 1:
                    if (!dataItem.isValid) return [3 /*break*/, 5];
                    dataObj = __assign(__assign({}, dataItem.values), { workLocationID: workLocationID
                            ? workLocationID
                            : (updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID) &&
                                (updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID) !== "undefined" &&
                                (updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID) !== "null"
                                ? updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID
                                : 0, workLocation: (_a = dataItem.values.workLocation) === null || _a === void 0 ? void 0 : _a.trim(), address1: (_b = dataItem.values.address1) === null || _b === void 0 ? void 0 : _b.trim(), address2: ((_c = dataItem.values.address2) === null || _c === void 0 ? void 0 : _c.trim()) || "", stateID: selectedState, cityID: selectedCity === null || selectedCity === void 0 ? void 0 : selectedCity.cityValue, zipCode: (_d = dataItem.values) === null || _d === void 0 ? void 0 : _d.zipCode, workLocationStatusID: ((_e = dataItem.values) === null || _e === void 0 ? void 0 : _e.workLocationStatusID) ||
                            workLocationByIdList["workLocationStatusID"] ||
                            StatusTypeDataList[0].value, clientID: workLocationID
                            ? workLocationByIdList["clientID"]
                            : oidcUserProfile.ClientId, employeeID: workLocationID ? workLocationByIdList["employeeID"] : null, workLocationTypeID: 1, city: selectedCity === null || selectedCity === void 0 ? void 0 : selectedCity.cityText, zipCodeExtension: "", perDiemLevel: (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.locationBasedPerDiem)
                            ? (_f = dataItem.values) === null || _f === void 0 ? void 0 : _f.perDiemLevel
                            : workLocationByIdList === null || workLocationByIdList === void 0 ? void 0 : workLocationByIdList.perDiemLevel, isManuallyUpdated: (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.locationBasedPerDiem)
                            ? (workLocationByIdList === null || workLocationByIdList === void 0 ? void 0 : workLocationByIdList.perDiemLevel) != ((_g = dataItem.values) === null || _g === void 0 ? void 0 : _g.perDiemLevel)
                                ? true
                                : false
                            : false, isAddNewTimeCard: false, webUserID: oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.WebUserId });
                    _h.label = 2;
                case 2:
                    _h.trys.push([2, 4, , 5]);
                    return [4 /*yield*/, postWorkLocationForSetup(dataObj)];
                case 3:
                    response = _h.sent();
                    if (AppConstant.HTTP_SUCCESS_CODES.includes(response.status) && response.data) {
                        onSaveSuccess();
                    }
                    else {
                        onSaveFailure();
                    }
                    return [3 /*break*/, 5];
                case 4:
                    err_1 = _h.sent();
                    console.log(err_1);
                    return [3 /*break*/, 5];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    var formHeadingData = {
        title: workLocationID ? (_jsx(Transform, { mkey: TranslationConstant.EDIT_WORK_LOCATION_HEADING })) : (_jsx(Transform, { mkey: TranslationConstant.ADD_WORK_LOCATION_HEADING })),
    };
    var handleAddNewLocation = function () {
        setShowAddLocationDialog(true);
    };
    var onSaveSuccess = function () {
        updatemodalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.SUCCESS }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-check fa-lg" }), _jsx("span", { children: workLocationID || (updatedFormData === null || updatedFormData === void 0 ? void 0 : updatedFormData.workLocationID) ? (_jsx(Transform, { mkey: TranslationConstant.UPDATED_SUCCESSFULLY })) : (_jsx(Transform, { mkey: TranslationConstant.SAVED_SUCCESSFULLY })) })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: false,
            onModalChangeState: function () { return hideModal(true); },
        });
        changeModalState(true);
    };
    var onSaveFailure = function () {
        updatemodalContent({
            headingText: _jsx(Transform, { mkey: TranslationConstant.FAILED }),
            bodyText: (_jsxs("div", __assign({ className: "block subheading" }, { children: [_jsx("i", { className: "fa fa-times-circle fa-lg" }), _jsx("span", { children: _jsx(Transform, { mkey: TranslationConstant.SAVE_FAILED }) })] }))),
            cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }),
            isErrorDialog: true,
            onModalChangeState: function () { return hideModal(false); },
        });
        changeModalState(true);
    };
    var hideModal = function (value) {
        changeModalState(false);
        if (value) {
            navigate(reDirectRoute);
        }
    };
    return (_jsxs(_Fragment, { children: [_jsx(FormHeading, { data: formHeadingData }), _jsx(Form, { initialValues: initialValues, ref: formRef, onSubmitClick: handleSubmit, render: function (formRenderProps) { return (_jsxs(FormElement, __assign({ className: "general-form" }, { children: [_jsxs("fieldset", __assign({ className: "k-form-fieldset row" }, { children: [_jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "workLocation", name: "workLocation", component: FormInput, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_VENUE }), isRequired: true, validator: requiredValidator, placeholder: ComponentConstant.LABEL_VENUE, isFocusableElement: true, maxLength: 100 }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "address1", name: "address1", component: FormInput, label: _jsx(Transform, { mkey: TranslationConstant.ADDRESS1 }), isRequired: true, validator: requiredValidator, placeholder: ComponentConstant.LABEL_ADDRESS_1, maxLength: 150 }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "address2", name: "address2", component: FormInput, label: _jsx(Transform, { mkey: TranslationConstant.ADDRESS2 }), placeholder: ComponentConstant.LABEL_ADDRESS_2, maxLength: 150 }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "countryID", name: "countryID", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_COUNTRY }), textField: "text", valueField: "value", data: countriesList, value: selectedCountry, component: FormDropDownListWithValue, defaultItem: COUNTRY_DROP_DOWN_DEFAULT_ITEM, onChange: function (e) {
                                            setSelectedCountry(e.value);
                                            formRenderProps.onChange("stateID", {
                                                value: null,
                                            });
                                            setSelectedState(null);
                                            formRenderProps.onChange("cityID", {
                                                value: null,
                                            });
                                            setSelectedCity({
                                                cityText: "",
                                                cityValue: null,
                                            });
                                            formRenderProps.onChange("zipCode", {
                                                value: null,
                                            });
                                            setSelectedZip(null);
                                        }, placeholder: ComponentConstant.PLACEHOLDER_DEFAULT_OPTION_SELECT }) }), selectedCountry), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "stateID", name: "stateID", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_STATE }), isRequired: true, validator: requiredValidator, textField: "text", valueField: "value", component: FormDropDownListWithValue, data: stateFromCountryList, value: selectedState, defaultItem: STATE_DROP_DOWN_DEFAULT_ITEM, onChange: function (e) {
                                            setSelectedState(e.value);
                                            formRenderProps.onChange("cityID", {
                                                value: null,
                                            });
                                            setSelectedCity({
                                                cityText: "",
                                                cityValue: null,
                                            });
                                            formRenderProps.onChange("zipCode", {
                                                value: null,
                                            });
                                            setSelectedZip(null);
                                        }, disabled: !workLocationID && selectedCountry === null ? true : false }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "cityID", name: "cityID", isRequired: true, validator: requiredValidator, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_CITY }), textField: "text", valueField: "value", component: FormDropDownListWithValue, data: citiesFromStatesObj, value: selectedCity === null || selectedCity === void 0 ? void 0 : selectedCity.cityValue, defaultItem: CITY_DROP_DOWN_DEFAULT_ITEM, onChange: function (e) {
                                            setSelectedCity(__assign(__assign({}, selectedCity), { cityText: e.target.component._valueDuringOnChange.text, cityValue: e.value }));
                                            formRenderProps.onChange("zipCode", {
                                                value: null,
                                            });
                                            setSelectedZip(null);
                                        }, disabled: !workLocationID && selectedState === null ? true : false }) })), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "zipCode", name: "zipCode", isRequired: true, validator: requiredValidator, label: _jsx(Transform, { mkey: TranslationConstant.LABEL_ZIP }), textField: "text", valueField: "text", component: FormDropDownListWithValue, data: zipcodeFromCity, value: selectedZip, defaultItem: ZIP_DROP_DOWN_DEFAULT_ITEM, onChange: function (e) {
                                            setSelectedZip(e.value);
                                        }, disabled: !workLocationID && (selectedCity === null || selectedCity === void 0 ? void 0 : selectedCity.cityValue) === null
                                            ? true
                                            : false }) })), (selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.locationBasedPerDiem) && (_jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "perDiemLevel", name: "perDiemLevel", label: _jsx(Transform, { mkey: TranslationConstant.PER_DIEM_LEVEL }), layout: "horizontal", data: PER_DIEM_LEVEL_ID, component: FormRadioGroup, isRequired: true, validator: requiredValidator }) }))), _jsx("div", __assign({ className: "col-xs-12 col-md-4 mb-2" }, { children: _jsx(Field, { id: "status", name: "workLocationStatusID", label: _jsx(Transform, { mkey: TranslationConstant.LABEL_STATUS }), layout: "horizontal", data: StatusTypeDataList, component: FormRadioGroup }) }))] })), _jsx(FormButtons, { searchType: false, showAddNewButton: true, wrapperClass: "full-width-btns", customButtonTitle: _jsx(Transform, { mkey: TranslationConstant.ADD_NEW_COUNTRY_STATE_CITY_BTN }), addNewHandler: function () {
                                handleAddNewLocation();
                            }, resetHandler: function () {
                                formRenderProps.onFormReset();
                                formValuesDecider();
                            }, showCancelButton: true, cancelHandler: function () {
                                formRenderProps.onFormReset();
                                navigate(reDirectRoute);
                            } })] }))); } }, JSON.stringify(initialValues)), showAddLocationDialog && (_jsx(AddNewLocation, { handleClose: function () { return setShowAddLocationDialog(false); } })), _jsx(DialogBox, __assign({}, modalContent, { isDialogOpen: modalState }))] }));
};
export default AddOrEditWorkLocationForm;
