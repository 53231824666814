var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { encode, decode } from "js-base64";
import { AppConstant, ComponentConstant } from "common/constant";
import { useCurrentViewContext } from "store/common/currentViewContext";
import { formatDate, formatNumber } from "@progress/kendo-intl";
import { MobileGridColumnTypes, WEBUSERROLEID } from "common/constant/Enums";
export var removeFalsyValueFromObj = function (dataObj) {
    // It will remove null and undefined values
    return Object.entries(dataObj).reduce(function (a, _a) {
        var k = _a[0], v = _a[1];
        return (v == null ? a : ((a[k] = v), a));
    }, {});
};
export var createQueryString = function (data) {
    return (data &&
        Object.keys(data)
            .map(function (key) {
            var val = data[key];
            if (val !== null && typeof val === "object")
                val = createQueryString(val);
            return "".concat(key, "=").concat(encodeURIComponent("".concat(val)));
        })
            .join("&"));
};
var queryStringToJSON = function (queryString) {
    var queryStringPairs = queryString.split("&");
    var result = {}, data = [];
    queryStringPairs.forEach(function (queryStringPair) {
        var _a;
        queryStringPair = queryStringPair.split("=");
        var key = decodeURIComponent(queryStringPair[0] || "");
        var value = decodeURIComponent(queryStringPair[1] || "");
        if (value.indexOf("=") > -1) {
            var innerPairs = value.split("&");
            var dataObject_1 = (_a = {}, _a[key] = {}, _a);
            innerPairs.forEach(function (innerpair) {
                innerpair = innerpair.split("=");
                var innerKey = innerpair[0];
                var value = decodeURIComponent(innerpair[1] || "");
                dataObject_1[key][innerKey] = value === "null" ? null : value;
            });
            data.push(dataObject_1);
        }
        else {
            result[queryStringPair[0]] = value === "null" ? null : value;
        }
    });
    var resultObject = JSON.parse(JSON.stringify(__assign(__assign({}, result), data[0])));
    return resultObject;
};
export var createEncodedURL = function (url, dataToQueryParams) {
    var queryString = createQueryString(dataToQueryParams);
    var encodedParams = encode(queryString);
    var encodedURL = "".concat(url, "?").concat(encodedParams);
    return encodedURL;
};
export var getWindowDimensions = function () {
    var width = window.innerWidth, height = window.innerHeight;
    return { width: width, height: height };
};
export var generateID = function (length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    var counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
};
export var decodedURL = function () {
    var encodedURLParams = window.location.href.split("?")[1];
    try {
        var decodedURLObject = decode(encodedURLParams);
        var decodedObject = queryStringToJSON(decodedURLObject);
        return decodedObject;
    }
    catch (e) {
        return {};
    }
};
export var getCookie = function (cookieName) {
    var name = "".concat(cookieName, "=");
    var cookieDecoded = decodeURIComponent(document.cookie); //to be careful
    var cookieArr = cookieDecoded.split("; ");
    var result;
    cookieArr.forEach(function (val) {
        if (val.indexOf(name) === 0)
            result = val.substring(name.length);
    });
    return result;
};
export var setCookie = function (cname, cvalue, exMins) {
    var d = new Date();
    d.setTime(d.getTime() + exMins * 60 * 1000);
    var expires = "expires=".concat(d.toUTCString());
    document.cookie = "".concat(cname, "=").concat(cvalue, ";").concat(expires, ";path=/");
};
export var checkAndChange = function (arrayToUpdate) {
    var updatedObject = arrayToUpdate.map(function (object) {
        object.expanded = false;
        if (object.items.length > 0) {
            checkAndChange(object.items);
        }
        return object;
    });
    return updatedObject;
};
export function convertToDate(dateString) {
    //  Convert a "dd/MM/yyyy" string into a Date object
    var d = dateString.toString().split("/");
    var dat = new Date("".concat(d[2], "/").concat(d[1], "/").concat(d[0]));
    return dat;
}
export var gridFieldMapper = {
    firstName: "First Name",
    lastName: "Last Name",
    client: "Client",
    document: "Document",
    jobStartDate: "Job Start Date",
    clientName: "Client",
    MASID: "MAS ID",
    TWClientID: "TW Client ID",
    TWDivisionID: "TW Division ID",
    effectiveDate: "Effective Date",
    expensePercent: "Expense Percent",
    startDate: "Start Date",
    readyToExport: "Ready to Export",
    account: "Account",
    status: "Status",
    newHireBatchID: "Batch ID",
    documentname: "Document Name",
    documenttype: "Document Type",
    documentType: "Document Type",
    isactive: "Is Active",
    isActive: "Is Active",
    minoronly: "Minor Only",
    signaturerequired: "Signature Required",
    Category: "Category",
    category: "Category",
    Required: "Required",
    documentStatus: "Document Status",
    accountName: "Account",
    newHireDocumentStatusDate: "Effective Date",
    jobStatus: "Job Status",
    agentName: "Agent Name",
    webUserStatus: "User Status",
    lastLoginSuccess: "Last Login Success",
    lastLoginFailure: "Last Login Failure",
    role: "Role",
    accountDescription: "Account Description",
    title: "Feature Name",
    FeatureDesc: "Description",
    name: "Document",
    Description: "Description",
    Name: "Role Name",
    description: "Description",
    attributeName: "Attribute Name",
    attributeLabel: "Attribute Label",
    attributeGroup: "Attribute Group",
    attributeGroupName: "Attribute Group Name",
    dataTypeName: "Data Type",
    attributeType: "Attribute Type",
    isSystemConfigured: "Is System Configured",
    documentName: "Document Name",
    documentCategory: "Document Category",
    state: "State",
    workflow: "Document Workflow",
    isMinor: "Minor Only",
    masid: "MAS ID",
    inputClientName: "Client",
    primaryAgentType: "PrimaryAgentType",
    primaryAgent: "PrimaryAgent",
    backAgentType: "BackAgentType",
    backUpAgent: "BackUpAgent",
    sectionName: "Section Name",
    sectionTitle: "Section Title",
    subSectionTitle: "Sub-Section Title",
    sectionTypeName: "Section Type Name",
    sectionDescription: "Section Description",
    approvalReverification: "Approval I-9 Reverification",
    approvedDate: "Approved Date",
    eventName: "Event Type",
    Type: "Value Type",
    condition: "Condition",
    action: "Action",
    attributes: "Attribute(s)",
    hrDocName: "Document Name",
    effectiveEndDate: "Effective End Date",
    dateWorked: "Date",
    position: "Position",
    projectCode: "Project Code",
    timeIn: "Time In",
    timeOut: "Time Out",
    mealHours: "Meals",
    doubleTimeHours: "DT Hours",
    workedHours: "Total Hours",
    additionalHours: "Addl Hours",
    guaranteedHours: "Guar Hours",
    mealPenaltyHours: "MP Hours",
    onSiteHours: "Onsite Hours",
    paidHours: "Total Hours",
    regularPay: "Reg Pay",
    oTPAY: "OT Pay",
    doubleTimePay: "DT Pay",
    nonWorkedPay: "NW Pay",
    mealPenaltyPay: "MP Pay",
    grossPay: "Gross Pay",
    weekEndingDate: "Week Ending Date",
    regularHours: "Regular Hours",
    overTimeHours: "Over Time Hours",
    totalHours: "Total Hours",
    required: "Required",
    availableHours: "Available Hours",
    city: "City",
    pageName: "Document Name",
    isSuccess: "Status",
    batchID: "Batch ID",
    serviceTrackingID: "Service Tracking ID",
    exceptionDetails: "Exception Details",
    createdDate: "Created Date",
    message: "Message",
    amount: "Amount",
    expenseCode: "Expense Code",
    statusDate: "Status Date",
    commentEnteredBy: "Comment By",
    comment: "Comment",
    transactionDate: "Transaction Date",
    addedDate: "Added Date",
    addedBy: "Added By",
    fileName: "File Name",
    companyName: "Company Name",
    prjPurchaseOrder: "Project Purchase Order",
    prjBegDt: "Project Begin Date",
    prjEndDt: "Project End Date",
    expirationDate: "Expiration Date",
    glAccountNumber: "GL Account Number",
    darwinEmployeeID: "Darwin Employee ID",
    masId: "MAS ID",
    invoiceDate: "Invoice Date",
    invoiceNumber: "Vendor Invoice Number",
    oT1HRS: "OT1 Hours",
    oT2HRS: "OT2 Hours",
    oT3HRS: "OT3 Hours",
    timeSheetStatus: "Status",
    manualPaymentType: "Manual Payment Type",
    invoicedAmount: "Invoiced Amount",
    statusCode: "Status Code",
    costCenter1: "Cost Center1",
    nonWorkedHoursCode: "NW Hours Code",
    emailAddress: "Email Address",
    phoneNumber: "Phone Number",
    date: "Date",
    costCenterTesting: "Cost Center Testing",
    clientname: "Name",
    expenseDate: "Expense Date",
    originalFileName: "File Name",
    daysWorked: "Days Worked",
    code: "Code",
    ncci: "NCCI",
    jobType: "Job Type",
    generalLiabilityPercent: "General Liability Percent",
    accountID: "ID",
    employeeIdentificationNumber: "EID",
    ssn: "SSN",
    PhoneNumber: "Phone Number",
    worker: "Worker",
    ddAuthorization: "DD Authorization",
    icAgreement: "IC Agreement",
    glcoi: "GL COI",
    wccoi: "WC COI",
    w9: "W9",
    vzPercentOfDay: "% of Day",
    subAccountName: "Project",
    projectTitle: "Project Title",
    budget: "Budget",
    calenderYear: "Calender Year",
    employeeNumber: "Employee Number",
    locSTAccrual: "Location st accrual",
    locCityAccrual: "Location city accrual",
    preYearCarryover: "Previous Year Carryover",
    calWorkHours: "Calender Work Hours",
    calAccruedHours: "Calender accrued Hours",
    calPSLAvailable: "Calender PSL Available",
    calPSLTaken: "Calender PSL Taken",
    thinkWareID: "ThinkWare Id",
    twDivisionID: "TW Division Id",
    nonWorkedHours: " Non Worked Hours",
    mealPenaltyHoursPaid: "Meal Penalty Hours",
    supervisorName: "Supervisor",
    oT1Pay: "OT1 Pay",
    oT2Pay: "OT2 Pay",
    oT3Pay: "OT3 Pay",
    expenseReportStatus: "Status",
    manualPaymentStatus: "Status",
    supervisor: "Supervisor",
    currentInvoicedAmount: "Current Amount",
    address: "Address",
    address2: "Address 2",
    zip: "Zip",
    notes: "Notes",
    w4Type: "W4 Type",
    projectName: "Project Name",
    voidedDate: "Voided Date",
    rateType: "Rate Type",
    rate: "Rate",
    vendorPaymentStatus: "Payment Status",
    fileNumber: "File Number",
    vendorID: "Vendor ID",
    logInAsEmployee: "Log in as Employee",
    workLocation: "Work Location",
    masterClient: "Master Client",
    darwinClientID: "Darwin Client ID",
    startEndDate: "Start Date - End Date",
    stateCounty: "County, State",
    miscItemCode: "Misc Item Code",
    taxable: "Taxable",
    receiptsNeeded: "Receipts Needed",
    recordType: "Record Type",
    no: "No",
    locSTEligible: "Location ST Eligible",
    locCityEligible: "Location City Eligible",
    initialEmpDatesWorked: "Initial Empl Days Worked",
    initialEmpHoursWorked: "Initial Empl Hours Worked",
    initialEmpEligibilityPassed: "Initial Empl Eligibility Passed",
    empEligibleDays: "Employee Eligible Days",
    empEligibleHours: "Employee Eligible Hours",
    accHoursWorked: "Accrual Hours Worked",
    pslAccDOE: "PSL Accessible Days of Employees",
    pslUsageCapsHours: "PSL Usage Caps Hours",
    pslCarryoverHours: "PSL Carryover Hours",
    accRateHours: "Accrual Rate Hours",
    accBeginDays: "Accrual Begin Days",
    accCapsHours: "Accrual Caps Hours",
    sa: "SA",
    note: "Note",
    availableBalance: "Available Balance",
    pendingTransactions: "Pending Transactions",
    currentBalance: "Current Balance",
    openingBudget: "Opening Budget",
    purchaseOrder: "Purchase Order",
    entryType: "Debit/Extend",
    entryDate: "Date",
    vendorPaymentStatusDate: "Status Date",
    vendorCost: "Fees",
    manualPaymentStatusDate: "Status Date",
    adminFee: "Fees",
    fees: "Fees",
    weekEnding: "Week Ending",
    totalCost: "Total Cost",
    weekDay: "Day",
    batchName: "Batch Name",
    refID: "Ref ID",
    acaHealthInsuranceAmount: "Amount",
    acaInsuranceEffectiveDate: "Effective Date",
    locationOfService: "Location of Service",
    productionStartDate: "Start Date",
    productionEndDate: "End Date",
    workOrderComplete: "Complete",
    minimumWageRate: "Minimum wage Rate",
    cityCountyState: "City, County, State",
    equipmentPercent: "Equipment Percent",
    vendorPaymentPercent: "Vendor Payment Percent",
    markupPercent: "Markup Percent",
    ficaPercent: "FICA Percent",
    projectBeginDate: "Project Begin Date",
    projectEndDate: "Project End Date",
    exportName: "Export Name",
    masExportStatus: "Export Status",
    payPeriodBeginDate: "Pay Period Begin Date",
    payPeriodEndDate: "Pay Period End Date",
    checkDate: "Check Date",
    shortName: "State",
    exportStatus: "Export Status",
    futaPercent: "FUTA Percent",
    sutaPercent: "SUTA Percent",
    overTimePay: "Overtime Pay",
    preBill: "Pre Bill",
    amountAvailableVendorInvoices: "Amount available for vendor invoices",
    allocated: "Allocated",
    enteredBy: "Entered By",
    workersCompCode: "Workers Comp Code",
    wcPercent: "Percent",
    gocCode: "GOC",
    comments: "Comments",
    clientSpecificForm: "Client Specific Form",
    accountCreated: "Account Created",
    itSecurityID: "IT Security ID",
    earningsCode: "Earnings Code",
    eventStartDate: "Event Start Date",
    eventEndDate: "Event End Date",
    glCode: "GL Code",
    endDate: "End Date",
    darwinCode: "Darwin Code",
    earningType: "Earning Type",
    active: "Active",
    oT1PAY: "OT1 Pay",
    oT2PAY: "OT2 Pay",
    oT3PAY: "OT3 Pay",
    adminPercent: "Admin Percent",
    federalWagesCapYear: "Year",
    federalWagesCapAmount: "Federal Unemployment",
    reportNames: "Attached Reports",
    minAmount: "Amount From",
    maxAmount: "Amount To",
    accountNames: "Project",
    emailSubject: "Email Subject",
    emailBody: "Email Body",
    userName: "User Name",
    deliveryList: "Send To Delivery List",
    reportName: "Report Name",
    webSiteTrainingLink: "Website Link",
    retraining: "Re Training",
    employeeOrSupervisor: "Employee Or Supervisor",
    effectiveFrom: "Effective Date",
    formattedExpirationDate: "Date Expired",
};
export var timesheetGridFilterMapper = {
    weekEndingDate: "Week Ending",
    regularHours: "Reg Hrs",
    overTimeHours: "OT Hrs",
    paidHours: "Paid Hrs",
    invoicedAmount: "Inv Amt",
    requisitionNumber: "Requisition Number",
    subAccountName: "Sub Account",
    nonWorkedHours: "NW Hrs",
    nonWorkedHoursCode: "NW Hrs Code",
    doubleTimeHours: "DT Hrs",
    oT1HRS: "OT1HRS",
    oT2HRS: "OT2HRS",
    oT3HRS: "OT3HRS",
    workedHours: "Tot Hrs",
    additionalHours: "Addl Hrs",
    guaranteedHours: "Guar Hrs",
    mealPenaltyHours: "MP Hrs",
    onSiteHours: "Onsite Hrs",
    overTimePay: "OT Pay",
    batchName: "Batch",
    daysWorked: "Days Wrkd",
    regularRateOfPay: "RRP",
    mealHours: "MI Hrs",
};
export var isValidRegex = function (regexString) {
    try {
        new RegExp(regexString);
        return true;
    }
    catch (e) {
        return false;
    }
};
export var stringToRegex = function (str) {
    /* eslint-disable no-useless-escape */
    var match = str.match(/^([\/~@;%#'])(.*?)\1([gimsuy]*)$/);
    return match
        ? new RegExp(match[2], match[3]
            // Filter redundant flags, to avoid exceptions
            .split("")
            .filter(function (char, pos, flagArr) { return flagArr.indexOf(char) === pos; })
            .join(""))
        : new RegExp(str);
};
export var setGridItemCount = function (count) {
    return localStorage.setItem("gridItemCount", count || AppConstant.GRID_ITEM_TAKE_SIZE);
};
export var getGridItemCount = function () {
    var gridItemCount = localStorage.getItem("gridItemCount") || AppConstant.GRID_ITEM_TAKE_SIZE;
    return parseInt(gridItemCount);
};
export var downloadDocument = function (pdfByteArray, fileName, type, fileExtension) {
    if (type === void 0) { type = "pdf"; }
    if (fileExtension === void 0) { fileExtension = null; }
    if (type === "zip") {
        var byteCharacters = atob(pdfByteArray); // Decode base64
        var byteNumbers = new Array(byteCharacters.length);
        for (var i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        var byteArray = new Uint8Array(byteNumbers);
        var blob = new Blob([byteArray], { type: "application/zip" });
        var url = URL.createObjectURL(blob);
        var a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
    }
    else {
        var linkSource = "data:application/".concat(type, ";base64,").concat(pdfByteArray);
        var downloadLink = document.createElement("a");
        downloadLink.href = linkSource;
        downloadLink.download = fileExtension
            ? "".concat(fileName).concat(fileExtension === null || fileExtension === void 0 ? void 0 : fileExtension.toLowerCase())
            : fileName;
        downloadLink.click();
    }
};
export var calculateHours = function (args) {
    var isEveryElementNull = args.every(function (element) { return element === null; });
    if (!isEveryElementNull) {
        return __spreadArray([], args, true).reduce(function (a, b) { return a + b; }, 0);
    }
    return null;
};
export var addZeroes = function (num, minimumFractionDigits) {
    if (minimumFractionDigits === void 0) { minimumFractionDigits = 2; }
    return num === null || num === void 0 ? void 0 : num.toLocaleString("en", {
        useGrouping: false,
        minimumFractionDigits: minimumFractionDigits,
    });
};
export var getUserDetailsFromLocalStorage = function (userType) {
    // userType if user is from pro then pass pro
    var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    if (userType === "pro") {
        localStorageKey = "".concat(localStorageKey, ":pro");
    }
    if (localStorage.getItem(localStorageKey)) {
        return JSON.parse(atob(localStorage.getItem(localStorageKey)));
    }
    return null;
};
export var getDay = function (endDate, dayAdd) {
    var next = new Date(new Date(endDate).getTime());
    next.setDate(new Date(endDate).getDate() + dayAdd);
    return next;
};
export var startTimeInterval = function () {
    setTimeout(function () {
        useCurrentViewContext.getState().setIsApiRequest(true);
    }, AppConstant.SESSION_AUTO_RENEW_TIME);
};
export var cleanedZipCode = function (value) { return (value === null || value === void 0 ? void 0 : value.replace(/[^0-9]/g, "")) || ""; };
export var modifiedZipCode = function (value) {
    var _a;
    return (_a = cleanedZipCode(value)
        .match(/.{1,5}/g)) === null || _a === void 0 ? void 0 : _a.join("-");
};
export var createWeekDays = function (weekEndingDate, weekDayCount, agreementDateRange) {
    if (weekDayCount === void 0) { weekDayCount = 7; }
    if (agreementDateRange === void 0) { agreementDateRange = []; }
    var weekArrayList = __spreadArray([], Array(weekDayCount), true).map(function (_, i) {
        var _a, _b;
        var d = new Date(weekEndingDate);
        d.setDate(d.getDate() - weekDayCount);
        d.setDate(d.getDate() + i + 1);
        var formateDate = formatDate(d, "EEEE, MMMM dd, yyyy");
        var isDisabled = true;
        if (agreementDateRange.length > 0) {
            for (var index = 0; index < agreementDateRange.length; index++) {
                var startDate = Date.parse((_a = agreementDateRange[index]) === null || _a === void 0 ? void 0 : _a.startDate), endDate = Date.parse((_b = agreementDateRange[index]) === null || _b === void 0 ? void 0 : _b.endDate);
                isDisabled = d.valueOf() >= startDate.valueOf() && d.valueOf() <= endDate.valueOf();
                if (isDisabled === true)
                    break;
            }
        }
        return {
            id: i + 1,
            value: formateDate,
            label: formateDate,
            dayIndex: i + 1,
            disabled: !isDisabled,
        };
    });
    return weekArrayList;
};
export var getTimeFromDate = function (inputDate) {
    var dateInput = new Date(inputDate);
    var Hours = String(dateInput.getHours()).padStart(2, "0");
    var Minutes = String(dateInput.getMinutes()).padStart(2, "0");
    return "".concat(Hours).concat(Minutes);
};
export var setHoursFromTime = function (inputTime) {
    var hours = inputTime.slice(0, 2);
    var minutes = inputTime.slice(2);
    var dateInput = new Date();
    dateInput.setHours(0, 0, 0, 0);
    dateInput.setHours(hours, minutes);
    return dateInput;
};
export var renderDangerouslySetInnerHTML = function (html) {
    return _jsx("span", { style: { display: "inherit" }, dangerouslySetInnerHTML: { __html: html } });
};
export var checkIfCorpEmployee = function () {
    var userProfile = window.location.href.includes("pro")
        ? getUserDetailsFromLocalStorage("pro")
        : getUserDetailsFromLocalStorage("");
    var isCorpEmployee = (userProfile === null || userProfile === void 0 ? void 0 : userProfile.WebUserRoleId) === WEBUSERROLEID.Employee &&
        window.location.href.includes("pro");
    return isCorpEmployee;
};
function isInteger(value) {
    return Number.isInteger(parseInt(value));
}
export var parseNumericValue = function (value) {
    if (!isNaN(value)) {
        if (isInteger(value)) {
            return parseInt(value);
        }
        else {
            return parseFloat(value);
        }
    }
    return 0;
};
/**
 * Get client side timezone.
 *
 * @returns {(+|-)HH:mm} - Where `HH` is 2 digits hours and `mm` 2 digits minutes.
 * @example
 * // From Indian/Reunion with UTC+4
 * // '+04:00'
 * getTimeZone()
 * return `${offsetOperator}${offsetHours}:${offsetMinutes}`
 */
export var getDateTimeFromCurrentTimeZone = function (currentDate) {
    if (currentDate !== "" && currentDate !== null && currentDate !== undefined) {
        var timezoneOffset = new Date().getTimezoneOffset();
        var offset = Math.abs(timezoneOffset);
        var offsetHours = Math.floor(offset / 60)
            .toString()
            .padStart(2, "0");
        var offsetMinutes = Math.floor(offset % 60)
            .toString()
            .padStart(2, "0");
        var currentDateTime = new Date(currentDate);
        var currentHours = currentDateTime.getHours();
        var currentMinutes = currentDateTime.getMinutes();
        if (timezoneOffset < 0) {
            currentDateTime.setHours(currentHours + parseInt(offsetHours));
            currentDateTime.setMinutes(currentMinutes + parseInt(offsetMinutes));
        }
        else if (timezoneOffset > 0) {
            currentDateTime.setHours(currentHours - parseInt(offsetHours));
            currentDateTime.setMinutes(currentMinutes - parseInt(offsetMinutes));
        }
        return currentDateTime;
    }
};
export var getPreviousDate = function (noOfDays) {
    var date = new Date();
    var previous = new Date(date.getTime());
    previous.setDate(date.getDate() - noOfDays);
    return previous;
};
export var cleanLocalUserStorage = function () {
    var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    var localStorageEmployeeKey = "".concat(AppConstant.LOCAL_STORAGE_EMPLOYEE_DATA, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID);
    localStorage.removeItem(localStorageKey);
    localStorage.removeItem(localStorageEmployeeKey);
    localStorage.removeItem("sessionextend");
    localStorage.removeItem("employeeInformation");
    localStorage.removeItem("selectedClient");
    localStorage.removeItem("remindMeLaterPopups");
    localStorage.removeItem("isAdminLoggedInAsEmployee");
    localStorage.removeItem("masterClientLoggedIn");
    localStorage.removeItem("adminClientLoggedIn");
    localStorage.removeItem("employeeUsername");
    localStorage.removeItem("defaultLandingPage");
    localStorage.removeItem("defaultLandingPagePRO");
    localStorage.removeItem("currentRouteAccessType");
    setCookie("IsLogoutTriggered", "", 0);
    setCookie(AppConstant.ADMIN_AS_EMPLOYEE_COOKIE_NAME, "", 0);
    localStorage.removeItem("isnewEmployeePopUpDismissed");
};
export var commaSepratedNumberWithStyle = function (number, style) {
    if (style === void 0) { style = "decimal"; }
    return new Intl.NumberFormat("en-US", {
        style: style,
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    }).format(number);
};
export var setMinEndDate = function (startDate) {
    if (startDate) {
        if (new Date(startDate).getTime() > new Date().getTime()) {
            return new Date(new Date(startDate).setDate(new Date(startDate).getDate()));
        }
        else {
            new Date();
        }
    }
    else {
        new Date();
    }
};
export var convertToMaskedPhoneNumber = function (value) {
    var phone = "(";
    value = (value === null || value === void 0 ? void 0 : value.replace(/[^0-9]/g, "")) || "";
    if (value) {
        for (var i = 0; i < value.length; i++) {
            phone = phone + value[i];
            if (i === 2) {
                phone = "".concat(phone, ") ");
            }
            if (i === 5) {
                phone = "".concat(phone, "-");
            }
        }
    }
    else {
        phone = "(___) ___-____";
    }
    return phone;
};
export var getNextDayOfWeek = function (date, dayOfWeek) {
    var targetDay = dayOfWeek % 7; // Ensure dayOfWeek is in the range [0, 6]
    var currentDay = date.getDay();
    var difference = targetDay - currentDay;
    var nextDay = difference >= 0 ? difference : difference + 7;
    var nextDate = new Date(date);
    nextDate.setDate(date.getDate() + nextDay);
    return nextDate;
};
export var getLastSunday = function (date) {
    var dayOfWeek = date.getDay();
    var daysUntilLastSunday = dayOfWeek === 0 ? 7 : dayOfWeek; // Calculate days until last Sunday
    var lastSunday = new Date(date);
    lastSunday.setDate(date.getDate() - daysUntilLastSunday); // Subtract days to get last Sunday
    return lastSunday;
};
export var redirectAdminUser = function (clientID) {
    var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID, ":pro");
    var userProfile = JSON.parse(atob(localStorage.getItem(localStorageKey)));
    userProfile.ClientId = clientID;
    if (localStorage.getItem("adminClientLoggedIn") && userProfile.ClientId === 0) {
        var selectedClient = JSON.parse(localStorage.getItem("selectedClient"));
        userProfile.ClientId = selectedClient === null || selectedClient === void 0 ? void 0 : selectedClient.clientID;
    }
    localStorage.setItem(localStorageKey, btoa(JSON.stringify(userProfile)));
    localStorage.setItem("adminClientLoggedIn", "true");
};
export var timeDifferenceInSeconds = function (inTime, outTime) {
    var outTimes = outTime.split(":");
    var inTimes = inTime.split(":");
    var hoursDifference = outTimes[0] - inTimes[0];
    var minDifference = outTimes[1] - inTimes[1];
    var totalTimeInMinutes = hoursDifference * 60 + minDifference;
    var totalTimeInSeconds = totalTimeInMinutes * 60;
    return totalTimeInSeconds;
};
export var generateRandomUUID = function () {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0, v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};
export var isObjectNotEmpty = function (obj) {
    return obj ? Object.keys(obj).length > 0 : false;
};
export var getQueryParams = function (url) {
    var params = new URLSearchParams(url.split("?")[1]);
    var queryParams = new Map();
    params.forEach(function (value, key) {
        queryParams.set(key, value);
    });
    return queryParams;
};
export var removeMenuSelection = function () {
    var selEle = document.querySelectorAll(".k-panelbar-item.k-selected");
    var selEleLink = document.querySelectorAll(".k-panelbar-item .k-selected");
    if (selEle.length) {
        selEle.forEach(function (element) {
            element === null || element === void 0 ? void 0 : element.classList.remove("k-selected");
        });
    }
    if (selEleLink.length) {
        selEleLink.forEach(function (element) {
            element === null || element === void 0 ? void 0 : element.classList.remove("k-selected");
        });
    }
};
export function truncateString(inputString, maxLength) {
    if (inputString.length <= maxLength) {
        return inputString;
    }
    else {
        return "".concat(inputString.substring(0, maxLength), "...");
    }
}
export function redirectToAdmin() {
    var element = document.querySelector(".application-loader");
    if (element)
        element.style.display = "block";
    var oidcUserProfile = getUserDetailsFromLocalStorage();
    var localStorageKey = "".concat(AppConstant.LOCAL_STORAGE_USER_PROFILE, ":").concat(process.env.AUTHORITY_URL, ":").concat(process.env.CLIENT_ID, ":pro");
    var userProfile = JSON.parse(atob(localStorage.getItem(localStorageKey)));
    userProfile.ClientId = oidcUserProfile === null || oidcUserProfile === void 0 ? void 0 : oidcUserProfile.ClientId;
    localStorage.setItem(localStorageKey, btoa(JSON.stringify(userProfile)));
    localStorage.removeItem("masterClientLoggedIn");
    localStorage.removeItem("adminClientLoggedIn");
    localStorage.removeItem("selectedClient");
    localStorage.setItem("defaultLandingPagePRO", "");
}
export var correctInvalidTimeStringValues = function (timeString) {
    // Split the time string into hours, minutes, and seconds
    var _a = timeString.split(":").map(Number), hours = _a[0], minutes = _a[1], seconds = _a[2];
    // Adjust values if they exceed their maximum allowed values
    var correctedHours = Math.min(23, hours);
    var correctedMinutes = Math.min(59, minutes);
    var correctedSeconds = Math.min(59, seconds);
    // Format the corrected values back into a time string
    var correctedTimeString = "".concat(String(correctedHours).padStart(2, "0"), ":").concat(String(correctedMinutes).padStart(2, "0"), ":").concat(String(correctedSeconds).padStart(2, "0"));
    return correctedTimeString;
};
export var sanitizedInputStrForNumericValue = function (inputStr, maxIntegerLength, maxFractionLength) {
    var sanitizedInput = inputStr.replace(/[^\d.]/g, "");
    var decimalCount = (sanitizedInput.match(/\./g) || []).length;
    if (decimalCount > 1) {
        sanitizedInput = sanitizedInput.substring(0, sanitizedInput.lastIndexOf("."));
    }
    var _a = sanitizedInput.split("."), integerPart = _a[0], _b = _a[1], decimalPart = _b === void 0 ? "" : _b;
    if (integerPart && integerPart.length > maxIntegerLength) {
        integerPart = integerPart.substring(0, maxIntegerLength);
    }
    if (decimalPart.length > maxFractionLength) {
        decimalPart = decimalPart.substring(0, maxFractionLength);
    }
    return decimalCount ? "".concat(integerPart, ".").concat(decimalPart) : integerPart;
};
export var allowNumberOnlyOnKeypress = function (event) {
    var charCode = event.which || event.keyCode;
    var key = event.key;
    var allowedKeys = /^[0-9\b\s]+$/;
    if (allowedKeys.test(key) ||
        charCode === 8 || // Backspace
        charCode === 9 || // Tab
        charCode === 13 || // Enter
        charCode === 27 || // Escape
        (charCode >= 37 && charCode <= 40) // Arrow keys
    ) {
        return true;
    }
    // If the pressed key is not a number or a navigation key, prevent input
    event.preventDefault();
    return false;
};
export var generateTaxYears = function () {
    // Get the current year
    var currentYear = new Date().getFullYear();
    // Create an array of tax years from 2019 to the current year plus one
    var taxYears = [];
    for (var year = 2019; year <= currentYear + 1; year++) {
        taxYears.push({ value: year, label: year });
    }
    return taxYears;
};
export var restrictSpaceOnKeyDown = function (event) {
    // Check if the pressed key is the space key (keyCode 32)
    if (event.keyCode === 32) {
        // Prevent the default behavior (in this case, preventing the space character from being typed)
        event.preventDefault();
    }
};
export var formatDateToRemoveTZ = function (dateValue, formatType) {
    if (formatType === void 0) { formatType = "yyyy-MM-dd"; }
    if (dateValue) {
        return formatDate(new Date(dateValue), formatType);
    }
    return dateValue;
};
export var formatDateWithTimeToRemoveTZ = function (dateValue, formatType) {
    if (formatType === void 0) { formatType = "yyyy-MM-ddTHH:mm:ss"; }
    if (dateValue) {
        return formatDate(new Date(dateValue), formatType);
    }
    return dateValue;
};
export var isValidDate = function (dateValue) {
    var parsedDate = new Date(dateValue);
    var year = parsedDate.getUTCFullYear();
    var month = parsedDate.getUTCMonth() + 1; //January is 0!
    var day = parsedDate.getUTCDate();
    // Check if year is valid (between 1 and 9999)
    if (year < 1900 || year > 9999) {
        return false;
    }
    // Check if month is valid (between 1 and 12)
    if (month < 1 || month > 12) {
        return false;
    }
    // Check if day is valid
    var lastDayOfMonth = new Date(year, month, 0).getDate();
    if (day < 1 || day > lastDayOfMonth) {
        return false;
    }
    return true;
};
export var formatRenderData = function (value, type) {
    if (type === MobileGridColumnTypes.DATE) {
        return formatDate(new Date(value), "MM/dd/yy");
    }
    else if (type === MobileGridColumnTypes.NUMBER) {
        return formatNumber(value, "n2");
    }
    else if (type === MobileGridColumnTypes.CURRENCY) {
        return formatNumber(value, "c2");
    }
    return value;
};
export var dynamicFieldSearch = function (array, query, fieldDefs) {
    return array.filter(function (item) {
        return fieldDefs.some(function (def) {
            var fieldValue = item[def.field];
            if (fieldValue != null) {
                return String(fieldValue).toLowerCase().includes(query.toLowerCase());
            }
            return false;
        });
    });
};
export var dynamicSorting = function (a, b, type) {
    if (type === MobileGridColumnTypes.STRING) {
        return a.localeCompare(b);
    }
    else if (type === MobileGridColumnTypes.NUMBER) {
        return a - b;
    }
    else if (type === MobileGridColumnTypes.CURRENCY) {
        var numA = parseFloat(a.replace("$", ""));
        var numB = parseFloat(b.replace("$", ""));
        return numA - numB;
    }
    else if (type === MobileGridColumnTypes.DATE) {
        var aDate = new Date(a);
        var bDate = new Date(b);
        if (!isNaN(aDate.getTime()) && !isNaN(bDate.getTime())) {
            return aDate.getTime() - bDate.getTime();
        }
        return 0;
    }
    else {
        return 0;
    }
};
export var isEmptyOrNull = function (field) {
    return (field === null ||
        field === undefined ||
        (typeof field === "string" && field.trim() === "") ||
        (Array.isArray(field) && field.length === 0) ||
        (typeof field === "object" && !Array.isArray(field) && Object.keys(field).length === 0));
};
function toMinutes(time) {
    var _a = time.split(":").map(Number), hours = _a[0], minutes = _a[1];
    return hours * 60 + minutes;
}
export var validateTimeOverlap = function (arrData) {
    var _a;
    var isValid = true;
    var errorArr = [];
    for (var i = 0; i < arrData.length; i++) {
        var timeInA = toMinutes(arrData[i].timeIn);
        var timeOutA = toMinutes(arrData[i].timeOut);
        for (var j = 0; j < arrData.length; j++) {
            if (i !== j) {
                var timeInB = toMinutes(arrData[j].timeIn);
                var timeOutB = toMinutes(arrData[j].timeOut);
                if ((timeInA < timeOutB && timeOutA > timeInB) ||
                    (timeInB < timeOutA && timeOutB > timeInA)) {
                    if (!((_a = errorArr === null || errorArr === void 0 ? void 0 : errorArr[i]) === null || _a === void 0 ? void 0 : _a.error)) {
                        errorArr[i] = {
                            error: ComponentConstant.TIME_OVERLAP,
                            fieldIndex: arrData[i].fieldIndex,
                        };
                        isValid = false;
                    }
                }
            }
        }
    }
    return { isValid: isValid, errorArr: errorArr };
};
