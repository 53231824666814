var TranslationConstant = {
    TIME_CLOCK_MANAGEMENT: "TimeClockManagement",
    ADD_MISSING_TIME: "AddMissingTime",
    EDIT_MISSING_TIME: "EditMissingTime",
    MINUTES_UNTIL_AUTO_LOGOUT: "MinutesUntilAuto",
    SOMETHING_WENT_WRONG: "SomethingWentWrong",
    TRY_AGAIN: "TryAgain",
    FOOTER: "Footer",
    YES: "Yes",
    NO: "No",
    WELCOME: "Welcome",
    MINUTES_UNTILL_AUTO: "MinutesUntilAuto",
    LOGOUT: "Logout",
    DASHBOARD: "Dashboard",
    GO_TO_TEXT: "GoToText",
    GO_TO_IC_ADVISOR: "GoToICAdvisor",
    GO_TO_PAYREEL_ONLINE: "GoToPayReelOnline",
    GO_TO_ONBOARDING: "GoToOnBoarding",
    LABEL_FIRST_NAME: "LabelFirstName",
    LABEL_MIDDLE_NAME: "LabelMiddleName",
    LABEL_LAST_NAME: "LableLastName",
    LABEL_PHONE_NUMBER: "LabelPhoneNumber",
    LABEL_CELL_PHONE: "LabelCellPhone",
    LABEL_EMPLOYEE_EMAIL: "LabelEmployeeEmail",
    LABEL_EMAIL: "LabelEmail",
    LABEL_START_DATE: "LabelStartDate",
    LABEL_END_DATE: "LabelEndDate",
    LABEL_CLIENT: "LableClient",
    LABEL_SEARCH: "SearchButton",
    LABEL_DEFAULT_OPTION_SELECT: "LabelDefaultOptionSelect",
    LABEL_DEFAULT_OPTION_CLIENT: "LabelDefaultOptionClient",
    LABEL_DEFAULT_OPTION_ACCOUNT: "LabelDefaultOptionAccount",
    LABEL_DEFAULT_OPTION_WORK_STATE: "LabelDefaultOptionWorkState",
    LABEL_DEFAULT_OPTION_SUPERVISOR: "LabelDefaultOptionSupervisor",
    LABEL_DEFAULT_OPTION_AGENT: "LabelDefaultOptionAgent",
    LABEL_ACCOUNT: "LabelAccount",
    LABEL_WORK_STATE: "LabelWorkState",
    LABEL_SUBMIT: "LabelSubmit",
    BACK_BUTTON: "BackButton",
    EMPLOYEE_TITLE: "EMPLOYEE_TITLE",
    LABEL_JOB_STATUS: "JobStatus",
    CLIENT_TITLE: "Full Name",
    VIEW_JOB_DOC_FOR_APPROVAL_TITLE: "ViewJobForApproval",
    LABEL_DEFAULT_OPTION_JOB_STATUS: "LabelDefaultOptionJobStatus",
    SEARCH_NEWHIRE_DOCUMENT_TITLE: "SearchNewHireDocument",
    SEARCH_NEWHIRE_DOCUMENT_HEADING: "Pleasefillsearchcriteria",
    SEARCH_NEW_HIRE_JOBS: "SearchNewHireJobs",
    NEWHIRE_FORM_HEADING: "NewHireFormHeading",
    LABEL_SAVE_BUTTON: "SaveButton",
    LABEL_EFFECTVE_DATE: "LabelEffectiveDate",
    LABEL_EFFECTVE_END_DATE: "LabelEffectiveEndDate",
    LABEL_DOCUMENT_TYPE_TO_UPLOAD: "LabelDocumentTypeToUpload",
    NEW_CLIENT_BY_EMPLOYEE_INPUT: "NewClientbyEmployeeinput",
    RECORDS_WITH_EXISTING_CLIENTS: "Recordswithexistingclients",
    CREATED_JOBS_PAST_START_DATE: "CreatedJobsPastStartDate",
    CREATED_JOBS_THAT_NEED_REVIEW: "CreatedJobsThatNeedReview",
    INCOMPLETE_JOBS_PAST_START_DATE: "IncompleteJobsPastStartDate",
    INPROGRESS_JOBS_PAST_START_DATE: "InprogressJobsPastStartDate",
    INPROGRESS_JOBS_THAT_NEED_REVIEW: "InprogressJobsThatNeedReview",
    JOBS_AWAITING_APPROVAL: "JobsAwaitingApproval",
    COUNT_PAYREEL_JOBS_APPROVAL: "CountPayreelJobsApproval",
    EXPIRED_VISA: "ExpiredVisa",
    EXPIRED_VISA_COUNT: "ExpiredVisaCount",
    PAYREEL_TASK: "PayreelTask",
    PAYREEL_TASK_COUNT: "PayreelTaskCount",
    AGENT_TASK: "AgentTask",
    AGENT_TASK_COUNT: "AgentTaskCount",
    LOCKED_USERS: "LockedUsers",
    REPEAT_I9_APPROVAL_LIST: "RepeatI9ApprovalList",
    REPEAT_I9_LIST: "RepeatI9list",
    JOBS_FOR_REVERIFICATION: "JobsForReverification",
    REVERIFICATION_PENDING_APPROVAL: "ReverificationPendingApproval",
    LOGOUT_CONFIRM_HEADING: "LogoutConfirmHeading",
    LOGOUT_SURE_TEXT: "LogoutSureText",
    GROUP_NAME: "Group Name",
    DESCRIPTION: "Description",
    ADD_NEW_ATTR_GRP: "AddNewAttributeGroup",
    ADD_NEW_ATTR: "AddNewAttribute",
    EDIT_NEW_ATTR: "EditNewAttribute",
    ADD_ATTR_HEADING: "Follow the steps to add/edit new attributes",
    ATTR_NAME: "Attribute Name",
    EDIT: "Edit",
    DELETE: "Delete",
    PUBLISH: "Publish",
    PUBLISHED: "Published",
    SUCCESSFULLY_PUBLISHED: "SuccessPublished",
    VIEW: "View",
    ATTRIBUTE_GROUP: "AttributeGroup",
    ATTRIBUTE_TYPE: "AttributeType",
    DATA_TYPE: "DataType",
    SELECT_DATA_TYPE: "SelectDataType",
    LABEL_DEFAULT_OPTION_GROUP: "LabelDefaultOptionGroup",
    SELECT_ATTRIBUTE_TYPE: "SelectAttributeType",
    MANAGE_FEATURES: "ManageFeatures",
    MANAGE_ROLES: "ManageRoles",
    MANAGE_USERS: "ManageUsers",
    SECURITY_ROLES_FEATURE: "SecurityRolesFeature",
    LABEL_SAVE_MAPPING: "LabelSaveMapping",
    LABEL_CLEAR_MAPPING: "LabelClearMapping",
    APPLICATION_TYPE_TEXT: "ApplicationTypeText",
    LABEL_RESET: "LabelReset",
    LABEL_CANCEL: "LabelCancel",
    IS_ACTIVE: "IsActive",
    SELECT_YES: "Yes",
    SELECT_NO: "No",
    VIEW_NEW_HIRE_JOB_DOC_TITLE: "ViewNewHireJobDocTitle",
    DOCUMENT_VIEW_TITLE: "DocumentViewTitle",
    BUTTON_APPROVE: "ButtonApprove",
    BUTTON_NOT_APPROVE: "ButtonNotApprove",
    LABEL_EXPIRY_DATE: "LabelExpiryDate",
    CLOSE_BUTTON: "CloseButton",
    FEATURE_ROLE_LABEL: "FeatureRoleLabel",
    EMPLOYEE_IDENTIFICATION_TITLE: "EmployeeIdentificationTitle",
    FEATURE_MAPPING_SUCCESS_HEADING: "FeatureMappingSuccessHeading",
    FEATURE_MAPPING_SUCCESS_MESSAGE: "FeatureMaapingSuccessMessage",
    FEATURE_MAPPING_FAILURE_HEADING: "FeatureMappingFailureHeading",
    FEATURE_MAPPING_FAILED_MESSAGE: "FeatureMappingFailedMessage",
    MANAGE_FEATURE_UPDATE_SUCCESS_MESSAGE: "ManageFeatureUpdateSuccessMessage",
    MANAGE_FEATURE_UPDATE_FAILURE_MESSAGE: "ManageFeatureUpdateFailureMessage",
    ATTR_OPTIONS: "AttrOptions",
    ATTR_RADIOBUTTON: "AttrRadioButton",
    APPLIED_FILTERS: "AppliedFilters",
    SHOW_FILTERS_TEXT: "ShowFiltersText",
    HIDE_FILTERS_TEXT: "HideFiltersText",
    ARE_YOU_SURE_TEXT: "AreYouSureText",
    ARE_YOU_SURE_TEXT_PUBLISH: "AreYouSureTextPublish",
    ATTRIBUTE_GROUP_DELETED: "AttributeGroupDeleted",
    CUSTOM_FORM_PUBLISHED: "CustomFormPublished",
    ATTRIBUTE_NOT_DELETED_MAPPING: "AttributeNotDeletedMapping",
    ATTRIBUTE_GROUP_UPDATED_SUCCESS: "AttributeGruoupUpdatedSuccess",
    ATTRIBUTE_ADDED_SUCCESS: "AttributeAddedSuccess",
    UPDATE_ATTRIBUTE_GROUP_FAIL: "UpdateAttributeGruoupFail",
    SUCCESSFULLY_CANCEL_TEXT: "SuccessfullyCancelText",
    SUCCESSFULLY_DELETE_TEXT: "SuccessfullyDeleteText",
    DELETE_FAILURE_TEXT: "DeleteFailureText",
    PUBLISH_FALIURE: "CustomFormPublishFailure",
    EDIT_ATTRIBUTE_GROUP_TITLE: "EditAttributeGroupTitle",
    UPDATED_SUCCESSFULLY: "UpdatedSuccessfully",
    UPDATE_FAILED: "UpdateFailed",
    SAVED_SUCCESSFULLY: "SavedSuccessfully",
    SAVE_FAILED: "SaveFailed",
    MANAGE_SYSTEM_FIELDS: "ManageSystemFields",
    ACRO_FIELDS: "AcroFields",
    SYSTEM_FIELDS: "SystemFields",
    IC_WORKER_STATUS_TITLE: "ICWorkerStatusTitle",
    APPROVE_JOBS_TITLE: "ApproveJobsTitle",
    ATTRIBUTE_SAVED_SUCCESS: "AttributeSavedSuccess",
    SYSTEM_FIELDS_SAVED_SUCCESS: "systemFieldsSavedSuccess",
    SYSTEM_FIELDS_SAVED_FAILED: "systemFieldsSavedFailed",
    ATTRIBUTE_SAVED_FAILED: "AttributeSavedFailed",
    ATTRIBUTE_DELETED: "AttributeDeleted",
    ATTRIBUTE_SYSTEM_CONFIGURED: "AttributeSystemConfigured",
    DELETE_ATTRIBUTE: "DeleteAttribute",
    EXPIRED_VISA_TITLE: "ExpiredVisaTitle",
    CLICK_TO_SEND_MAIL: "ClickToSendMail",
    UPLOAD_NEW_HR_DOCUMENT: "UploadHRDocument",
    STEPS_TO_UPLOAD_HR_DOCUMENT: "StepsToUploadNewHRDocument",
    EMPLOYER_POSTER: "EmployerPoster",
    HR_DOCUMENT_NAME: "HRDocumentName",
    ISACTIVE: "IsActive",
    LABEL_FOR_UPLOAD_DOCUMENT: "PleaseUploadYourDocumnet",
    VIEW_HR_DOCUMENTS: "ViewHRDocuments",
    UPLOAD_HR_DOCUMENTS: "UploadHRDocuments",
    VIEW_FORM_TEMPLATES_TITLE: "ViewFormTemplates",
    MANAGE_NEW_ATTR: "ManageAttributeMapping",
    MANAGE_ATT_HEADING: "ManageAttributeHeading",
    ATTRIBUTES: "Attributes",
    ADD_NEW_MAPPING: "AddNewMapping",
    MANAGE_JOB_DOCUMENTS_HEADING: "ManageJobHeading",
    MANUALLY_ADD_JOB_HEADING: "SelectADcoumentToAdd",
    LABEL_SCREENING_COMPLETED_SUCCESSFULLY: "ScreeningCompleted",
    LABEL_DOCUMENT_SELECT: "SelectDocument",
    ADD_DOCUMENTS_BUTTON: "AddDocuments",
    DOCUMENT_NAME: "DocumentName",
    FORM_VERSION: "FormVersion",
    DOCUMENT_CATEGORY: "DocumentCategory",
    DOCUMENT_WORK_FLOW: "DocumentWorkFlow",
    DOCUMENT_TYPE_TEXT: "IsRequiredDocument",
    NEW_HIRE_DOCUMENT_TYPE_TEXT: "NewHireDocumentType",
    FOLLOW_STEPLS_UPLOAD_NEW_FORM: "FollowStepsUploadNnewForm",
    MINOR_ONLY: "Minor Only",
    UPLOAD_NEW_FORM: "UploadNewForm",
    UPLOAD_YOUR_DOCUMET: "PleaseUploadYourDocument",
    LABEL_STATE: "State",
    EDIT_UPLOAD_FORM_HEADING: "EditUploadFormHeading",
    UPLOAD_FORM_SUBMIT_SUCCESS: "UploadForSubmitSuccess",
    UPLOAD_FORM_SUBMIT_FAILED: "UploadFormSubmitFailed",
    UPLOAD_FORM_PATCH_SAVE_SUCCESS: "UploadFormPatchSaveSuccess",
    UPLOAD_FORM_PATCH_SAVE_FAILED: "UploadFormPatchSaveFailed",
    USERNAME_SET_INFORMATION: "UserNameSetInformation",
    PASSWORD_SET_INFORMATION: "PasswordSetInformation",
    SEARCH_WEB_USERS_TITLE: "SearchWebUsers",
    LABEL_DEFAULT_OPTION_USER_ROLE: "LabelDefaultOptionUserRole",
    LABEL_DEFAULT_OPTION_USER_STATUS: "LabelDefaultOptionUserStatus",
    LABEL_USER_STATUS: "LabelUserStatus",
    LABEL_USER_ROLE: "LabelUserRole",
    PAYREEL_TASKS_HEADING: "PayReelTasks",
    EMPLOYEE_BOARD_HEADING: "EmployeeHeading",
    FORMS_TO_COMPLETE_HEADING: "FormsToComplete",
    LABEL_SEARCH_CUSTOM_FORMS: "SearchCustomForms",
    LABEL_EFFECTIVE_FROM: "EffectiveStartDate",
    LABEL_EFFECTIVE_TO: "EffectiveEndDate",
    LABEL_BOTH: "Both",
    IS_BULK_SIGN_IN_TEXT: "IsBulkSignIn",
    VIEW_THIS_FORM: "ViewThisForm",
    MANAGE_ATTRIBUTES_MAPPING_TEXT: "ManageAttributeMappingText",
    MANAGE_SYSTEM_FIELDS_TEXT: "ManageSystemFieldsText",
    DYNAMIC_CUSTOM_FORM: "DynamicCustomForm",
    INCORRECT_UPLOADED_FILE: "IncorrectUploadedFile",
    EMAIL_SEND_SUCCESS: "EmailSent",
    EMAIL_SEND_FAILED: "EmailSentFailed",
    EMAIL_SENT_TITLE: "EmailSuccessfullySentTitle",
    EMAIL_FAILURE_TITLE: "EmailFailedTitle",
    ASSIGN_TEMP_AGREEMENTS_TITLE: "AssignTempTitle",
    ASSIGN_TEMP_AGREEMENTS_HEADING: "AssignTempHeading",
    AGENTS_TASKS_HEADING: "AgentTasksHeading",
    DOCUMENT_VIEW_HEADING: "DocumentViewHeading",
    WELCOME_TO_PAYREEL: "WelComeToPayreel",
    REQUIRED_FORMS_HEADING: "RequiredFormHeading",
    OPTIONAL_FORMS_HEADING: "OptionalFormHeading",
    UPLOAD_FORM_DOCUMENT_TYPE_HEADING: "UploadFormDocumentTypeHeading",
    UPLOAD_DOCUMENT_BTN: "UploadDocumentBtn",
    MANUALLY_UPLOAD_NEWHIRE_FORM_TITLE: "ManuallyUploadNewHireFormTitle",
    SELECT_DOCUMENTS_TO_UPLOAD_TITLE: "SelectDocumentsToUploadTitle",
    RESEND_CREATE_USER_LINK: "ResendCreateUserLink",
    RESEND_EMAIL_I9_VERIFIER: "ResendEmailI9Verifier",
    DOCUMENT_ADDED_SUCCESSFULLY_HEADING: "DocumentAddedSuccesfully",
    DOCUMENT_ADDED_SUCCESS_MESSAGE: "DocumentAddedSuccessMessage",
    DOCUMENT_ADDED_FAILURE_HEADING: "DocumentAddedFailure",
    DOCUMENT_ADDED_FAILURE_MESSAGE: "DcumenAddedFailureMesage",
    LABEL_WORK_VISA_DATE: "LabelWorkVisaDate",
    DOCUMENT_UPLOADED_SUCCESSFULLY: "DocumentUploadedSuccess",
    DOCUMENT_UPLOADED_FAILURE: "DocumentUploadedFailure",
    DOCUMENT_UPLOADED_SUCCESS_MESSAGE: "DocumentUploadedSuccessMessage",
    DOCUMENT_UPLOADED_FAILURE_MESSAGE: "DocumentUploadedFailureMessage",
    DELETED_SUCCESS_MESSAGE: "DeleteSuccessMessage",
    DELETED_FAILED_MESSAGE: "DeleteFailedMessage",
    CANCEL_JOB_SURE_MESSAGE: "CancelJobSureMessage",
    JOB_DELETED_SUCCESS_MESSAGE: "jobDeletedSuccess",
    JOB_DELETED_ERROR_MESSAGE: "JObDeletedErrorMessage",
    EDIT_JOB_PAGE_TITLE: "EditJobPageTitle",
    EDIT_JOB_FORM_HEADING: "EditJobFormHeading",
    SAVE_JOB: "SaveJob",
    AGENT_TYPE_CLIENT: "AgentTypeClient",
    AGENT_TYPE_PAYREEL: "AgentTypePayreel",
    AGENT_TYPE_EXTERNAL: "AgentTypeExternal",
    AGENT_TYPE_EMPLOYEE_AUTHORIZED: "AgentTypeEmployeeAuthorized",
    LABEL_EMPLOYEE_POSITION: "LabelEmployeePosition",
    LABEL_EMPLOYEE_RATE: "LabelEmployeeRate",
    LABEL_EMPLOYEE_RATE_TYPE: "LabelEmployeeRateType",
    LABEL_CHECKBOX_EXEMPT: "LableCheckboxExempt",
    LABEL_SUPERVISOR_1: "LabelSupervisor1",
    LABEL_SUPERVISOR_2: "LabelSupervisor2",
    LABEL_PRIMARY_AGENT_TYPE: "LabelPrimaryAgentType",
    LABEL_BACKUP_AGENT_TYPE: "LabelBackupAgentType",
    LABEL_PRIMARY_AGENT_LIST: "LabelPrimaryAgentList",
    LABEL_BACKUP_AGENT_LIST: "LabelBackupAgentList",
    LABEL_MANUALLY_UPLOAD_FORM: "LabelManuallyUploadForm",
    LABEL_EMPLOYEE_MINOR: "LabelEmployeeMinor",
    LABEL_GUARDIAN_TYPE: "LabelGuardianType",
    LABEL_GUARDIAN_FIRST_NAME: "LabelGurardianFirstName",
    LABEL_GUARDIAN_LAST_NAME: "LabelGuardianLastName",
    LABEL_CHECKBOX_FOR_IC_STATUS: "LabelCheckoxForICStatus",
    LABEL_CHECKBOX_DIRECT_DEPOSIT_NOT_ALLOWED: "LabelChexboxDirectDeposit",
    LABEL_SCREENING_REQUIRED: "LabelScreeingRequired",
    LABEL_START_ONBAORDING_PROCESS: "LabelStartOnBoardingProcess",
    LABEL_ADD_EMPLOYEE_JOB: "LabelAddEmployeeJob",
    ADD_NEW_JOB_FORM_HEADING: "AddNewJobFormHeading",
    ADD_NEW_JOB_PAGE_TITLE: "AddNewJobPageTitle",
    GUARDIAN_TYPE_PARENT: "GuardiaTypeParent",
    GUARDIAN_TYPE_LEGAL_GUARDIAN: "GuardiaTypeLegalGuardian",
    RATE_TYPE_HOURLY: "RateTypeHourly",
    RATE_TYPE_HALF_DAY: "RateTypeHalfDay",
    RATE_TYPE_FULL_DAY: "RateTypeFullDay",
    RATE_TYPE_WEEKLY: "RateTypeWeekly",
    APPROVE_JOB_BUTTON: "JobButton",
    JOB_APPROVED_SUCCESSFULLY: "JobApprovedSuccessfuly",
    JOB_APPROVED_FAILED: "JobApprovedFailed",
    JOB_APPROVED_SUCCESS_MESSAGE: "JobApproveSuccessMessage",
    JOB_APPROVE_FAILED_MESSAGE: "JobApproveFailedMessage",
    ADD_ICWORKER_JOB_SUCCESS_MESSAGE: "AddICWorkerJobSuccess",
    ADD_ICWORKER_JOB_FAILURE_MESSAGE: "AddICWorkerJobFailure",
    ATTRIBUTE_MAPPING_DELETED_SUCCESS: "AttributeMappingDeletedSuccess",
    ATTRIBUTE_MAPPING_DELETED_FAILED: "AttributeMappingDeletedFailed",
    SEARCH_CUSTOM_FORM_HEADING: "SearchCustomFormHeading",
    LABEL_PAYREEL_COORDINATOR_LIST: "LabelPayReelCoordiantorList",
    LABEL_TOTAL_COMPENSASATION: "LabelTotalCompenstation",
    LABEL_WEEKLY_RATES: "LabelWeeklyRates",
    ADD_NEW_JOB_CREATED_SUCCESS: "AddNewJobCreatedSuccess",
    ADD_NEW_JOB_CREATED_FAILURE: "AddNewJobCreatedFailure",
    LABEL_DAILY_HOURS: "LabelDailyHours",
    NEW_HIRE_JOB_UPDATED: "NewHireJobUpdated",
    RETURN_TO_DASHBOARD: "ReturnToDashboard",
    APPROVE_JOBS_SURE_TEXT: "ApproveJobsSureText",
    ONBOARDING_REPORT_TITLE: "OnboardingReportTitle",
    CREATE_REPORT_BTN: "CreateReportBtn",
    DOCUMENT_CANCEL_SUCCESS_MESSAGE: "DocumentHasBeenCancelled",
    LABEL_PAYMENT: "LabelPayment",
    TARGET_ATTR: "TargetAttribute",
    EVENT_TYPE: "EventType",
    OPERATOR_TYPE: "OperatorType",
    ACTION: "ActionType",
    STATIC: "Static",
    DYNAMIC: "Dynamic",
    VALUEOF: "Value Of",
    UI_VALIDATION: "UIValidation",
    UI_MANIPULATION: "UIManipulation",
    MANAGE_VALIDATION: "ManageValidation",
    ADD_NEW_VALIDATION_FORM_HEADING: "AddNewValidationFormHeading",
    LABEL_DEFAULT_OPTION_DOCUMENT_STATUS: "LabelDefaultOptionDocumentStatus",
    LABEL_DEFAULT_OPTION_DOCUMENT_TYPE: "LabelDefaultOptionDocumentType",
    LABEL_DOCUMENT_STATUS: "LabelDocumentStatus",
    LABEL_DOCUMENT_TYPE: "LabelDocumentType",
    I9_REVERIFICATION_HEADING: "I9ReverificationGrid",
    I9_NEW_CLIENT_HEADING: "I9NewClientHeading",
    I9_EXISTING_CLIENTS_HEADING: "I9ExistingClientsHeading",
    I9_REVERIFICATION_MASID_TITLE: "MasidTitle",
    DATE_UPDATE_SUCCESS_MESSAGE: "DateUpdateSuccessMessage",
    DATE_UPDATE_FAILURE_MESSAGE: "DateUpdateFailureMessage",
    SET_START_DATE_HEADING: "SetStartDateHeading",
    CLIENT_DELETE_SUCCESS_MESSAGE: "ClientDeleteSuccess",
    CLIENT_DELETE_FAILURE_MESSAGE: "ClientDeleteFailure",
    UPDATE_TEXT: "UpdateText",
    VIEW_MORE: "ViewMore",
    I9_CLIENT_UPDATE_SUCCESS_MESSAGE: "I9ClientUpdateSuccess",
    I9_CLIENT_UPDATE_FAILURE_MESSAGE: "I9ClientUpdateFailure",
    MANAGE_VALIDATIONS: "ManageValidations",
    MANAGE_ATTR_VAL: "ManageAttributeValidation",
    UI_VAL: "UIValidations",
    UI_MAN: "UIManipulations",
    SAVE_VALIDATION: "SaveValidation",
    SAVE_MANIPULATION: "SaveManipulation",
    EMAIL_SEND_CONFIRMATION_MESSAGE: "EmailSendConfimation",
    SUPPORTING_DOCUMENT_VIEWER: "ViewSupportingDocument",
    REFRESH_GRID_BTN_TEXT: "RefreshGridText",
    REFRESH_HEADING: "RefreshHeading",
    REFRESH_GRID_MESSAGE: "RefreshGrifMessage",
    CHANGE_I9_VERIFIER: "ChangeI9Verifier",
    SECTION_NAME: "Section Name",
    SUB_SECTION_TITLE: "Sub-Section Title",
    SECTION_TYPE: "Section Type",
    SECTION_TITLE: "Section Title",
    ADD_NEW_SECTION: "AddNewSection",
    SECTION_ADDED_SUCCESS: "SectionAddedSuccessfully",
    SECTION_CREATION_FAILED: "SectionCreationFailed",
    SECTION_UPDATED_SUCCESS: "SectionUpdatedSuccessfully",
    UPDATE_SECTION_FAIL: "SectionUpdateFailed",
    EDIT_SECTION_TITLE: "EditSectionTitle",
    SECTION_NOT_DELETED_MAPPING: "SectionNotDeletedMapping",
    SECTION_NOT_DELETED: "SectionNotDeleted",
    SECTION_DELETED: "SectionDeletedSuccess",
    EDIT_WEB_USERS_TITLE: "EditWebUsers",
    LABEL_USER_NAME: "LabelUserName",
    LABEL_LAST_LOGIN_SUCCESS: "LabelLastLoginSuccess",
    LABEL_LAST_LOGIN_FAILURE: "LabelLastLoginFailure",
    EDIT_WEB_USER_FORM_DESCRIPTION: "EditWebUserFormDescription",
    SEND_PASSWORD_RESET_EMAIL_TO_USER: "SendPasswordResetEmailToUser",
    LABEL_UPDATE_BTN: "LabelUpdateBtn",
    VIEW_DOCUMENTS: "ViewDocuments",
    RETURN_TO_SEARCH_WEB_USER: "ReturnToSearchWebUser",
    WEB_USER_UPDATE_SUCCESS: "WebUserUpdatedSuccessfully",
    WEB_USER_UPDATE_FAIL: "WebUserUpdationFailed",
    SCREENING_COMPLETED_SUCCESS_MESSAGE: "ScreeningCompletedSuccess",
    SCREENING_COMPLETED_FAILURE_MESSAGE: "ScreeningCompletedFailure",
    VIEW_JOB_DOCUMENTS_TITLE: "ViewJobDocuments",
    I9_REVERIFICATION_APPROVED: "I9ReverificationApproved",
    PENDING_APPROVALS: "PendingApprovals",
    APPROVED_LISTS: "ApprovedLists",
    RETURN_TO_HR_DOCUMENT: "ReturnToHRDocument",
    SIGNATURE_PAD_FORM_TITLE: "SignaturePadFormTitle",
    SIGN_AND_SAVE_FORM_TITLE: "SignAndSaveForm",
    REQUIRED_FIELD_MARKED_SYMBOL: "RequiredFieldMarkedSymbol",
    SIGNATURE_PAD_ATTENTION_TEXT_DYNAMIC_FORM: "SignaturePadAttentionTextDynamicForm",
    SIGNATURE_PAD_WARNING_TEXT_DYNAMIC_FORM: "SignaturePadWarningTextDynamicForm",
    SIGNATURE_PAD_ATTENTION_TEXT: "SignaturePadAttentionText",
    SIGNATURE_PAD_WARNING_TEXT: "SignaturePadWarningText",
    SIGNATURE_PAD_GUARDIAN_NOTICE_HEADING: "SignaturePagGuardianNoticeHeading",
    SIGNATURE_PAD_GUARDIAN_NOTICE_TEXT: "SignaturePagGuardianNoticeText",
    USE_FINGER_OR_MOUSE_TEXT: "UseFingerOrMouseText",
    DRAW_YOUR_SIGNATURE: "DrawYourSignature",
    CLEAR_TEXT: "ClearText",
    DYNAMIC_FORM_SUBMIT_FAILURE: "DynamicFormSumbitFailure",
    DIRECT_DEPOSIT_AUTH: "DirectDepositAuthorization",
    FINANCIAL_INS: "FinancialInstitution",
    ACC_Type: "AccountType",
    BRANCH_STREET_ADD: "BranchStreetAddress",
    BRANCH_CITY: "BranchCity",
    BRANCH_STATE: "BranchState",
    TRANSIT_ROUTING_NUMBER: "TransitRoutingNumber",
    CNF_TRANSIT_ROUTING_NUMBER: "ConfirmTransitRoutingNumber",
    ACC_NUM: "AccountNumber",
    CNF_ACC_NUM: "ConfirmAccountNumber",
    CHECKING: "Checking",
    SAVING: "Saving",
    IMG_REF_MSG: "imagerefmsg",
    SUCCESSFULLY_MANIPULATION_DELETE_TEXT: "SuccessfullyManipulationDelete",
    UPLOAD_DOCUMENT_SUBMIT_SUCCESS: "UploadDocumentSuccess",
    VOID_CHECK_MSG: "voidcheckmsg",
    LABEL_CONFIRM_PASSWORD: "LabelConfirmPassword",
    CREATE_USER_ACKNOWLEDGMENT_TEXT: "CreateUserAcknwledgermentText",
    CREATE_USER_PRIVACY_POLICY_TEXT: "CreateUserPrivacyPolicyText",
    CREATE_USER_BUTTON: "CreateUserButton",
    CREATE_USER_HEADING: "CraeteUserHeading",
    CREATE_USER_DESCRIPTION: "CreateUserDescription",
    LABEL_USERNAME: "LabelUserName",
    LABEL_PASSWORD: "LabelPassword",
    RETURN_TO_LOGIN_PAGE: "ReturntoLoginPage",
    LABEL_USER_CREATED: "UserCreated",
    LABEL_USER_CREATE_FAILED: "UserCreateFailed",
    WEB_USER_CREATED: "WebUserhasbeenCreated",
    SUPPORT_PAGE_TITLE: "SupportTitle",
    CONFIRM_DECLINING_FORM: "ConfirmDecliningForm",
    BATCH_JOBS_TITLE: "BatchJobsTitle",
    BATCH_JOB_MAS_EXPORT_DESCRIPTION: "BatchJobMasExportDescription",
    CREATE_BATCH_BTN: "CreateBatchBtn",
    CANCEL_BTN: "CancelBtn",
    BATCH_CREATED: "BatchCreated",
    BATCH_CREATED_SUCCESS: "BatchCreatedSuccess",
    BATCH_CREATED_FAIL_WITH_NO_RECORDS: "BatchCreateFailWithNoRecords",
    BATCH_CREATION_FAILURE_MESSAGE: "BatchCreationFailureMessage",
    BATCH_JOBS: "BatchJobs",
    ONE_BATCH_JOB_REQUIRED: "OneBatchJobRequired",
    BATCHES_TITLE: "Batches",
    VIEW_BATCHES_TITLE: "ViewBatches",
    MY_PROFILE: "MyProfile",
    USERNAME: "Username",
    PASSWORD: "Password",
    CONFIRM_PASSWORD: "Confirm Password",
    EMAIL: "email",
    PROFILE_DETAILS_UPDATED_SUCCESS: "ProfileDetailsUpdatedSuccess",
    UPDATE_PROFILE_DETAILS_FAIL: "UpdateProfileDetailsFail",
    PROFILE_PAGE_TITLE: "ChangeFieldsBelowToUpdateYourInformation",
    CHANGE_I9_VERIFIER_FORM_HEADING: "PleaseProvideyouri9verifiersdetails",
    LABEL_EMAIL_ADDRESS: "LabelEmailAddress",
    INTERNAL_API_SERVER_ERROR_500: "InternalServerError",
    LABEL_BAD_REQUEST: "BadRequest",
    LABEL_HR_DOCUMENTS: "HrDocuments",
    SIGN_UP_SUCCESSFULL: "SignUpSuccessfull",
    SUCCESSFULLY_SIGN_UP: "SuccessfullySignUp",
    I9_FEDRAL_FORM: "I9FedralForm",
    CLICK_TO_EXPAND_TITLE: "clickToExpandTitle",
    EMPLOYEE_INFORMATION_TITLE: "employeeInformation",
    ATTESTATION_TITLE: "attestationTitle",
    SING_AND_SAVE_FORM: "singAndSave",
    LABEL_MIDDLE_INITIAL: "middleInital",
    LABEL_OTHER_NAME: "otherNameUsed",
    LABEL_SSN: "ssn",
    LABEL_DOB: "dob",
    LABEL_STREET_ADDRESS: "streetAddress",
    LABEL_APARTMENT_NUMBER: "apartmentNumber",
    LABEL_CITY: "city",
    LABEL_ZIP: "zipcode",
    STEP: "step",
    PROVIDE_I9_VERIFIER_DETAIL: "provideI9VerifierDetail",
    HELP: "help",
    ATTENTION: "attention",
    VERIFY_FIELDS: "verifyFields",
    EMPLOYEE_INFORMATION_ATTENTION_TEXT: "employeeInformationAttentionText",
    LABEL_SELECT_AN_IMAGE_TO_UPLOAD: "selectAnImageToUpload",
    LABEL_DOCUMENT_LIST_TYPE: "documentListType",
    LABEL_DOCUMENT_TITLE: "documentTitle",
    LABEL_ASSISTANCE_CHECKBOX: "assistanceCheckbox",
    LABEL_FORM_I9_ADMISSION_NUMBER: "formI9AdmissionNumber",
    LABEL_ALIEN_REGISTERATION_NUMBER: "alienRegistrationNumber",
    LABEL_ALIEN_AUTHORIZED_DATE: "alienAuthorizedDate",
    LABEL_ATTESTATION_RADIO: "attestationRadioLabel",
    MINOR_TRUST_ACCOUNT: "MinorTrustAccount",
    BANK_ZIP_CODE: "bankzipcode",
    BANK_FINANCIAL_INS: "Bank/FinancialInstitution",
    BANK_STREET_ADD: "BankStreetAddress",
    BANK_CITY: "BankCity",
    BANK_STATE: "BankState",
    MINOR_CHECK_MSG: "minorcheckmsg",
    TRUST_ACK_MSG: "trustackmsg",
    NAME_ACCOUNT: "NameofAccount",
    NAME_TRUSTEE: "NameofTrustee",
    TYPE_TRUST_ACC: "TypeOfTrustAccount",
    MINOR_FORM_SIGN_MSG: "minorformsignmsg",
    UPLOAD_YOUR_DOCUMET_MINOR: "minordocuploadtxt",
    LABEL_BAN_PHONE_NUMBER: "BankphoneNumber",
    COOGAN: "Coogan/BlockedTrustAccount(California)",
    UGMA: "UGMAAccount(NY Only)",
    UTMA: "UTMAAccount(NY Only)",
    ENTER_VALIDATION_MESSAGE: "entervalidationmessage",
    LABEL_USER_NAME_EXISTS: "PleasetryanewUsername",
    EMPLOYEE_INFORMATION_FORM_ATTENTION_TEXT: "employeeInformationFormAttentionText",
    RECREATE_BATCH_EXPORT_FILE: "RecreateBtach",
    RESEND_THINKWARE_FILES: "ResendThinkware",
    THINKWARE_EMPLOYEES: "ThinkwareEmployees",
    THINKWARE_LOCAL_TAX: "ThinkwareLocalTax",
    THINKWARE_DIRECT_DEPOSIT: "ThinkwareDirectDeposit",
    DOWNLOAD_MAS_EXPORT_FILE: "MasExportFiles",
    DOWNLOAD_SLAM_EXPORTING: "SlamExportFiles",
    BATCH_DETAILS_ONE_LINER: "OneLinerBatchDetails",
    DOWNLOAD_DOCUMENTS_TITLE: "AllCompletedForms",
    DOWNLOAD: "Download",
    SELECT_ALL_DOCUMENTS: "SelectAllDocuments",
    DOCUMENTS_NOT_AVAILABLE: "DocumentsAreNotAvailable",
    DOWNLOAD_DOCUMENTS: "DownloadDocuments",
    BATCH_DETAILS_TITLE: "BatchDetailsTitle",
    BATCH_NAME_TITLE: "BatchNameTitle",
    INCOMPLETE_ONBOARDING_PAPERWORK_HEADING: "IncompleteOnboardingPaperwork",
    INCOMPLETE_EMPLOYEE_AGREEMENT_HEADING: "IncompleteEmployeeAgreement",
    ADD_NEW_TIMESHEET: "addNewTimesheet",
    CHANGE_HOME_ADDRESS: "changeHomeAddress",
    UPDATE_DIRECT_DEPOSITE_OR_PAYCARD: "updateDirectDepositeOrPaycard",
    UPDATE_FEDRAL_OR_STATE_W4: "updateFedralOrStateW4",
    TIMESHEETS: "timeSheets",
    TIMESHEET: "timeSheet",
    LABEL_WEEK_END_DATE: "weekEndDate",
    CREATE: "create",
    SIGN_AND_SUBMIT: "singAndSubmit",
    ADD_TIME: "addTime",
    HIDE_PAY_TEXT: "hidePayText",
    SHOW_PAY_TEXT: "showPayText",
    RECALL: "recall",
    ARE_YOU_SURE_RECALL_TEXT: "AreYouSureRecallText",
    ADD: "add",
    DUPLICATE: "duplicate",
    LNG_MSG: "LanguageMsg",
    My_PRI_LNG: "MyPrimaryLanguageis",
    PRI_LANG_MSG: "PrimaryLanguageMsg",
    CAL_MINOR_WORK_PERMIT: "CaliforniaMinorWorkPermit",
    UPLOAD_DOC_CAL_WORK_PEMIT: "UploadDoccaliforniaMinorWorkPermit",
    REQ_CALIFORNIA_MINOR_WORK_DOC: "reqUploadCaliforniaMinorWorkPermit",
    UPLOAD_FILE: "UploadFile",
    FINANCIAL_INFO_FORM: "FinancialInformation",
    MINOR_FORM_SIGNATURE: "Minorformsigntext",
    EMPLOYEE_INFORMATION_HEADING: "EmployeeInformationHeading",
    EMPLOYEE_INFORMATION_VERIFICATION: "EmployeeInfoVerification",
    MAILING_ADDRESS_SAME_TEXT: "MailingAddressSameText",
    MAILING_STREET_ADDRESS: "MailingStreetAddress",
    MAILING_APARTMENT: "MailingApartment",
    MAILING_CITY: "MailingCity",
    MAILING_STATE: "MailingState",
    MAILING_ZIP: "MailingZip",
    WORK_VISA_PERMIT_TEXT: "WorkVisaPermitText",
    VISA_TYPES_TEXT: "VisaTypesText",
    EMERGENCY_CONTACT_NAME: "EmergencyConatctName",
    EMERGENCY_CONTACT_PHONE: "EmergencyPhone",
    EMPLOYEE_PHYSICIAN: "EmployeePhysician",
    EMPLOYEE_PHYSICIAN_PHONE: "EmployeePhysicianPhone",
    EMPLOYEE_HEALTH_INSURANCE: "EmployeeHealthInsurance",
    EMPLOYEE_POLICY_NUMBER: "EmployeePolicyNumber",
    EMPLOYEE_MED_ALERGIES_CONDITION: "EmpMedicalAlgCondition",
    CONTINUE_BTN: "ContinueBtn",
    VERIFY_YOUR_FORM_TEXT: "VerifyYourForm",
    SIGN_SAVE_BTN: "SignSaveBtn",
    EMERGENCY_INFORMATION: "EmergencyInformation",
    LABEL_PHONE_NUMBER_2: "LabelPhoneNumber2",
    FORM_SAVED_SIGNED_SUCCESS_MESSAGE: "FormSavedSuccessMessge",
    VERIFY_FORM_INFO_DESC: "VerifyFormDescription",
    PREPARER: "preparer",
    UPDATE_PERSONAL_INFO: "UpdatePersonalInfo",
    UPDATE_USER_NAME: "UpdateUserName",
    UPDATE_PASSWORD: "UpdatePassword",
    HOME_ADDRESS_CHANGE: "HomeAddressChange",
    PAID_SICK_LEAVE: "PaidSickLeave",
    ADDRESS1: "Address1",
    ADDRESS2: "Address2",
    USER_NAME_PASSWORD_RULES: "UserNameMustfollowTheFollowingRules",
    CHARACTERS_LENGTH: "8-50CharactersInLength",
    NO_SPECS: "NoSpaces",
    INCLUDE_ONE_ALPHA: "IncludeAtLeastOneAlphaCharacter",
    INCLUDE_ONE_NUMERIC: "IncludeAtLeastOneNumericCharacter",
    PASSWORD_RULES: "PasswordMustfollowTheFollowingRules",
    NOTIFY_ADDRESS_CHANGE: "NotifyIfYourAddressIsChanging",
    EFFECTVE_DATE: "EffectiveDate",
    PERSONAL_INFORMATION: "PersonalInformation",
    ADDRESS_CHANGE: "AddressChange",
    MORE_INFORMATION: "moreInformation",
    MINOR_TRUST_SIGN_ATTN_MSG: "MinorTrustSignaturePadmsg",
    COMP_VAL_TYPE: "CompValueType",
    COMP_VALUE: "CompareValue",
    APPLICABLE_ATTRIBUTES: "ApplicableAttributes",
    PROPERTY_VALUE: "PropertyValue",
    PROPERTY_NAME: "PropName",
    NEW_YORK_MINOR_WORK_PERMIT: "NewYorkMinorWorkPermit",
    REQ_NEW_YORK_MINOR_WORK_DOC: "reqUploadMinorWorkPermit",
    EMP_INFO_LEGAL_ATTENTION: "EmpInfoLegalAttention",
    DECLINED_SUCCESS: "DeclineSuccess",
    DECLINE_FAILED: "DeclineFailed",
    FINANCIAL_INFORMATION: "FinancialInformation",
    DIRECT_DEPOSIT_FORM_ERR: "DirectDepositFormErr",
    IMG_UPLOAD_SUCCESS: "Imageuploadsuccess",
    IMG_UPLOAD_SUCCESS_HEADING: "ImageuploadsuccessHeading",
    DIRECT_DEPOSIT_UPLOAD_TEXT: "DirectDepositUploadTxt",
    SUCCESSFULLY_CHANGED: "SuccessfullyChanged",
    I9_VERIFIER_SUCCESS_MESSAGE: "I9VerifierSuccessMessage",
    CHANGE_I9_VERIFIER_HEADING: "ChangeI9VerfierHeading",
    REVIEW_AND_SIGN: "Reviewandsign",
    CLICK_ONE: "Clickone",
    NEW_YORK_EXEMPT_EMP_NOTICE: "NewYorkExemptEmployeeNotice",
    GEORGIA_W4: "GeorgiaW4",
    VIEW_FORM_WITH_INS: "ViewFormWithInstructions",
    STATE_OF_RESIDENCE: "YourStateOfResidence",
    SPOUSE_STATE_OF_RESIDENCE: "YourSpousStateOfResidence",
    SINGLE: "Single",
    MARRIED_JOINT_WOKRING: "marriedJointWorking",
    MARRIED_ONE_WORKING: "MarriedOneWorking",
    MARRIED_SEPARATION: "marriedSeparation",
    HEAD_OF_HOUSEHOLD: "headOfHouse",
    MARITAL_STATUS_LABEL: "maritalStatusLabel",
    DEPENDENCE_ALLOW: "dependentAllowances",
    ADDITIONL_ALLOWANCES: "AdditionalAllowances",
    ADDITIONL_ALLOWANCES_INP: "AdditionalAllowancesinp",
    ADDITIONAL_WITHHOLDINGS: "AdditionalWithHoldings",
    WOKSHEET_TEXT: "WorkSheetTxt",
    STANDARD_DEDUCTION_TEXT: "standardDeductiontxt",
    AGE_65_OR_OVER: "Age65orOver",
    BLIND: "Blind",
    NUM: "*1300",
    NUMBER_OF_CHECKBOXES_CHKD: "NumberOfCheckBoxesChecked",
    ADDITIONAL_ALLOWANCES_FOR_DEDUCTION: "AdditionalAllowancesForDeductions",
    FEDERAL_ITEMIZING_TXT: "FederalItemizing",
    GEORGIA_STANDARD_TXT: "georgiaStandard",
    SUBTRACT_LINE_B_FROM_A: "subtractTxt",
    ALLOWABLE_DEDUCTIONS: "Allowablededuction",
    ADD_THE_AMOUNT: "Addtheamount",
    ESTIMATE_OF_TAXABLE: "estimateOfTaxable",
    SUBTRACT_LINE_F_FROM_E: "subtractTxt2",
    DIVIDE_AMOUNT: "divideAmountTxt",
    LETTER_USED: "LetterUsed",
    TOTAL_ALLOWANCE: "TotalAllowance",
    EXEMPT: "Exempt",
    EXEMPT_OPTION_1: "exemptOption1",
    EXEMPT_OPTION_2: "exemptOption2",
    EMPLOYEE_DASHBOARD_HEADING: "EmployeeDashboardHeading",
    CHANGE_FAILED: "ChangeFailed",
    I9_VERIFIER_FAILED_MESSAGE: "I9VerifierFailedMessage",
    BULK_IMPORT_JOBS_TITLE: "BulkImportJobs",
    BULK_IMPORT_UPLOAD_FILE_FAILED: "UnabletoValidateFile",
    BULK_IMPORT_UPLOAD_FILE_FAILED_MESSAGE: "Therewasaproblemwiththefilevalidation",
    BULK_IMPORT_UPLOAD_FILE_SUCCESS: "FileSuccessfullyUploaded",
    BULK_IMPORT_UPLOAD_FILE_SUCCESS_MESSAGE: "Filehasbeenuploaded.",
    EMPLOYEE_REPRESENTATIVE_REVIEW_TITLE: "EmployeeRepresentativeReview",
    ISSUEING_AUTHORITY_LABEL: "IssueingAuthority",
    DOCUMENT_NUMBER: "DocumentNumber",
    LABEL_DOCUMENT: "Document",
    LABEL_FORM_DATA: "FormData",
    THINKWARE_EMPLOYEES_TITLE: "ThinkwareEmployees",
    FILL_FORM_FIELDS: "fillformfields",
    EXPENSE_REPORTS: "expenseReports",
    EXPENSE_REPORT: "expenseReport",
    ADD_EXPENSE_REPORT: "addExpenseReport",
    ADD_EXPENSE: "addExpense",
    ATTACH_RECEIPT: "attachReceipt",
    EXPENSE_REPORT_RECEIPT: "expenseReportReceipt",
    ADD_FILE: "addFile",
    TAX_STATEMENTS: "TaxStatements",
    TAX_YEAR: "taxYear",
    DOWNLOAD_STATEMENT: "downloadStatement",
    NEW_YORK_HOURLY_EMPLOYEE_NOTICE_TITLE: "NewYorkHourlyEmployeeNotice",
    REVIEW_FORM_AND_SIGN: "ReviewFormandsignbelow",
    CHECK_ONE: "CheckOne",
    NEW_YORK_HOURLY_EMPLOYEE_NOTICE_AGR: "NewYorkHourlyEmployeeNoticeAgreement",
    NEW_YORK_HOURLY_EMPLOYEE_NOTICE_LANGUAGE: "NewYorkHourlyEmployeeNoticeLanguage",
    BULK_IMPORT_FORM_HEADING: "BulkImportFormHeading",
    SELECT_DATE_TYPE_PAYSTUB: "pleaseSelectADateTypeToDownloadPaystub",
    PAYDATE: "paydate",
    WEEK_ENDING_DATE: "weekEndingDate",
    PAY_STUBS: "payStubs",
    DOWNLOAD_PAY_STATEMENT: "downloadPayStatement",
    EXPORT_FILES_SUCCESS: "ExportFilesSuccess",
    EXPORT_FILES_SUCCESS_MESSAGE: "ExportFilesSuccessMessage",
    EXPORT_FILE_RECREATED: "ExportFileRecreated",
    EXPORT_FILE_RECREATED_MESSAGE: "ExportRecreatedMessage",
    EXPORT_FILE_RESEND_FAIL_MESSAGE: "ExportResendFailMsg",
    CREDIT_CARD_TRANSACTION_TITLE: "CreditCardsTransaction",
    CREDIT_CARD_TRANSACTION_RECEIPTS_TITLE: "CreditCardsReceiptsTransaction",
    SAME_STATE_TXT: "sameStateTxt",
    SELECT_ITEM_TO_UPDATE: "SelectItem",
    DIRECT_DEPOSIT: "DirectDeposit",
    PAYCARD: "PayCard",
    W4_CHANGE_TITLE_HEADING: "W4Change",
    FEDERAL_DIALOG: "Federal",
    STATE_DIALOG: "State",
    FEDERAL_OR_STATE_DIALOG_BODY: "FederalOrState",
    VIEW_STATUS_HISTORY: "ViewStatusHistory",
    VIEW_PDF: "ViewPDF",
    PRINT_TIMESHEET: "PrintTimesheet",
    TIMESHEET_STATUS_HISTORY_TITLE: "timesheetStatusHistory",
    LABEL_COMMENT: "comment",
    SIGN_AND_SUBMIT_TIMESHEET_HEADING: "signAndSubmitTimesheet",
    SIGN_AND_SUBMIT_EXPENSE_REPORT_HEADING: "signAndSubmitExpenseReport",
    LABEL_APPROVE_BTN: "Approve",
    LABEL_NOT_APPROVE_BTN: "NotApprove",
    SUCCESSFULLY_DECLINED: "SuccessfullyDeclined",
    VISA_DECLINED_SUCCESSFULLY: "VisaDeclinedSuccessfully",
    RETURN_TO_TASKS: "ReturntoTasks",
    SUCCESSFULLY_APPROVED: "SuccessfullyApproved",
    VISA_APPROVED_SUCCESSFULLY: "VisaApprovedSuccessfully",
    VISA_APPROVAL_FAILED: "VisaApprovalFailed",
    APPROVAL_FAILED: "ApprovalFailed",
    ERROR_IN_DECLINE: "ErrorinDecline",
    DECLINE_ERROR_MESSAGE: "errorindeclinemessage",
    UI_CAL: "UICalculation",
    TARGET_OUTPUT: "TargetOutput",
    UI_CALCULATION_SAVED_SUCCESS_MSG: "UICalculationSaveSucessMsg",
    UI_CALCULATION_DELETE_SUCCESS_MSG: "UICalculationDeleteSucessMsg",
    DAY_WORKED: "dayWorked",
    PROJECT_CODE: "projectCode",
    WORK_LOCATION: "workLocation",
    LABEL_POSITION: "position",
    ADD_SICK_LEAVE: "sickLeaves",
    AVAILABLE_HOURS: "availableHours",
    HOURS: "Hours",
    TIME_IN: "timeIn",
    TIME_OUT: "timeOut",
    LABEL_MEALS: "meals",
    EXPENSE_DATE: "expenseDate",
    EXPENSE_CODE: "expenseCode",
    LABEL_AMOUNT: "amount",
    LABEL_TITLE: "Title",
    RETURN_TO_TASK: "ReturnToTask",
    VENDOR_PAYMENTS_TITLE: "vendorPayment",
    ADD_VENDOR_PAYMENT: "addVendorPayment",
    LABEL_INVOICE_DATE: "invoiceDate",
    LABEL_INVOICE_NUMBER: "invoiceNumber",
    RESEND_EMPLOYEE_AGREEMENT_EMAIL: "ResendEmployeeAgreementEmail",
    RESEND_EMPLOYEE_ONBOARDING_LINK_EMAIL: "ResendEmployeeOnboardingLink",
    ADD_PAYMENT: "addPayment",
    ADD_BACKUP: "addBackup",
    PROJECT_PURCHASE_ORDER: "projectPurchaseOrder",
    PROJECT_BEG_DATE: "projectBeg",
    PROJECT_END_DATE: "projectEnd",
    VENDOR_PAYMENT_BACKUP: "vendorPaymentBackup",
    ADD_BACKUP_PAYMENT_FILE: "addBackupFile",
    NEW_YORK_DAY_RATE_EMP_NOTICE: "NewYorkDayRateEmployeeNotice",
    CAL_YORK_NOTICE_TO_EMP: "CAliforniaNoticeToEmployee",
    VIEW_NOTICE_TO_EMP: "ViewNoticeToEmployee",
    NOTIFY_PERSONAL_INFO_CHANGE: "NotifyPersonalInfoChanging",
    BACKUP: "backup",
    VENDOR_DOCUMENT_TITLE: "vendorDocTitle",
    VENDOR_DOCUMENT_HEADING: "vendorDocHeading",
    WORKERS_COMPENSATION: "workersCompensation",
    GENERAL_LIABILITY: "generalLiability",
    W9_DOCUMENT: "w9Document",
    CONTRACT: "contract",
    PAY_STUBS_DOWNLOAD_FAILURE: "payStubsDownloadFailure",
    END_NONEXEMPT_DAILY_EMP_AGREEMENT: "endeavorNonExemptDailyEmployeeAgreement",
    Endeavor_OnLocation_PayReel_PerDiem_Agreement_Title: "endeavoronLocationpayreelperdiemagreement",
    VIEW_WITH_INSTRUCTIONS: "viewFormWithInstructions",
    CONTENTS_TV_RELEASE: "contentsTvRelease",
    DOWNLOAD_FAILURE: "downloadFailure",
    ARE_YOU_SURE_DELETE_ITEM: "SureDeleteItem",
    SEXUAL_HARASSMENT_CERTIFICATE: "sexualHarassmentCertificate",
    UPLOAD_SEXUAL_HARASSMENT_CERTIFICATE: "uploadSexualHarassmentCertificate",
    VOID_CERTIFICATE_CHECK_MSG: "voidcertificatecheckmsg",
    CERIFICATE_UPLOAD_TEXT: "certificateUploadTxt",
    TIMESHEET_DELETED_SUCEESS: "TimesheetDeletedSuccess",
    TIMESHEET_DELETED_FAILURE: "TimesheetDeletedFailure",
    LABEL_REQUIRED_FIELDS: "PleaseSign",
    ENDEAVOR_NON_EXCEMPT_EMPLOYEE_AGREEMENT_TITLE: "EndeavorHourlyNonExemptEmployeeAgreement",
    DATE: "Date",
    RADIO_ALL_STATUS: "allStatus",
    RADIO_ACTIVE_STATUS: "active",
    RADIO_INACTIVE_STATUS: "inactive",
    LABEL_SSN_EID: "ssn/eid",
    LABEL_COMPANY_NAME: "companyName",
    LABEL_VENDOR_ID: "vendorId",
    LABEL_STATUS: "status",
    LABEL_VENDORS_HEADING: "vendors",
    NEW_YORK_EMPLOYEE_NOTICE_SALARY_TITLE: "NewYorkEmployeeNoticeSalary",
    LABEL_COST_CENTER: "costCenter",
    LABEL_SEARCH_VENDOR_PAYMENTS_HEADING: "searchVendorPayments",
    CERT_UPLOAD_SUCCESS_HEADING: "certuploadsucessheading",
    CERT_UPLOAD_SUCCESS: "certUploadSuccessTxt",
    EXPENSE_REPORT_DELETED_SUCCESS: "ExpenseReportDeletedSucess",
    EXPENSE_REPORT_DELETED_FAILED: "ExpenseReportDeletedFailed",
    VERIFY_FIRST_BG_CHECK: "verifyFirstBackgroundCheck",
    FILL_FORM_FIELDS_TITLE: "fillFormFields",
    LABEL_REQUEST_COPY_CHECKBOX: "requestacopy",
    LABEL_SUMMARY_RIGHTS_CHECKBOX: "summaryofrights",
    ADD_INITIALS: "pleaseaddyourinitials",
    ARTICLE_23A_NY_CORRECTION_LAW: "article23ANYCorrectionLaw",
    PREVIOUS: "previous",
    DRIVERS_LICENSE_NUMBER: "driversLicenseNumber",
    STATE_ISSUING_DRIVERS_LICENSE: "stateIssuingDriversLicense",
    LABEL_ALIAS_NAME_USED: "aliasNameUsed",
    MINNESOTA_WAGE_THEFT: "minnesotaWageTheft",
    END_EXEMPT_EMP_AGREEMENT: "endeavorExemptEmployeeAgreement",
    TAX_STATEMENT_NOT_AVAILABLE: "taxStatmentNotAvailable",
    PAY_STUBS_NOT_AVAILABLE: "payStubsNotAvailable",
    FAILED: "failed",
    END_ONLOC_PAYREEL_EXEMPT: "endOnLocPayReelExemptTemp",
    CLIENT_POSITIONS: "ClientPositions",
    LABEL_NAME: "Name",
    LABEL_CLOSED: "Closed",
    COST_CENTERS_TITLE: "CostCenters",
    ADD_COST_CENTER_BTN: "AddCostCenter",
    LABEL_ALL: "All",
    SEARCH_MANUAL_PAYMENT_TITLE: "searchManualPayments",
    LABEL_PAYMENT_TYPE: "permanentType",
    HOME_STATE_CHANGE_POPUP_HEADING: "HomeStateChangeHeading",
    HOME_STATE_CHANGE_POPUP_TEXT: "HomeStateChangeText",
    MY_VENDOR_PAYMENTS: "myVendor",
    ALL_VENDOR_PAYMENTS: "allVendor",
    LABEL_APPROVE_VENDOR_PAYMENT_BUTTON: "approveVendorButton",
    LABEL_APPROVE_VENDOR_PAYMENTS_TITLE: "approveVendorsTitle",
    LABEL_VENDOR_PAYMENTS: "vendorPayments",
    APPROVE_VENDOR_HEADING: "selectOptions",
    VOID_INVOICE: "VoidInvoice",
    LABEL_CLIENT_NAME: "ClientName",
    LABEL_AR_EXPORT: "ARExport",
    INVOICE_NUMBER: "InvoiceNumber",
    TIMESHEET_RECALLED_SUCEESS: "TimesheetRecalledSuccess",
    TIMESHEET_RECALLED_FAILURE: "TimesheetRecalledFailure",
    SUCCESS: "success",
    PDF_REPORT_NOT_AVAILABLE: "Pdf_ReportNotAvailable",
    LABEL_EMPLOYEE: "Employees",
    LABEL_EIN: "EmployeeIdentificationNumber",
    VENDOR_PAYMENT_HISTORY_TITLE: "vendorPaymentHistoryTitle",
    ADD_USER_BTN: "addUser",
    CLIENT_USERS_TITLE: "clientUsers",
    LABEL_SEARCH_TIME_SHEETS: "SearchTimesheets",
    TIME_ADDED_SUCCESSFULLY: "TimeAddedSuccesfully",
    LABEL_VENUE: "LabelVenue",
    LABEL_ADDRESS_1: "LabelAddress1",
    LABEL_ADDRESS_2: "LabelAddress2",
    LABEL_SEARCH_EXPENSE_REPORT_HEADING: "searchExpenseReport",
    LABEL_COST_CENTER_TESTING: "costCenterTesting",
    EXPENSE_REPORT_TITLE: "expenseReport",
    EXPENSE_REPORT_HISTORY_TITLE: "expenseReportStatusHistory",
    EXPENSE_REPORT_BACKUP: "expenseReportReceipt",
    ADDRESS_UPDATED_SUCCESSFULLY: "addressUpdatedSuccessfully",
    EDIT_HR_DOCUMENT: "EditHRDocument",
    STEPS_TO_EDIT_HR_DOCUMENT: "stepsToEditHrDocument",
    EXPENSE_RECALLED_SUCEESS: "ExpenseRecalledSuccess",
    EXPENSE_RECALLED_FAILURE: "ExpenseRecalledFailure",
    MASTER_CLIENTS_TITLE: "MasterClients",
    ADD_MASTER_CLNT_BTN: "AddMasterClient",
    EDIT_MASTER_CLNT_BTN: "EditMasterClient",
    LABEL_MASTER_CLIENT: "Master Client",
    LABEL_DARWINN_CLIENT_ID: "DarwinClientId",
    EXPENSE_BACKUP_REPORT: "expenseBackupReport",
    LABEL_REQUISITION_NUMBER: "LabelRequisitionNumber",
    LABEL_ADDITIONAL_HOURS: "LabelAdditionalHours",
    LABEL_GUARANTEE_HOURS: "LabalGuranteeHours",
    LABEL_PAY_CODE: "LabelPayCode",
    LABEL_PAY_CODE_HOURS: "LabelPayCodeHours",
    LABEL_UDF1_ALIAS: "LabelUDF1Alias",
    LABEL_UDF2_ALIAS: "LabelUDF2Alias",
    LABEL_UDF3_ALIAS: "LabelUDF3Alias",
    LABEL_SUPERVISOR_ADD_TIME: "LabelSupervisorAddTime",
    TIMESHEET_SUBMIT_FALIURE: "timesheetSubmitFaliure",
    LABEL_APPROVE_EXPENSE_REPORT_TITLE: "approveExpenseReportTitle",
    MY_EXPENSE_REPORT: "myExpenseReport",
    ALL_EXPENSE_REPORT: "allExpenseReport",
    LABEL_EXPENSE_REPORT: "expenseReports",
    LABEL_APPROVE_EXPENSE_REPORT_BUTTON: "approveExpenseReport",
    CAN_NOT_ASSIGN_YOURSELF_I9_VERIFIER: "cannotAssignYourselfI9Verifier",
    ACCESS_CLIENT: "accessClient",
    CLIENTS_TITLE: "clients",
    ADD_CLIENT_BTN: "addClient",
    SLAM_LOCATIONS: "SlamLocations",
    EDIT_SLAM_LOCATION: "EditSlamLocation",
    ADD_SLAM_LOCATION: "addslamlocation",
    TIME_UPDATED_SUCCESSFULLY: "TimeUpdatedSuccessFully",
    ACK_AND_SAVE: "AcknowledgeAndSave ",
    ACK_AND_SAVE_MSG: "AckAndSaveMsg ",
    RECEIPT_UPLOADED_SUCCESSFULLY: "receiptUploadedSuccessfully",
    SLAM_LOCATION_SAVE_SUCCESS: "Slamlocationsavesuccess",
    SLAM_LOCATION_SAVE_FAILED: "Slamlocationsavefail",
    RECEIPT_SUBMITTED_SUCCESSFULLY: "receiptSubmittedSuccessfully",
    RECEIPT_DELETED_SUCCESSFULLY: "receiptDeletedSuccessfully",
    ONE_RECEIPT_REQUIRED: "oneReceiptRequired",
    MISSISSIPPI_EMP_WITH_HOLDING: "mississippiEmpWithHolding",
    LABEL_MARITAL_STATUS: "maritalStatus",
    LABEL_EXEMPTION_SINGLE: "singleEnterExemption",
    LABEL_UNEMPLOYED_SPOUSE: "unemployedSpouse",
    LABEL_EMPLOYED_SPOUSE: "employedSpouse",
    LABEL_HEAD_OF_FAMILY: "headOfFamily",
    LABEL_NUMBER_OF_DEPENDENTS: "numberOfDependents",
    LABEL_CLAIMED_DEPENDENTS_AMOUNT: "claimedDependentsAmount",
    LABEL_AGE_BLINDNESS_EXEMPTION: "ageBlindnessExemption",
    LABEL_ADDITIONAL_DOLLAR_AMOUNT: "additionalDollarAmount",
    LABEL_EXEMPTION_CLAIM: "exemptionClaim",
    HSB_AGE_65_OR_OVER: "husbandAge65OrOlder",
    WIFE_AGE_65_OR_OVER: "wifeAge65OrOlder",
    SINGLE_AGE_65_OR_OVER: "singleAge65OrOlder",
    HSB_IS_BLIND: "husbandIsBlind",
    WIFE_IS_BLIND: "wifeIsBlind",
    SINGLE_AND_BLIND: "singleAndBlind",
    LABEL_FEDERAL_FORM_DD: "federalFormDD",
    LABEL_MILITARY_SPOUSE_ID_CARD: "militarySpouseIdCard",
    OPTION_SINGLE: "Single",
    OPTION_MARRIED_UNEMPLOYED: "marriedSpouseNOTemployed",
    OPTION_MARRIED_EMPLOYED: "marriedSpouseISemployed",
    OPTION_HOD: "headOfFamily",
    NOT_APPLICABLE: "notApplicable",
    NOT_APPLICABLE_VALUE: "na",
    LABEL_EMPLOYEE_FORMS: "employeeforms",
    ADD_TIMECARD_ALERT_HEADING: "AddTimecardAlertHeading",
    FINAL_SUBMISSION_CONFIRMATION: "finalSubmissionConfirmation",
    ERROR_OCCURED: "errorOccured",
    MASTER_CLIENT_USERS_TITLE: "MasterClientsUsers",
    LABEL_WEB_SETTINGS: "WebSettings",
    LABEL_USER_INFO: "UserInfo",
    LABEL_EDIT_USER: "EditUser",
    LABEL_CODE: "code",
    LABEL_NCCI: "ncci",
    LABEL_STATE_NOT_NCCI: "stateNotNCCI",
    ADD_WORKERS_COMP_CODES_TITLE: "workersCompCodes",
    EDIT_WORKERS_COMP_CODES_TITLE: "editWorkersCompCodes",
    EDIT_WORKERS_COMP_HEADING: "editWorkersHeading",
    HIGH_LOW_PER_DIEM_LOC_TITLE: "highlowperdiemloc",
    EDIT_HIGH_LOW_PER_DIEM_LOC_TITLE: "edithighlowperdiemloc",
    HIGH_LOW_PER_DIEM_FORM_HEADING: "Selectlocationsanddatesforwhenper-diemishigh",
    LABEL_COUNTRY: "Country",
    LABEL_LOW_PER_DIEM: "LowperDiem",
    LABEL_HIGH_PER_DIEM: "HighperDiem",
    HIGH_LOW_PER_DIEM_TITLE: "HighlowperDiem",
    HEALTH_INSURANCE_RATE_TITLE: "acahealthinsurancerate",
    EDIT_HEALTH_INSURANCE_RATE_TITLE: "editacahealthinsurancerate",
    HEALTH_INSURANCE_AMOUNT: "healthinsuranceamount",
    LABEL_START_CITY: "LabelStartCity",
    LABEL_END_CITY: "LabelEndCity",
    LABEL_TOTAL_MILES: "LabelTotalMiles",
    FORM_VALIDATION_FAILED: "formValidationFailed",
    EXPENSE_REPORT_FAILIURE: "expensereportfailiure",
    VENDOR_SUBMIT_FAILIURE: "vendorsubmifailiure",
    VENDOR_DELETED_SUCEESS: "vendorDeleteSuccess",
    VENDOR_DELETED_FAILIURE: "vendorDeleteFaliure",
    EMPLOYEE_ADDRESS_CHANGE_HEADING: "employeeAddressChange",
    ARE_YOU_SURE_ADDRESS_CHANGE: "changeTheAddress",
    SAVE_EXPENSE: "SaveExpense",
    SIGN_AND_SUBMIT_SUCCESSFULLY: "signAndSubmitSuccessfully",
    SELECT_ACCOUNT: "account",
    SELECT_EMPLOYEE: "employee",
    SELECT_ACCOUNT_AND_EMPLOYEE: "accountAndEmployee",
    SELECT_HIGH_LOW: "highLow",
    SELECT_GSA: "gsa",
    SELECT_NONE: "none",
    SELECT_SUB_ACCOUNT: "subAccount",
    SELECT_WEEKLY: "weekly",
    SELECT_BIWEEKLY: "biWeekly",
    SELECT_CAX: "cax",
    SELECT_CA_MPI: "caMPI",
    SELECT_CLIENT: "client",
    SELECT_PASS_THROUGH: "passThrough",
    SELECT_FLAT_RATE: "flatRate",
    SELECT_SIMPLE_ADMIN: "simpleAdminRate",
    SELECT_BEST_BUY: "bestBuy",
    SELECT_FEDERAL_STATE: "federalState",
    SELECT_I9_VERIFIER: "i9Verifier",
    ADD_CLIENT_HEADING: "addClientHeading",
    EDIT_CLIENT_HEADING: "editClientHeading",
    LABEL_MAS_ID: "masID",
    LABEL_MEAL_PENALTIES: "mealPenalties",
    LABEL_THINKWARE_ID: "thinkwareID",
    LABEL_THINKWARE_DIVISION_ID: "thinkwareDivisionID",
    LABEL_CLIENT_IDENTIFIER: "clientIdentifier",
    LABEL_MEAL_PENALTY_TYPE: "mealPenaltyType",
    LABEL_TS_APPROVAL_METHOD: "tsApprovalMethod",
    LABEL_NON_WORKED_HOURS: "nonWorkedHours",
    LABEL_ALLOCATION_METHOD: "allocationMethod",
    LABEL_SHOW_RATE_WITH_POSITION: "showRateWithPosition",
    LABEL_ALLOW_VENDOR_PAYMENTS: "allowVendorPayments",
    LABEL_GL_CODE_FOR_POSAITION: "glCodeForPosition",
    LABEL_DISPLAY_VENDOR_PAYMENT_DATE: "displayVendorPayment",
    LABEL_PAYROLL_BILLING_TYPE: "payrollBillingType",
    LABEL_1099_WORKER_TO_DARWIN: "1099WorkerToDarwin",
    LABEL_APPROVE_DETAILS: "approveDetails",
    LABEL_TAXABLE_BLIND_WAGES_CAPPED: "taxableBaseWages",
    LABEL_EMPLOYEE_TIMECARD_NOTIF: "employeeTimecard",
    LABEL_CREDIT_CARD_NOTIF: "creditCardNotif",
    LABEL_ACA_HEALTH_INSURANCE: "acaHealthInsurance",
    LABEL_TIMECARD_PUNCH: "timecardPumch",
    LABEL_DEDICATED_SUPERVISOR: "dedicatedSupervisor",
    LABEL_SUPERVISOR_NOTIFICATION: "supervisorNotif",
    LABEL_SUPERVISOR_ISOLATION: "supervisorIsolation",
    LABEL_CLIENT_CONTRACTORS: "clientContractors",
    LABEL_PAYREEL_CORDINATOR1: "payreelCordinator1",
    LABEL_MISSING_BULK_ENTRY: "missingBulkEntry",
    LABEL_BULK_ENTRY_EMPLOYEE_EDIT: "bulkEntryEmployeeEditTimecard",
    LABEL_BULK_ENTRY_EMPLOYEE_ADD_EDIT_EXPENSE_REPORT: "bulkEntryEmployeeAddEditExpense",
    LABEL_PAYREEL_CORDINATOR2: "payreelCordinator2",
    LABEL_GAURANTEED_HOURS_OT: "gauranteedHoursOT",
    LABEL_ACCOUNT_ALIAS: "accountAlias",
    LABEL_I9_REQUIRED: "i9Required",
    LABEL_ACCOUNT_DESC_ALLOWED: "accountDESCAllowed",
    LABEL_SUB_ACCOUNT_USED: "subAccountUsed",
    LABEL_IOS_ENABLED: "iOSEnabled",
    LABEL_ANDROID_ENABLED: "androidEnabled",
    LABEL_SUB_ACCOUNT_ALIAS: "subAccountAlias",
    LABEL_OVERTIME_CALCULATION: "overtimeCalculations",
    LABEL_SUB_ACCOUNT_DSC_ALLOWED: "subAccountDESCAllowed",
    LABEL_INVOICE_NUMBER_ALLOWED: "invoiceNumberAllowed",
    LABEL_UDF1_USED: "udf1Used",
    UDF1_ALIAS: "udf1Alias",
    LABEL_UDF2_USED: "udf2Used",
    UDF2_ALIAS: "udf2Alias",
    LABEL_UDF3_USED: "udf3Used",
    UDF3_ALIAS: "udf3Alias",
    LABEL_VZ_SETUP: "vzSetup",
    LABEL_ORACLE_SETUP: "oracleSetup",
    LABEL_IMG_SETUP: "imgSetup",
    LABEL_EMPLOYEMENT_AGREEMENT: "employeeAgreement",
    LABEL_MANUAL_PAYMENTS_ALLOWED: "manualPaymentAllowed",
    LABEL_MULTI_STATE_TIMESHEET: "MultiStateTimesheet",
    LABEL_USES_REQUISITION_NUMBER: "usesRequisitionNumber",
    LABEL_WORK_LOCATION_LEVEL: "workLocationLabel",
    LABEL_SUB_ACCOUNT_MASK: "subAccountMask",
    LABEL_PER_DEIM_CALCULATION: "perDeimCalculation",
    LABEL_POSITION_CLASSIFICATION_REQUIRED: "positionClassificationRequired",
    LABEL_CLIENT_STATUS: "clientStatus",
    LABEL_EVENT_TRACKING_LEVEL: "eventTrackingLevel",
    LABEL_UPLOAD_I9_SECTION2: "uploadI9Section2",
    LABEL_INVOICE_AUTO_DELIVERY: "invoiceAutoDelivery",
    LABEL_EMPLOYEE_TAKE_PICTURE: "employeeTakePicture",
    LABEL_PAYREEL_TEMPORARY_EMPLOYEE: "payreelTemporaryEmployee",
    LABEL_ALLOW_EMPLOYEE_AUTHORIZE_I9: "allowEmployeeI9",
    LABEL_OVERRIDE_PROJECT_APPROVERS: "overrideProjectApproves",
    LABEL_ONBOARDING_REMINDERS: "onboardingReminders",
    LABEL_EMPLOYEE_PAY_FREQUENCY: "employeePayFrequency",
    LABEL_CUSTOMIZE_CLIENT_USERS_UI: "customizeClientUsers",
    LABEL_ENABLE_MULTI_FACTOR_AUTHENTICATION: "enableMultiFactorAuthentication",
    LABEL_PROJECT_DETAILS_ON_SEARCH_SCREEN: "projectDetailsOnSearchScreen",
    EDIT_CLIENT_TITLE: "editClientsTitle",
    ADD_CLIENT_TITLE: "addClientsTitle",
    DID_YOU_MOVE: "didYouMove",
    VENDOR_UPADTE_SUCCESS: "vendorupdatesuccess",
    CHANGE_EXPENSE_TYPE: "ChangeExpenseType",
    ACCEPT: "Accept",
    WORKERS_COMP_JOB_TITLE: "workersCompJobTypesTitle",
    WORKERS_COMP_JOB_HEADING: "workerJobTypeHeading",
    LABEL_JOB_TYPE: "jobType",
    MANAGE: "manage",
    ADD_WORKERS_COMP_CODE_HEADING: "addWorkersCompCodes",
    REMOVE: "remove",
    GENERAL_LIABILITY_TITLE: "generalLiabilityTitle",
    GENERAL_LIABILTY_PERCENT: "generalLiabilityPercent",
    EDIT_GENERAL_LIABILITY_TITLE: "editGeneralLiabilityTitle",
    DELETE_EXPENSE_FAILED: "DeleteExpenseFailed",
    DELETE_EXPENSE_SUCCESS: "DeleteExpenseSuccess",
    EMPLOYEE_STATE_CHANGE_HEADING: "employeeStateChange",
    DID_YOU_MOVE_NEW_STATE: "didYouMoveNewState",
    ADD_UPDATE_DIRECT_DEPOSIT_HEADING: "addUpdateDirectDeposit",
    WELCOME_PAID_VIA_DIRECT_DEPOSIT: "welcomePaidViaDirectDeposit",
    PAYREEL_HANDBOOK_UPDATE_HEADING: "payreelHandbookUpdate",
    SIGN_SAVE_PAYREEL_HANDBOOK: "signAndSavePayreelHandbook.",
    SIGN_NOW: "signNow",
    UPLOAD_NOW: "uploadNow",
    REMIND_ME_LATER: "remindMeLater",
    SIGN_TEMP_HEADING: "signTempAgreement",
    ENDEAVOR_TEMP_AGREEMENT_HEADING: "endeavorTemporaryAgreement",
    OK: "ok",
    PAYMENT_REMINDER_HEADING: "paymentReminder",
    PAYREEL_PAYMENT_STATEMENT_HEADING: "payReelPaymentStatementUpdate",
    SIGN_SAVE_PAYMENT_STATEMENT: "signAndSavePayReelPaymentStatement",
    SHTC_UPDATE_HEADING: "SHTCupdate",
    ADD_DIVISIONS_BTN: "AddDivisionbtn",
    DIVISION_DELETED: "DivisionDeleted",
    DIVISION_NOT_DELETED: "Divisionisnotdeleted",
    AGENCY_FEE: "agencyfees",
    AGENCY_PERCENTAGE: "agencyper",
    LABEL_SUB_ACCOUNT: "subaccount",
    REVIEW_EMPLOYEE_I9_FORM_HEADING: "ReviewEmployeeI9Form",
    ACCEPTABLE_DOCUMENTS_TEXT: "AcceptableDocumentsText",
    INSERT_ATTRIBUTE: "insertAttribute",
    EXPRESSION: "Expression",
    ADD_NEW: "AddNew",
    LABEL_SHORT_SSN: "SSN",
    PAYREEL_USERS_TITLE: "payreelUsersTitle",
    ADD_PAYREEL_USER: "addPayreelUsers",
    WORKER_DASHBOARD: "workerdashboard",
    WORKER: "worker",
    INVOICING_MISC_CHARGES_TITLE: "InvoicingMiscCharges",
    LABEL_CHARGE_TYPE: "ChargeType",
    LABEL_DARWIN_CODE: "DarwinCode",
    MANAGE_INVOICE_LAYOUT_TITLE: "ManageInvoiceLayout",
    LABEL_LAYOUT_NAME: "LayoutName",
    LABEL_INVOICE_TYPE: "InvoiceType",
    LABEL_ACTIVE: "Active",
    EMP_ELIGIBILITY_TITLE: "EmployeeEligibility",
    EMP_NUMBER: "EmployeeNumber",
    EMP_DATE_OF_HIRE: "EmployeeDateofHire",
    EMP_DATE_OF_PSL_AVL: "EmployeeDateofPSLAvailable",
    LOCATION_ST_ELIGIBLE: "LocationSTEligible",
    LOCATION_CITY_ELIGIBLE: "LocationCityEligible",
    ADD_NEW_EMP_BTN: "AddNewEmp",
    PAYROLL_ENTRY_CHANGE_HEADING: "payrollEntryChange",
    SIGN_OFF: "signOff",
    PROCEED_TIMESHEET_ENTRY: "proceedToTimesheetEntry",
    ADD_DAY: "AddDay",
    REMINDER_EMAIL_WORKER: "reminderEmail",
    ADD_RECEIPTS: "addReceipts",
    EMPLOYEE_ACCRUAL_BALANCE_TITLE: "employeeAccrualTitle",
    LABEL_EMPLOYEE_NUMBER: "employeeNumber",
    RUN_PROCEDURE_BTN: "runProcedure",
    GOC: "goc",
    APPROVING_SUPERVISOR: "ApprovingSupervisor",
    SECONDARY_SUPERVISOR: "SecondarySupervisor",
    ITEM_ID: "Itemid",
    INVOICE_CONTACT_NAME: "Invoicontactname",
    IMVOICE_CONTACT_EMAIL: "Invoicecontactemail",
    EDIT_PAYREEL_USER_TITLE: "editPayreelUsers",
    ADD_PAYREEL_USER_HEADING: "addPayreelUsersHeading",
    EDIT_PAYREEL_USER_HEADING: "editPayreelUsersHeading",
    ADMINISTRATIVE_ACCESS: "administrativeAccess",
    COORDINATOR: "coordinator",
    SAVE_DAY: "SaveDay",
    LABEL_CONTACT: "contact",
    LABEL_PHONE: "phone",
    LABEL_ADDRESS: "address",
    LABEL_ZIP1: "zip",
    LABEL_BUDGET: "budget",
    LABEL_PROJECT: "project",
    LABEL_DIVISION: "division",
    LABEL_NUMBER: "number",
    PROJECT_TRACKING_UPLOAD_HEADING: "projectTrackingUpload",
    LABEL_CREATE_UPDATE_PROJECTS: "createUpdateProjects",
    REPORTS_TITLE: "reports",
    LABEL_PAYROLL: "payroll",
    LABEL_EXPENSE: "expense",
    LABEL_VENDOR_PAYMENT: "vendorPayment",
    CLIENT_REPORT_FORM_HEADING: "clientreportheading",
    PLEASE_ADD_AN_EXPENSE: "pleaseAddAnExpense",
    VALIDATION_MESSAGE: "validationMessage",
    REGULAR_EXPRESSION: "regularExpression",
    LABEL_APPROVE_TIMESHEET_TITLE: "ApproveTimesheet",
    APPROVE_SELECTED_TIMESHEETS_BTN: "Approveselectedtimesheet",
    SELECT_TIMESHEET_TYPE: "Selecttimesheettype",
    LABEL_MY_TIMESHEETS: "Mytimesheets",
    LABEL_ALL_TIMESHEETS: "alltimesheets",
    VENDOR_PAYMENTS_VZ_TITLE: "vendorPaymentVerizon",
    PLEASE_ADD_AN_EXPENSE_RECEIPT: "pleaseAddAnExpenseReceipt",
    RELEASE_PAYROLL: "releasePayroll",
    GET_TIMESHEETS: "getTimesheets",
    GET_EXPENSES: "getExpenses",
    GET_VENDOR_PAYMENTS: "getVendorPayments",
    GET_MANUAL_PAYMENTS: "getManualPayments",
    INSURANCE_CERTIFICATE_NOT_FOUND: "InsuranceCertNotFound",
    INSURANCE_CERTIFICATE_PENDING: "InsuranceCertificatePending",
    INSURANCE_CERTIFICATE_EXPIRED: "InsuranceCertificateExpired",
    CLIENT_NAME: "clientName",
    CONTACT_NAME: "contactName",
    CONTACT_CITY: "contactCity",
    CONTACT_PHONE: "contactPhone",
    CONTACT_EMAIL: "contactEmail",
    LABEL_MESSAGES: "Messages",
    EDIT_CLIENT: "EditClient",
    WORK_ORDER: "workOrder",
    LABEL_DATE: "date",
    LABEL_BUSINESS_NAME: "businessName",
    LABEL_CLASSIFICATION: "classification",
    LABEL_TIN: "tin",
    PLEASE_FILL_THE_DETAILS_BELOW: "pleaseFillTheDetailsBelow",
    UPLOAD_DOCUMENT: "pleaseUploadDocumnet",
    LABEL_CREATED_DATE: "createdDate",
    LABEL_FORM: "form",
    PENDING_DOCUMENT: "pendingDocuments",
    NO_PENDING_DOCUMENT: "noPendingDocument",
    LABEL_UPLOAD: "upload",
    PRECISION: "Precision",
    GET_CREDIT_CARD_TRANSACTIONS: "getCreditCardTransactions",
    EMPLOYEE_ARBITRATION_AGGREMENT: "EmployeeArbitrationAggrement",
    INVOICE_SAVED: "InvoiceSaved",
    INVOICE_UPDATED: "InvoiceUpdated",
    SELECT_EXPORT: "SelectExport",
    LABEL_MANUAL_PAYMENT_TITLE: "ManualPayments",
    MANUAL_PAYMENT_STATUS_HISTORY_TITLE: "manualpaymentstatushistory",
    PAYROLL_RECORD_NOT_FOUND: "payrollRecordNotFound",
    READY_TO_RELEASE_PAYROLL: "readyToReleasePayroll",
    CONFIRM: "confirm",
    RELEASE_PAYROLL_TRANSMITTED_SUCCESSFULLY: "RELEASE_PAYROLL_TRANSMITTED_SUCCESSFULLY",
    DIVISION_ERROR: "divisionErr",
    LABEL_APPROVE_MANUAL_PAYMENTS_TITLE: "approveManualTitle",
    MY_MANUAL_PAYMENTS: "myManualPayment",
    ALL_MANUAL_PAYMENTS: "allManualPayment",
    LABEL_APPROVE_MANUAL_PAYMENTS: "manualPayments",
    LABEL_APPROVE_MANUAL_PAYMENT_BUTTON: "approveManualPaymentButton",
    RETURN_TO_PAYREEL: "returntopayreel",
    CORP_CREATE_REPORTS_FORM_HEADING: "CorpCreateReportFormHeading",
    EDIT_1099_WORKER_FORM_HEADING: "edit1099WorkerForm",
    DOWNLOAD_DOCUMENT_SUCCESS: "DownloadDocumentSuccess",
    UPLOAD_PROJECT_ERROR: "reportedErrors",
    LABEL_APPROVE: "approve",
    RETURN_TO_EMPLOYEE: "returnToEmployee",
    DELETE_ALLOCATION: "deleteAllocation",
    COMPLETE_ALLOCATION: "completeAllocation",
    ALLOCATION_DETAILS: "allocationDetails",
    HOURS_ALLOCATION: "hoursAllocation",
    ALLOCATION_COMPLETED: "allocationCompleted",
    LABEL_MARK_AS_COMPLETE: "markascomplete",
    ARE_YOU_SURE_MARK_AS_COMPLETE: "Areyousureyouwouldliketomarkthisascomplete",
    SUCCESSFULLY_MARKED_AS_COMPLETE: "MarkedasCompleteSuccessfully.",
    FAILED_MARK_AS_COMPLETE: "MarkasCompleteFailed!.",
    ONE_TIMESHEET_FOR_APPROVE_MSG: "OneTimesheetForAprroveMsg",
    ONE_MANUAL_PAYMENT_FOR_APPROVE_MSG: "OneManualPaymentForAprroveMsg",
    APPROVE_TIMESHEET_SUCCESS: "ApproveTieeetSuccess",
    APPROVE_TIMESHEET_FAILURE: "ApprovetimesheetFailure",
    APPROVE_MANUAL_PAYMENT_SUCCESS: "ApproveManualPaymentSuccess",
    APPROVE_MANUAL_PAYMENt_FAILURE: "ApproveManualPaymentFailure",
    INVOICING_HISTORY_TITLE: "InvoicingHistory",
    AR_EXPORT: "ArExport",
    INVOICE_STATUS: "InvoiceStatus",
    INVOICE_CREATION_DATE: "InvoiceCreationDate",
    ADD_BACKUP_TXT: "Pleaseaddbackup",
    DOCUMENT_CHANGES: "documentChanges",
    RETURN_TO_PAYREEL_SUCCESS_COMMENT: "returntopayreelsuccesscomment",
    RETURN_TO_PAYREEL_FAILURE_COMMENT: "returntopayreelfailurecomment",
    NEW_W4_COMPLETED: "newW4Completed",
    BUDGET_ISSUES: "budgetIssues",
    INVOICE_MISC_CHANGE_SAVE_SUCCESS: "miscSuccessMsg",
    INVOICE_MISC_CHANGE_UPDATE_SUCCESS: "miscUpdateMsg",
    LESS_AVAILABLE_ALLOCATION_HOURS: "lessAvailableAllocationHours",
    HOURS_ALLOCATED_SUCCESSFULLY: "hoursAllocatedSuccessfully",
    ALLOCATION_DELETED_SUCCESSFULLY: "allocationDeletedSuccessfully",
    AGREEMENT_CHANGES: "agreementChanges",
    RETURN_TIMESHEET: "ReturnTimesheet",
    RETURN_EXPENSE_REPORT: "ReturnExpenseReport",
    RETURN_TIMESHEET_SUCCESS: "ReturnTimesheetSuccess",
    RETURN_TIMESHEET_FAILURE: "ReturnTimesheetFailure",
    VIEW_EMPLOYEE_INFO_TITLE: "viewEmployeeinfo",
    LABEL_EMPLOYEE_INFO: "Employeeinfo",
    LABEL_EMPLOYEE_POSITIONS: "positions",
    LABEL_HOME_STATE: "HomeState",
    LABEL_PRIMARY_WORK_STATE: "PrimaryWorkState",
    LABEL_EXEMPT: "exempt",
    LABEL_EXEMPT_JUSTIFICATION: "excemptjustification",
    LABEL_EXEMPT_MAX_PER_WEEK: "exemptmaxperweek",
    LABEL_EXEMPT_MAX_HOURS_PER_DAY: "exemptmaxhrsperday",
    LABEL_PROCESS_HOURS_AS_CALIFORNIA: "processhrscal",
    LABEL_NON_WORKED_HRS_ALLOWED: "nonworkedhrsallwd",
    LABEL_WEB_USER_STATUS: "webuserstatus",
    VOIDED_INVOICES: "voidedInvoices",
    RETURN_TO_VENDOR: "ReturnToVendor",
    ACCOUNT_UPDATED_SUCCESS: "AccountUpdateSuccess",
    ACCOUNT_UPDATE_FAILED: "AccountUpdateFailed",
    ALLOCATION_COMPLETED_SUCCESSFULLY: "allocationCompletedSuccessfully",
    LABEL_SHORT_SSN_LAST_FOUR: "ssnlastfour",
    LABEL_SUPERVISOR: "supervisor",
    LABEL_BACKUP_SUPERVISOR: "Backupsupervisior",
    LABEL_REQUIRED_WORK_DESCRIPTION: "RequireWorkDescription",
    LABEL_REQUIRED_WORK_LOCATION: "RequireWorkLocation",
    LABEL_DEFAULT_WORK_LOCATION: "DefaultWorkLocation",
    EMPLOYEE_COMMENTS_TITLE: "employeecomments",
    ACCOUNT_CHANGE_HAEDING: "AccountChangeHeading",
    APPROVE_VENDOR_PAYMENT_FAILURE: "ApprovevendorpaymentFailure",
    APPROVE_VENDOR_PAYMENT_SUCCESS: "ApprovevendorpaymentSuccess",
    ONE_VENDOR_PAYMENT_FOR_APPROVE_MSG: "onevendorpaymentforapproval",
    LABEL_STATE_CITY_ZIP: "stateCityZip",
    SEXUAL_HARASSMENT_CERTIFICATES: "sexualHarassmentCertificates",
    EXPORT_GRID: "exportGrid",
    RETURN_TO_VENDOR_SUCCESS_COMMENT: "returntovendorsuccesscomment",
    RETURN_TO_VENDOR_FAILURE_COMMENT: "returntovendorfailurecomment",
    VENDOR_PAYMENT_STATUS_HISTORY: "VendorPaymentStatusHistory",
    RETURN_TO_NEXT_FORM: "ReturnToNextForm",
    CORP_BTN_CREATE_REPORTS_FORM_HEADING: "CorpBtnCreateReportFormHeading",
    SEARCH_EMPLOYEE_TITLE: "searchEmployees",
    LABEL_EMPLOYEE_ID: "employee#",
    RECHECK_RECORDS: "recheckRecords",
    SIGN_AND_SUBMIT_PRE_COMMENT_TXT: "SignandSubmityouverifyyour",
    SIGN_AND_SUBMIT_POST_COMMENT_TXT: "iscorrect",
    EXPENSE_REPORT_TITTLE: "expensereporttittle",
    ALLOCATION: "allocation",
    ADD_ALLOCATION: "addAllocation",
    RETURN_TO_EMPLOYEE_SUCCESS_COMMENT: "returnToEmployeesuccessmsg",
    RETURN_TO_EMPLOYEE_FAILURE_COMMENT: "returnToEmployeefailuremsg",
    VENDOR_PAYMENT_RECALLED_SUCEESS: "vendorPaymentRecallSuccess",
    VENDOR_PAYMENT_RECALLED_FAILURE: "vendorPaymentRecallFailure",
    ALLOCATION_ADDED_SUCCESSFULLY: "allocationAddedSuccessfully",
    INVALID_LOGIN_HEADING: "InvalidLginHeading",
    SSN_OR_FEIN: "ssnOrEin",
    EDIT_DDN: "editDDN",
    UPLOAD_VOIDED_CHECK: "uploadVoidedCheck",
    MANAGE_UN_SIGNED_TIMESHEETS_TITLE: "manageunsignedtimesheet",
    LABEL_ALL_PROJECTS: "allprojects",
    LABEL_ALL_DATES: "allDates",
    LABEL_TIMECARD_PUNCH_DATA: "timecardPunchData",
    DATE_WORKED: "dateWorked",
    ADD_NEW_RECORD: "addNewRecord",
    LABEL_CHECK: "check",
    LABEL_FULLTIME: "fulltime",
    LABEL_VARIABLE_TO_FULLTIME: "variabletofulltime",
    LABEL_VARIABLE_HOURS: "variablehours",
    LABEL_PART_TIME: "parttime",
    LABEL_INVITED_TO_ENROLL: "invitedtoenroll",
    LABEL_NOT_INVITED_TO_ENROLL: "notinvitedtoenroll",
    GRACE_PERIOD_EMPLOYMENT_AGREEMENT: "AllowGracePeriodforsubmitEmploymentAgreement",
    LABEL_EMPLOYEE_ACA_STATUS: "EmployeeACAStatus",
    LABEL_VISA_TAX_EXEMPT: "VISAtaxexempt",
    LABEL_EMPLOYEE_PAYMENT_SELECTION: "EmployeePaymentSelection",
    LABEL_BENEFITS_ELIGIBILITY_DATE: "BenefitsEligibilityDate",
    LABEL_ADMINISTRATIVE_PERIOD: "AdministrativePeriod",
    LABEL_ACA_HEALTH_INSURANCE_ENROLLED: "ACAhealthInsuranceEnrolled",
    LABEL_EDIT_TIMESHEETS: "EditTimesheets",
    LABEL_RETIREMENT_BENEFIT_ELIGIBILITY: "RetirementBenefitEligibility",
    LABEL_EMPLOYEE_TYPE: "EmployeeType",
    RATE_TYPE_WEEKLY_GUARANTEE: "weeklyguarantee",
    RATE_PER_HOUR: "rateperhour",
    RATE_PER_DAY: "rateperday",
    RATE_PER_WEEK: "rateperweek",
    LABEL_TREAT_POSITION_AS_EXEMPT: "TreatpositionasExempt",
    LABEL_DAILY_OVERTIME: "DailyOvertime",
    LABEL_DAILY_LENGTH: "DayLength",
    LABEL_DAILY_DOUBLE_TIME: "DailyDoubleTime",
    TEMP_PART_TIME: "TempPartTime",
    WEB_SETTINGS_RULE_TITLE: "UsernameandPasswordmustfollowthefollowingrules",
    EDIT_EMPLOYEE: "editemployee",
    EMPLOYEE_INFO_UPDATED_SUCCESSFULLY: "employeeupdatedsuccessfully",
    EMPLOYEE_INFO_UPDATE_FAILED: "employeeupdatefailed",
    EDIT_DDA_FORM_FAILURE: "EditDDAFailure",
    EDIT_DDA_FORM_SUCCESS: "EditDDASucess",
    LABEL_WEEK_END: "weekEnding",
    APPROVE_EXPANSE_REPORT_SUCCESS: "ApproveExpenseReportSuccess",
    APPROVE_EXPANSE_REPORT_FAILURE: "ApproveExpenseReportFailure",
    ONE_EXPENSE_REPORT_FOR_APPROVAL_MSG: "OneExpenseReportForAprroveMsg",
    INV_HISTORY_SUCCESS_MSG: "InvoiceHistorySucessMsg",
    INV_HISTORY_FAILED_MSG: "InvoiceHistoryFailedMsg",
    INV_LAYOUT_SAVE_SUCESS: "InvLayoutSaveSuccess",
    INV_LAYOUT_UPDATE_SUCESS: "InvLayoutUpdateSuccess",
    INV_HISTORY_PAID_MSG: "InvoiceHistoryPaidMsg",
    INV_HISTORY_UNPAID_MSG: "InvoiceHistoryUnPaidMsg",
    LABEL_SELECT_EXPORT: "selectExport",
    LABEL_COMPANY_CODE: "companyCode",
    LABEL_BUSINESS_PHONE_NUMBER: "businessPhoneNumber",
    LABEL_CELL_PHONE_NUMBER: "cellphoneNumber",
    LABEL_MANAGER: "manager",
    LABEL_CLIENT_WORKER: "clientworker",
    LABEL_SUPERVISOR_REPORT_ACCESS: "SupervisorReportAccess",
    APPROVE_CREDIT_CARD_TRANSACTIONS: "approveCreditCardTransactions",
    MY_CREDIT_CARD_TRANSACTIONS: "myCreditCardTransactions",
    ALL_CREDIT_CARD_TRANSACTIONS: "allCreditCardTransactions",
    INVOICE_LEVEL_GROUPING: "invoiceLevelGrouping",
    MAPPING_FIELDS: "mappingFields",
    LABEL_TREAT_POSITION_EXEMPT: "treatPositionAsExempt",
    LABEL_DAILY_DOUBLETIME: "dailyDoubleTime",
    LABEL_RATE_PER_HOUR: "ratePerHour",
    LABEL_RATE_PER_DAY: "ratePerDay",
    LABEL_RATE_PER_WEEK: "ratePerWeek",
    LABEL_DAY_WORKED_FROM: "dayWorkedFrom",
    LABEL_DAY_WORKED_TO: "dayWorkedTo",
    READ_TRAVEL_POLICY: "ReadTravelPolicy",
    PROCEES_TO_TIMESHEET_ENTRY: "ProccedToTimeSheetEntry",
    DIRECT_DEPOSIT_UPLOAD_FAILURE_TITLE: "directDepositFailureTitle",
    DIRECT_DEPOSIT_UPLOAD_INFO_TITLE: "directDepositInfo",
    LABEL_MONTH: "month",
    LABEL_YEAR: "year",
    SUCCESS_DELETE_TIMECARD: "successDeleteTimecard",
    ARE_YOU_SURE_DELETE_RECORD: "sureToDeleteRecord",
    SUCCESS_INSERT_TIMECARD: "successInsertTimecard",
    PREVIOUS_WEEKEND_ERROR: "previousWeekendError",
    CREDIT_CARD_TRANSACTIONS_APPROVAL_SUCCESSFUL: "successfullyApprovedCreditCard",
    CREDIT_CARD_TRANSACTIONS_APPROVAL_FAILED: "creditCardApprovalFailed",
    LABEL_EMPLOYEE_USER_NAME_EXISTS: "employeewithsameusernamealreadyexists",
    EMPLOYEE_REPEAT_I9_HEADING: "EmployeeRepeatI9Heading",
    EMPLOYEE_REPEAT_FORM_HEADING: "EmployeeRepeatFormHeading",
    PREVIOUS_CLIENT_EMPLOYEE_REPEAT: "PreviousClientEmployeeRepeat",
    PREVIOUS_EMPLOYEE_CLIENT_NAME: "PreviousClientName",
    VALIDATE_UPLOADED_FILE: "validateUploadedFile",
    INVALID_EMPLOYEE_ID: "invalidEmployeeId",
    SUCCESS_UPDATE_TIMECARD: "successUpdateTimecard",
    SYSTME_FIELD_SUBMITTED_SUCCESSFULLY: "systemFieldSubmittedSuccessfully",
    LABEL_WORKER_INFORMATION: "WorkerInformation",
    VOID_INV_SUCCESS: "VoidInvSuccess",
    VOID_INV_FAILED: "VoidInvFailed",
    VOID_CONFIRM_DAILOG: "ToVoidAnInvoice",
    LABEL_NON_WORKED_HOURS_CODES_TITLE: "NonWorkedHoursCodes",
    LABEL_EARNINGS_CODE: "EarningsCode",
    RADIO_OPTION_VA: "optionva",
    RADIO_OPTION_HO: "optionho",
    RADIO_OPTION_TD: "optiontd",
    RADIO_OPTION_JD: "optionjd",
    RADIO_OPTION_AP: "optionap",
    RADIO_OPTION_MP: "optionmp",
    RADIO_OPTION_HR: "optionhr",
    RADIO_OPTION_SL: "optionsl",
    LABEL_WORK_LOCATIONS: "workLocations",
    ADD_WORK_LOCATION_BTN: "addWorkLocations",
    LABEL_AGREE: "agree",
    ALL_EVENTS_PROJECTS: "allEventsProjects",
    LABEL_EVENT_PROJECT: "eventProject",
    ALL_POSITIONS: "allPositions",
    ALL_EMPLOYEES: "allEmployees",
    ALL_VENDORS: "allVendors",
    LABEL_MONTH_BILLED: "monthBilled",
    I9_REVERICATION_POPUP_TEXT: "I9ReverificationPopupText",
    PAYREEL_USER_INFO_TAB: "PayreelUserInfo",
    EXPENSE_CODES_TITILE: "expensecodestitle",
    LABEL_MISC_ITEM_CODE: "MiscItemCode",
    LABEL_RECEIPTS_NEEDED: "Receiptsneeded",
    LABEL_TAXABLE: "Taxable",
    LABEL_GL_ACCOUNT_NUMBER: "GLAccountNumber",
    RADIO_EXPREIMB_C: "expreimbc",
    RADIO_EQUIP_C: "equipc",
    LABEL_ADD_EMPLOYEE: "AddEmployee",
    LABEL_SELECT: "selectlabel",
    GENERAL_HEADING_FOR_W9_FORM: "pleaseClickW9ToBelowToBeginTheProcess",
    LABEL_DOCUMENTS: "documents",
    INFORMATION_SAVED_SUCCESSFULLY: "informationSavedSuccessfully",
    INFORMATION_SAVE_FAILED: "informationSaveFailed",
    DOWNLOAD_RECIEPTS: "DownloadReciepts",
    FAILED_INSERT_TIMECARD: "failedInsertTimecard",
    FAILED_UPDATE_TIMECARD: "failedUpdateTimecard",
    ADD_POSITION: "AddPosition",
    LABEL_GL_CODE: "glCode",
    LABEL_SUPERVISOR_POSITION: "supervisorposition",
    LABEL_POSITION_CLASSIFICATION: "positionClassification",
    EDIT_POSITION: "editposition",
    RECIEPT_COMMENTS_SUCCESS: "RecieptCommentsSuccess",
    RECIEPTS_COMENTS_FAILURE: "RecieptCommentsFailure",
    MIN_SEARCH_CRITERIA_ERROR: "minimumSearchCriteria",
    LABEL_WORKED_DAY_START: "workedDayStart",
    LABEL_WORKED_DAY_END: "workedDayEnd",
    INACTIVATE_ALL_EMPLOYEES_PAGE_TITLE: "InactivateAllEmployees",
    INACTIVATE_ALL_EMPLOYEES_FORM_HEADING_WARNING: "PleasereadWarningmessagebeforeyouproceed",
    WARNING: "Warning!",
    INACTIVATE_ALL_EMPLOYEES_WARNING: "Clicking 'Inactivate All Employees' will inactivate all employees for this client.",
    EMAIL_REQUEST_FOR_1099_WORKER_DOCUMENTS: "emailRequestFor1099WorkerDocuments",
    EMAIL_REQUEST_FOR_1099_WORKER_DOCUMENTS_FORM_HEADING: "pleaseEnterTheInformationBelowToLaunchEmailTo1099Worker",
    EDIT_WORK_LOCATION_TITLE: "editWorkLocationTitle",
    ADD_WORK_LOCATION_TITLE: "addWorkLocationTitle",
    EDIT_WORK_LOCATION_HEADING: "editWorkLocation",
    ADD_WORK_LOCATION_HEADING: "addWorkLocation",
    PAID_SICK_LEAVE_RULES_TITLE: "paidSickLeaveRuleTitle",
    ADD_NEW_RULE_BTN: "addNewRules",
    IMPORT_RULES_BTN: "importRules",
    LABEL_EMPLOYEE_ELIGIBLE_DAYS: "employeeEligibleDays",
    LABEL_EMPLOYEE_ELIGIBLE_HOURS: "employeeEligibleHours",
    LABEL_ACCRUAL_RATE_HOURS: "accrualRateHours",
    LABEL_ACCRUAL_RATE_HOURS_WORKED: "accrualRateHoursWorked",
    LABEL_ACCRUAL_BEGIN_DAYS: "accrualBeginDays",
    LABEL_ACCRUAL_CAPS_HOURS: "accrualCapsHours",
    LABEL_PSL_ACCESSIBLE_DAYS: "pslAccessibleHours",
    LABEL_PSL_USAGE_CAPS_HOURS: "pslUsageCapsHours",
    LABEL_PSL_CARRY_OVER_HOURS: "pslCarryOverHours",
    EMPLOYEE_ADDED_SUCCESSFULLY: "EmployeeAddedSuccessFully",
    EMPLOYEE_ADD_FAILED: "AddEmployeeFailed",
    LABEL_BATCH: "batch",
    ALL_BATCHES: "allBatches",
    MANAGE_DASHBOARD_PAGE_HEADING: "1099WorkerDashboardStatus",
    REQUIRED_DOCUMENTS_CHANGES_NOTES: "requiredDocumentChangeNotes",
    STATE_AGREEMENT_DATE_TEXT: "StateAgreementDateText",
    EDIT_PAID_SICK_LEAVE_RULES: "editPaidSickLeaveTitle",
    ADD_PAID_SICK_LEAVE_RULES: "addPaidSickLeaveTitle",
    EDIT_PAID_SICK_LEAVE_RULES_HEADING: "editPaidSickLeaveRulesHeading",
    ADD_PAID_SICK_LEAVE_RULES_HEADING: "addPaidSickLeaveRulesHeading",
    LABEL_ACCRUAL_RATE_FOR_EMPLOYEE_HOURS_WORKED: "accrualRateForEmpHours",
    LABEL_ACCRUAL_BEGIN_DOE: "accrualBeginDOE",
    EXPIRATION_DATE: "expirationDate",
    LABEL_STATE_AGGREGATE: "stateAggregate",
    SICK_RULE_SAVED_SUCCESSFULY: "sickRulesSaved",
    DUPLICATE_PAID_SICK_LEAVE_SAVE: "duplicatePaidSickLeaveRule",
    INVALID_PAID_SICK_LEAVE_SAVE: "sickRulesSavedFailed",
    CUSTOM_LABELS: {
        FOR_COMMENT_REASON_IN_CHANGE_SELECTION: "pleaseenterTheReasonForTheChange",
        MANAGE_REQUIRED_DOCUMENTS: "manageRequiredDocuments",
        MANAGE_OPTIONAL_DOCUMENTS: "manageOptionalDocuments",
    },
    BATCH_RECORDS_TITLE: "BatchRecordTitle",
    BATCH_SELECTED_RECORDS: "BatchSelectedRecords",
    SEARCH_BATCH_SELECT: "SearchBatchSelect",
    BATCH_EXPORTS: "batchExports",
    BATCH_EXPORTS_HEADING: "batchExportsHeading",
    LABEL_WORK_DESCRIPTION: "LabelWorkDescription",
    MANAGE_AR_RECORDS_TITLE: "ManageArRecords",
    ADD_SELECTED_RECORDS: "AddSelectedRecords",
    MANAGE_AP_RECORDS_TITLE: "ManageAPRecordsTitle",
    MANAGE_PAYROLL_RECORDS_TITLE: "ManagePayrollRecordsTitle",
    PAYROLL_BATCH_EXPORTS: "payrollBatchExports",
    APPROVE_CREDIT_CARD_REPORT_SUCCESS: "ApproveCreditCardSucceess",
    APPROVE_CREDIT_CARD_REPORT_FAILURE: "creditCardApprovedFailure",
    AP_BATCH_EXPORTS: "apBatchExports",
    AR_BATCH_EXPORTS: "aratchExports",
    UPLOAD_VERIZON_INGEST_FILE: "uploadVerizonIngestFile",
    EXPORT_ADDED_SUCCESSFULLY: "exportAddedSuccessfully",
    LABEL_NOTES: "Notes",
    LABEL_ARCHIVE: "Archive",
    ARE_YOU_SURE_DELETE_ITEM_CONFIRMATION: "AreyousureyouwouldliketoDeletethisItem?",
    ARCHIVED_SUCCESSFULLY: "Archivedsuccessfully",
    VIEW_ARCHIVED_NOTES: "ViewArchivedNotes",
    ARCHIVED_NOTES_TITLE: "ArchivedNotesTotle",
    EDIT_EMPLOYEE_NOTES_FORM_HEADING_TITLE: "Pleasefilltextareatoaddnote",
    PERFOMANCE_COMPLIANCE_PAGE_TITLE: "PerformanceCompliance",
    ADD_ITEM: "addItem",
    ENTER_PERFORMANCE_COMPLIANCE_ITEM: "EnterPerformanceComplianceItem",
    REPORT_BUTTON: "reportButton",
    SECONDARY_PROJECT: "secondaryProject",
    LABEL_MONTH_WORKED: "monthWorked",
    MSG_SELECT_EVENT_SECONDARY_PROJECT: "selectAtleastEventSecondary",
    MSG_SELECT_EMPLOYEE_VENDOR: "selectAtleastEmployeeVendor",
    MSG_SELECT_WEEK_MONTH_STARTEND_DATE: "selectWeekEndingMonthStartEndDate",
    ACCOUNT_DEFAULT_LABEL: "AccountDefaultLabel",
    SUB_ACCOUNT_DEFAULT_LABEL: "SubAccountDefaultLabel",
    LABEL_COUNTY: "county",
    EXPORT_CLOSED_SUCCESSFULLY: "exportClosedSuccessfully",
    EXPORT_REOPENED_SUCCESSFULLY: "exportOpenedSuccessfully",
    LEBEL_DATE_OF_HIRE: "dateOfHire",
    WORKERS_COMP_CODE_DELETED_SUCEESS: "compCodeDeletedSuccess",
    WORKERS_COMP_CODE_DELETED_FAILURE: "compCodeDeletedFailure",
    JOB_TYPE_ADD_FAILURE: "jobTypeAddFailure",
    JOB_TYPE_UNIQUE_MESSAGE: "jobTypeMustBeUnique",
    SURE_TO_REMOVE_RECORD_FROM_EXPORT: "sureToRemoveRecordFromExport",
    DARWIN_INFO_TITLE: "DarwinInformation",
    GRID_LABEL_ADDRESSES: "Addresses",
    GRID_LABEL_PAYABLES: "Payables",
    GRID_LABEL_PAYSTUBS: "Paystubs",
    ADD_VENDOR: "addVendor",
    VENDOR: "vendor",
    LABEL_VENDOR_TYPE: "vendorType",
    BUNDLE_PAY_OR_INDEPENDENT_CONTRACTOR: "bundlePay",
    INDEPENDENT_CONTRACTOR_PAYING_FOR_WC: "IndependentContractor",
    SSN_OR_EIN: "ssnOrEin",
    LABEL_DEBIT_EXTENDS: "debitsExtends",
    LABEL_MANUAL_PAYMENTS: "manualPayments",
    DOWNLOAD_PDF: "downloadPdf",
    SUCCESSFULLY_APPROVED_BULK_REPORT: "successfullyApprovedBulkReport",
    ALL_WORK_ORDERS: "allWorkOrders",
    ORDER_NUMBER: "orderNumber",
    RETRIEVE_JOB: "retrieveJob",
    ADD_TIME_SHEET: "addTimeSheet",
    ROUTING_NUMBER: "routingNumber",
    USERNAME_LABEL: "UsernameLabel",
    DOWNLOAD_REPORT_SUCCESS: "Report Downloaded Successfully",
    PUNCH_TYPE_ERROR: "punchTypeCard",
    NO_DATE_ERROR: "noDateError",
    AGENCY_FEE_VENDOR: "agencyFeeVendor",
    ADMIN_NO_FEE_LABEL: "adminFeeLabel",
    AGENCY_FEE_PERCENT_LABEL: "agencyFeePercentLabel",
    INACTIVATE_CONFIRM_MESSAGE: "AreyousureyouwouldliketoInActivthisItem",
    ACTIVATE_CONFIRM_MESSAGE: "AreyousureyouwouldliketoActivthisItem",
    BULK_IMPORT_DOWNLOAD: "bulkImportDownload",
    RADIO_LABEL_INPROCESS: "InProcess",
    ADD_MANUAL_PAYMENTS_BTN: "addManualPaymentsbtn",
    SELECT_MANUAL_PAYMENTS_TYPE: "Selectmanualpaymenttype",
    RECALCULATE_TIMESHEET_BTN: "reCalculateTimesheetBtn",
    CONFIRM_REMOVE_EXPENSE: "confirmRemoveExpense",
    CONFIRM_REMOVE_TIMESHEET: "confirmRemoveTimeshet",
    CONFIRM_REMOVE_VENDOR_PAYMENT: "confirmVendorPayment",
    CONFIRM_REMOVE_MANUAL_PAYMENT: "confirmManualPayment",
    LABEL_FILE: "labelFile",
    HEADING_STATES_SETUP: "labelStatesSetup",
    ADD_NEW_LOCATION_HEADING: "addNewCountryStateCityHeading",
    LABEL_SHORT_STATE: "shortState",
    NEW_LOCATION_SUCCESS_HEADING: "newLocationAddedSuccess",
    ADD_NEW_COUNTRY_STATE_CITY_BTN: "addNewCountryStateCity",
    RECALCULATE_TIMESHEET_SUCCESS_MSG: "reCalculateTimesheetSuccessmsg",
    PAYSTUB_FORM_HEADING: "payStubHeading",
    LABEL_RECALCULATE_EXPENSE_REPORT: "recalculateExpenseReport",
    LABEL_UPDATE_DOCUMENT: "updateDoc",
    LABEL_SEARCH_CREDIT_CARD_TRANSACTION: "SearchCreditCardTransaction",
    PAYREEL_REPORTS: "payreelReports",
    CLIENT_REPORTS: "clientReports",
    MINIMUM_WAGE_RATE_TYPE_FORM_HEADING: "SelecttheStatecountyand/orcitytoaddminimumwagerate",
    MINIMUM_WAGE_RATE_TITLE: "Minimumwagerate",
    EDIT_MINIMUM_WAGE_RATE_TITLE: "EditMinimumwagerate",
    LABEL_MINIMUM_WAGE_RATE: "labelMinimumwagerate",
    LABEL_LOCATION_OF_SERVICE: "locationOfService",
    LABEL_PRODUCTION_START_DATE: "productionStartDate",
    LABEL_PRODUCTION_END_DATE: "productionEndDate",
    LABEL_AUTO_FILL_INOUT_TIMES: "autoFillInOutTimes",
    LABEL_AUTO_FILL_MEAL_TIME: "autoFillMealTime",
    CREATE_WORK_ORDER: "createWorkOrder",
    MARK_COMPLETE: "markComplete",
    EMAIL_PDF: "emailPDF",
    ADDENDUM: "addendum",
    RETURN_TO_WORK_ORDER: "returnToWorkOrder",
    LABEL_DATES_OF_PRODUCTION: "datesOfProduction",
    LABEL_EMAIL_TO: "EmailTo",
    LABEL_EMAIL_CC: "EmailCc",
    LABEL_EMAIL_FROM: "EmailFrom",
    LABEL_SUBJECT: "Subject",
    LEBEL_BODY: "Body",
    SEND_EMAIL_BTN: "SendEmail",
    GENERATE_TIME_SHEETS: "generateTimeSheets",
    GENERATE_TIME_SHEETS_SUCCESS: "timeSheetsCreated",
    GENERATE_TIME_SHEETS_FAILURE: "generateTimeSheetsFailed",
    ADDENDUM_TYPE: "addendumType",
    VIEW_WORK_ORDER_REPORT: "viewWorkOrdersReport",
    VIEW_WORK_ORDER_ADDENDUM: "workOrderAddendum",
    PAYROLL_ALLOCATION: "payrollAllocation",
    LABEL_TIN_VALIDATED: "tinValidated",
    ADD_TIME_VALIDATION: "AddTimeValidation",
    LABEL_GROSS_PAYMENT: "LabelGrossPayment",
    LABEL_SUBMIT_PAYMENT: "labelSubmitPayment",
    LABEL_SAVE_PAYMENT: "LabelSavePayment",
    SELECT_VENDOR: "SelectVendor",
    LABEL_EVENT_START_DATE: "eventStartDate",
    LABEL_EVENT_END_DATE: "eventEndDate",
    MARKET: "market",
    FUTA_RATES: "futaRates",
    SUTA_RATES: "sutaRates",
    WC_RATES: "wcRates",
    LABEL_NCCI_FOR_WC: "ncciForWC",
    LABEL_MANDATORY_DIRECT_DEPOSIT: "mandatoryDirectDeposit",
    TAXABLE_BASE_WAGES_CAPS: "taxableBaseWagesCaps",
    EXPENSE_RATE_TITLE: "ExpenseRate",
    EXPENSE_RATE_PERCENT: "ExpensePercent",
    EQUIPMENT_RATE_PERCENT: "EquipmentPercent",
    EQUIPMENT_RATE_TITLE: "EquipmentRateTitle",
    WORKER_COMP_CODE_UPDATED: "workersCompCodeUpdated",
    WORKER_COMP_CODE_ADDED: "workersCompCodeAdded",
    IMPORT_NEW_RULE_HEADING: "selectABulkTimesheetFileToUpload",
    IMPORT_NEW_RULE_TITLE: "paidSickLeaveRulesBulkLoad",
    VALIDATION_FAILED: "validationFailed",
    DHS_EXAMINE_DOCUMENTS: "DhsExamineDocuments",
    REVIEWED_DOCUMENT_TEXT: "ReviewedDocumentText",
    ADDITIONAL_INFOMATION_LABEL: "AdditionalInformationLabel",
    SELECTED_RECORD_ADDED_SUCCESSFULLY: "selectedRecordsAdded",
    EARNING_CODE_PAYMENT_TITLE: "earningCodeManualPayment",
    LABEL_ADMIN_PERCENT: "adminPercent",
    ADMIN_RATE_TITLE: "adminRateTitle",
    VENDOR_PAYMENT_BILLING_RATE: "VendorBillingRate",
    VENDOR_PAYMENT_RATE_TITLE: "VendorPaymentRate",
    POSTPONE_BTN: "postponebtn",
    CONTINUE_FOR_APPROVAL: "ContinueForApprovalBtn",
    NO_FURTHER_ACTION_BTN: "NoFurtherActionBtn",
    TOTAL: "total",
    EXPORT_SENT_TO_DARWIN: "ExportSentDarwin",
    SUTA_MARKUP_RATE_TITLE: "SUTAMarkupTitle",
    ADMIN_REPORTS_TITLE: "adminReportTitle",
    GENERAL_LIABILITY_MARKUP: "generalLiabilityMarkup",
    LABEL_FICA_RATE_PERCENT: "ficaRAtepercent",
    TITLE_FICA_RATE: "ficaRAte",
    CHOOSE_SUITABLE_ACTION: "ChooseSuitableAction",
    PROJECT_CHANGE: "projectChange",
    RETURN_VENDOR_PAYMENT: "returnVendorPayment",
    UPLOAD_TIMECARD_BACKUP_TITLE: "uploadTimeCard",
    UPLOAD_FAILED: "uploadFailed",
    ERROR_PASSWORD: "errorPassword",
    LABEL_EMPLOYER_IDENTIFICATION_NUMBER: "employerIdNumber",
    MODAL_TEXT_FOR_1099_WORKER: "modalTextFor1099Worker",
    LABEL_LEDGER: "ledger",
    TITLE_FUTA_RATES: "futaRates",
    LABEL_FUTA_RATES_PERCENT: "futaRatesPercent",
    TITLE_SUTA_RATES: "sutaRates",
    LABEL_SUTA_RATES_PERCENT: "sutaRatesPercent",
    CREATE_TIME_SHEET: "createTimeSheet",
    RETURNED_COMMENTS: "returnedComments",
    REALLOCATE_ACCOUNT: "reallocateTheAccount",
    LABEL_OWNER: "labelOwner",
    LABEL_PRE_BILL: "labelPreBill",
    RETURN_TO_PAYREEL_TEXT: "returnToPayreelText",
    LABEL_DEBIT: "labelDebit",
    LABEL_EXTENDS: "labelExtends",
    LABEL_ENTRY_TYPE: "labelentryType",
    LABEL_OPENING_BUDGET: "OpeningBudget",
    LABEL_CURRENT_BALANCE: "CurrentBalance",
    LABEL_PENDING_TRANSACTIONS: "pendingTransactions",
    LABEL_AVAILABLE_BALANCE: "availableBalance",
    LABEL_AMOUNT_AVAILABLE_FOR_VENDOR_INVOICES: "amountAvailableforVendorInvoices",
    LABEL_ACCOUNT_VALIDATION_FAILED: "accountValidationFailed",
    RETURN_TO_CLIENT: "returnToClient",
    RETURN_TO_CLIENT_COMMENTS: "rTcComments",
    RETURN_TO_PAYREEL_CLIENT_SUCCESS: "successfullyRTPC",
    RETURN_TO_PAYREEL_CLIENT_FAILURE: "failedRTPC",
    LABEL_FROM_DATE: "fromDate",
    LABEL_TO_DATE: "toDate",
    FILE_UPLOAD_1099_TITLE: "1099Statements",
    LABEL_FILE_UPLOAD_1099_TYPE: "1099FileUploadType",
    LABEL_W2_VENDOR: "w2Vendor",
    LABEL_1099_WORKER: "1099worker",
    SELECT_USER: "SelectUser",
    TITLE_WC_RATES: "wcRates",
    LABEL_WC_RATES_PERCENT: "wcPercent",
    LABEL_WORKERS_COMP_CODE: "workersCompCode",
    LABEL_ACA_REPORT_END_DATE: "reportEndDate",
    SYMMETRY_PAYROLL_POINT_BATCH_EXPORT: "symmetryPayrollPointBatchExport",
    GET_EXPORT: "getExport",
    BTN_EXPORT_FILE_FORM_HEADING: "useButtonToExport",
    USER_DETAILS_SAVED_SUCCESSFULLY: "userDetailsSavedSuccessFully",
    LABEL_VENDOR_BILLING_BY_PO: "LabelVendorBillingByPO",
    VENDOR_PAYMENT_OFFSET_VALUE: "VendorPaymentOffsetValue",
    SAVE_TIME: "SaveTime",
    LABEL_VIEW_MANUAL_PAYMENT_TITLE: "ViewManualPayment",
    PAY_STUB_DETAILS: "payStubDetails",
    LABEL_CHECK_NUMBER: "checkNumber",
    CLIENT_USER_MAPPING: "clientUserMapping",
    APPROVE_TIMESHEET_SUCCESS_MSG: "ApproveTimeSheetBatchRequestQueuedSuccessful",
    SELECT_EITHER_OPTION: "selectEitherOption",
    LABEL_CREATED_DATE_FROM: "createdDateFrom",
    LABEL_CREATED_DATE_TO: "createdDateTo",
    LABEL_SELECT_CLIENT: "pleaseSelectClient",
    EMPLOYER_POSTING_FORM_TITLE: "employerPostingFormTitle",
    EMPLOYER_POSTINGS: "employerPostings",
    EMPLOYER_POSTING_ADDED: "employerPostingAdded",
    EMPLOYER_POSTING_UPDATED: "employerPostingUpdated",
    PAID_SICK_LEAVE_RULES_FILE_UPLOAD: "PleaseChoosePaidSickLeaveRulesFiletoUpload",
    LABEL_CONFIRMATION: "confirmation",
    LABEL_PLEASE_SELECT_YEAR: "pleaseSelectYear",
    LABEL_RESULT: "labelResult",
    LABEL_ROLLOVER_SUCCESSFULLY: "rolloverSuccessfully",
    CLIENT_INFO_SAVED_MESSAGE: "ClientInfoSavedMesssge",
    CLIENT_INFO_SAVED_DESC: "ClientInfoSavedDesc",
    INVOICE_VOIDED: "invoicevoided",
    RETURN_TO_PAYROLL: "returntopayroll",
    LABEL_EARNING_CODE: "EarningCode",
    LABEL_DEFAULT_OPTION_ALL_CLIENT: "allClient",
    LABEL_WORKERS_COMPENSATION: "workersCompensation",
    LABEL_GENERAL_LIABILITY: "generalLiability",
    LABEL_ALL_CLIENTS: "allclients",
    LABEL_WORK_START_DATE: "workStartDate",
    LABEL_WORK_END_DATE: "workEndDate",
    REPORT_TYPE: "reporttype",
    INVOICED_START_DATE: "invoicestartdate",
    INVOICED_END_DATE: "invoiceenddate",
    STATE_SETUP_FORM_HEADING: "stateSetupFormHeading",
    EDIT_STATE_SETUP_FORM_HEADING: "editStateSetupFormHeading",
    LABEL_BATCHED_DATE: "batchedDate",
    LABEL_BATCHED_START_DATE: "batchedStartDate",
    LABEL_BATCHED_END_DATE: "batchedEndDate",
    SELECT_EITHER_OPTIONS: "selectEitherOptions",
    EMAIL_RESEND_EMPLOYEE_AGREEMENT: "EmailResendEmployeeAgreement",
    GEOLOCATION_FENCING: "GeoLocationFencing",
    INVOICING_INFO: "invoicingInfo",
    WC_MARKUP_TITLE: "wcMarkupTitle",
    LABEL_MARKUP_PERCENT: "MarkUpPercent",
    HOURS_MORE_THAN_ZERO: "hoursMoreThanZero",
    REPORT_NOT_EXIST: "reportDoesNotExist",
    ISSUE_SETTING_ALLOCATED: "issueSettingAllocated",
    ERROR_IN_SAVE_LOCATION_MAPPING: "ErrorinSaveLocationMapping",
    PERFORMANCE_COMPLIANCE_ITEMS: "performanceComplianceItems",
    LABEL_PHONE_ASH: "phoneAsh",
    NO_POSITION_ERROR: "noPositionError",
    EDIT_BELOW_FIELDS_FORM_HEADING: "Pleasechangethebelowfieldstoedit",
    LABEL_DATA: "data",
    LABEL_BEGIN_DATE: "begindate",
    FORMTITLE_EMPLOYEE_POSITION_REPORT: "employeepositionreport",
    LABEL_WORKED_START_DATE: "workedStartDate",
    LABEL_WORKED_END_DATE: "workedEndDate",
    RECORD_DELETED_SUCCESSFULLY: "recordDeletedSuccessfully",
    UNABLE_TO_LOAD_FILE: "unableToLoadFile",
    LABEL_LOCATION: "location",
    LABEL_MANAGEMENT_ADMIN_FEE_TERM_CODE: "managementAdminFeeTermCode",
    LABEL_SUPPLIER_NUMBER: "supplierNumber",
    LABEL_ADDRESS_LINE_1: "addressLine1",
    LABEL_ADDRESS_LINE_2: "addressLine2",
    LABEL_PAYREEL_LOGO: "payReelLogo",
    LABEL_TERMS: "terms",
    LABEL_PROJECT_NAME: "projectName",
    LABEL_INVOICE_LAYOUT: "invoiceLayout",
    LABEL_INVOICE_BY_EVENT_PROJECT: "invoiceByEventProject",
    LABEL_INCLUDE_SEPARATE_ADMIN_FEE: "includeSeparateAdminFee",
    LABEL_INCLUDE_INVOICE_NUMBER: "includeInvoiceNumber",
    LABEL_SEPARATE_ADMIN_FEE_INVOICE: "separateAdminFeeInvoice",
    LABEL_SEND_REMINDER_EMAIL_TO_CLIENT: "sendReminderEmailToClient",
    LABLE_MULTIPLE_INVOICE_EMAILS: "multipleInvoiceEmails",
    LABEL_NO_OF_DAYS: "noOfDays",
    LABEL_SEND_TO_DELIVERY_LIST: "sendToDeliveryList",
    LABEL_ATTACHED_REPORTS: "attachedReports",
    LABEL_EMAIL_SUBJECT: "emailSubject",
    LABEL_EMAIL_BODY: "emailBody",
    LABEL_INVOICE_DETAILS: "InvoiceDetails",
    LABEL_INVOICE_DELIVERY_DETAILS: "InvoiceDeliveryDetails",
    LABEL_CLIENT_ADDRESS: "clientAddress",
    LABEL_LOGO_ADMIN_PAYREEL_LOGO: "LabelLogoAdminPayreelLogo",
    LABEL_QVC_PA_MARKUP: "LabelQvcPAMarkup",
    LABEL_SEPERATE_ADMIN_FEE_INVOICE: "LabelSeparateAdminFeeInvoice",
    ERROR_CLIENT_INFO_DATA_NOT_EXIST: "ErrorClientInfoDataNotExist",
    LABEL_EMP_ID: "employeeId",
    COMPANY_NUMBER: "CompanyNumber",
    SWITCH_PER_DIEM_LOW_HIGH: "SwitchPerDienLowHigh",
    MANUAL_PAYMENT_CREATED_SUCCESS_MSG: "ManualPaymentcreatedsuccessfully",
    CHARGE_TYPE_EXISTS: "chargeTypeExists",
    DARWIN_CODE_EXISTS: "darwinCodeExists",
    AGENCY_RESPONSIBLE: "agencyResponsible",
    EDIT_AGENCY_RES_BTN: "EditAgencyResponsibleChecklist",
    CLIENT_ALERT_SETUP: "ClientSetupAlert",
    FEDERAL_UNEMPLOYMENT_INSERTED: "federalUnemploymentInserted",
    LABEL_NEXT: "Labelnext",
    LABEL_AMOUNT_TO: "LabelAmountTo",
    LABEL_AMOUNT_FROM: "LabelAmountFrom",
    MULTIPLE_EMAIL_BASED_LABEL: "MultipleEmailBasedLabel",
    LABEL_INSERT: "LabelInsert",
    ADD_NEW_CONDITION: "AddNewCondition",
    VALIDATE_AMOUNT_FAILURE: "ValidateAmountFailure",
    ADD_CLIENT_SAVED_MESSAGE: "AddClientSavedMessage",
    ADD_CLIENT_SAVED_DESC: "AddClientSavedDesc",
    ADD_CLIENT_CREATED_HEADING: "AddClientCreatedHeading",
    STATE_UNEMPLOYMENT_INSERTED: "stateUnEmploymentInserted",
    CREATED_SUCCESS_MSG: "Jobcreatedsuccessfully",
    LABEL_MANAGER_ACCESS: "managerAccess",
    LABEL_SUPERVISOR_ACCESS: "supervisorAccess",
    LABEL_CLIENT_WORKER_ACCESS: "clientWorkerAccess",
    SOCIAL_SECURITY_INSERTED: "socialSecurityInserted",
    VALIDATE_UPLOADED_FILE_TIMESHEET: "validateUploadedFileTimesheet",
    VALIDATE_UPLOADED_FILE_EXPENSE: "validateUploadedFileExpense",
    REPORT_MODE: "reportmode",
    LABEL_PAYREEL_COORDINATORS_LIST: "LabelPayReelCoordiantorsList",
    LABEL_QUARTER: "labelquarter",
    MULTIFACTOR_AUTHENTICATION_TITLE: "multiFactorAuthentication",
    LABEL_WEB_USERID: "webUserID",
    LABEL_ENABLE_2FACTOR_AUTHENTICATION: "enable2FactorAuthentication",
    TITLE_SEXUAL_HARASSMENT_TRAINING_REQUIREMENTS: "sexualHarassmentTrainingRequirements",
    LABEL_RE_TRAINING_REQUIREMENT: "reTrainingRequirement",
    LABEL_WEBSITE_TRAINING_LINK: "websiteTrainingLink",
    LABEL_ONE_YEAR: "oneYear",
    LABEL_TWO_YEAR: "twoYear",
    LABEL_THREE_YEAR: "threeYear",
    LABEL_FOUR_YEAR: "fourYear",
    LABEL_FIVE_YEAR: "fiveYear",
    LABEL_NO_REQUIREMENT: "noRequirement",
    LABEL_CLIENT_INFO: "LabelClientInfo",
    LABEL_ACCOUNTS: "LabelAccountS",
    LABEL_ALL_STATUSES: "allStatuses",
    LABEL_PAID_START_DATE: "paidStartDate",
    LABEL_PAID_END_DATE: "paidEndDate",
    HEADING_INFO: "infoHeading",
    EDIT_INFO_MSG: "noChangesfoundtoupdate",
    PER_DIEM_LEVEL: "PerDiemLevel",
    PER_DIEM_LEVEL_LOW_LABEL: "PerDiemLevelLow",
    PER_DIEM_LEVEL_HIGH_LABEL: "PerDiemLevelHigh",
    DELETE_DOCUMENT_CONFIRMATION: "deleteDocumentConfirmation",
    CLIENT_INVOICING_INFO_SAVED_SUCCESS: "ClientInvoicingInfOSavedSucess",
    NEP_WS_LOG_COMPANY_DIVISION_LABEL: "NepEsLogCompanyDivisonLabel",
    LABEL_SSN_4_DIGITS: "LabelSSN4Digits",
    CLICK_ON_CALENDAR_ICON: "clickOnCalendarIcon",
    LABEL_DEFAULT_MASTER_CLIENT: "defaultMasterClientSelect",
    BULK_IMPORT_HISTORY: "BulkImportHistory",
    BULK_VALIDATION_ERROR: "BulkValidationError",
    BULK_VALIDATION_CLOSE: "BulkValidationClose",
    NO_BULK_VALIDATION_ERROR: "NoBulkValidationError",
    NO_BULK_ERROR_FOUND: "NoErrorFound",
    FILE_NOT_FOUND: "FileNotFound",
    TIMECLOCK_DELETE_TIMESTAMP: "TimeClockDeleteTimestamps",
    DELETE_TIMESTAMP_HEADING: "DeleteTimestampHeading",
    SEARCH_EMPLOYEE_TIMECLOCK: "searchEmployeesTimeClock",
    ADD_MISSING_CLOCK_IN_OUT: "addMissingClockInOut",
    Enable_MobileTimeClockApp: "enableMobileApp",
    END_DATE_VALIDATION: "EndDateValidation",
    LABEL_EXPANDEDDATAVIEW: "LABEL_EXPANDEDDATAVIEW",
    SEARCH_INVOICES: "searchInvoices",
    LABEL_APPROVE_PAYMENTS_TITLE: "approvepayments",
    MY_PAYMENTS_REPORT: "mypayments",
    ALL_PAYMENTS_REPORT: "allpayments",
    APPROVE_SELECTED: "Approve Selected",
    SELECT_ALL: "Select All",
    LABEL_APPROVE_PAYMENTS: "LABEL_APPROVE_PAYMENTS",
    DESELECT_ALL: "DESELECT_ALL",
    CONFIRMATION_MESSAGE: "CONFIRMATION_MESSAGE",
    VENDOR_PAYMENTS: "VENDOR_PAYMENTS",
    LABEL_MANUAL_PAYMENT: "LABEL_MANUAL_PAYMENT",
    ATLEAST_ONE_RECORD_REQUIRED_TO_APPROVE: "ATLEAST_ONE_RECORD_REQUIRED_TO_APPROVE",
    LABEL_APPROVAL_STATUS: "LABEL_APPROVAL_STATUS",
};
export default TranslationConstant;
