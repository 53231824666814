var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { HOME_BREADCRUMB } from "components/shared";
import { localizedMessages } from "components/shared/utils";
// Default item for the supervisor dropdown
export var SUPERVISOR_DEFAULT_ITEM = {
    name: localizedMessages.LABEL_DEFAULT_OPTION_SELECT,
    clientUserID: null,
};
// Data list for the status radio buttons
export var PROJECT_STATUS_TYPE_DATA_LIST = [
    { label: localizedMessages.RADIO_ACTIVE_STATUS, value: 1 },
    { label: localizedMessages.RADIO_INACTIVE_STATUS, value: 2 },
    { label: localizedMessages.LABEL_BOTH, value: null },
];
export var PROJECT_FORM_STATUS_TYPE_DATA_LIST = [
    { label: localizedMessages.RADIO_ACTIVE_STATUS, value: 1 },
    { label: localizedMessages.RADIO_INACTIVE_STATUS, value: 2 },
];
// Heading data for the form, including tooltip and title
export var SEARCH_FORM_HEADING_DATA = {
    title: localizedMessages.SEARCH_PROMPT,
    tooltip: localizedMessages.ADD_PROJECT,
};
// Projects Screen Breadcrumb and PageTitle are constants since they don't change between renders
export var PROJECT_BREADCRUMB = [
    HOME_BREADCRUMB,
    {
        id: "projects",
        text: localizedMessages.PROJECTS,
    },
];
// Projects Screen Breadcrumb and PageTitle are constants since they don't change between renders
export var PROJECT_DETAILS_BREADCRUMB = __spreadArray(__spreadArray([], PROJECT_BREADCRUMB, true), [
    {
        id: "projectsDetails",
        text: localizedMessages.PROJECT_DETAILS,
    },
], false);
export var PROJECT_PAGE_TITLE = {
    title: localizedMessages.PROJECTS,
};
export var PROJECT_DETAILS_PAGE_TITLE = {
    title: localizedMessages.PROJECT_DETAILS,
};
export var DEFAULT_ITEM = {
    text: localizedMessages.LABEL_DEFAULT_OPTION_SELECT,
    value: null,
};
export var PER_DIEM_LEVEL_ID = [
    {
        label: localizedMessages.PER_DIEM_LEVEL_LOW_LABEL,
        value: 1,
    },
    {
        label: localizedMessages.PER_DIEM_LEVEL_HIGH_LABEL,
        value: 2,
    },
];
export var TOP_BUTTON_DATA = [
    {
        id: "back",
        title: "BACK_BUTTON",
        active: true,
        icon: "fa-arrow-left pe-1",
    },
    {
        id: "addWorker",
        title: "ADD_WORKER",
        active: true,
        icon: "fa fa-plus pe-1",
    },
    {
        id: "exportToExcel",
        title: "EXPORT_TO_EXCEL",
        active: true,
        icon: "",
    },
];
export var PROJECT_WORKER_TYPE = [
    {
        label: localizedMessages.INDIVIDUAL,
        value: 1,
    },
    {
        label: "Vendor",
        value: 2,
    },
];
export var PROJECT_WORKER_CUSTOM_FIELDS = [
    {
        customFieldId: 1,
        customFieldName: "EndDate",
        displayLabel: "End Date",
        customFieldDataTypeId: 1,
        customFieldDataTypeName: "text",
        customFieldDataId: 0,
        customFieldValue: "",
    },
    {
        customFieldId: 2,
        customFieldName: "Type",
        displayLabel: "Type",
        customFieldDataTypeId: 1,
        customFieldDataTypeName: "text",
        customFieldDataId: 0,
        customFieldValue: "",
    },
    {
        customFieldId: 3,
        customFieldName: "KitFeeAllowance",
        displayLabel: "Kit Fee Allowance",
        customFieldDataTypeId: 1,
        customFieldDataTypeName: "text",
        customFieldDataId: 0,
        customFieldValue: "",
    },
    {
        customFieldId: 4,
        customFieldName: "OvernightAllowance",
        displayLabel: "Overnight Allowance",
        customFieldDataTypeId: 1,
        customFieldDataTypeName: "text",
        customFieldDataId: 0,
        customFieldValue: "",
    },
];
export var PROJECT_WORKER_TYPE_INDIVIDUAL_DEFAULT_ITEM = {
    employeeName: localizedMessages.LABEL_DEFAULT_OPTION_SELECT,
    employeeID: null,
};
export var PROJECT_WORKER_TYPE_VENDOR_DEFAULT_ITEM = {
    companyName: localizedMessages.LABEL_DEFAULT_OPTION_SELECT,
    value: null,
};
export var YES_NO_OPTIONS = [
    {
        text: localizedMessages.YES,
        value: true,
    },
    {
        text: localizedMessages.NO,
        value: false,
    },
];
export var CLASSIFICATION_OPTIONS = {
    1: [
        {
            text: "W2",
            value: 1,
        },
    ],
    2: [
        {
            text: "Vendor",
            value: 2,
        },
        {
            text: "IC",
            value: 3,
        },
    ],
};
export var RATE_TYPE_OPTIONS = [
    {
        text: localizedMessages.RATE_TYPE_HOURLY,
        value: 1,
    },
    {
        text: localizedMessages.HALF_DAY,
        value: 2,
    },
    {
        text: localizedMessages.DAILY,
        value: 3,
    },
];
export var DAY_LENGTH_OPTIONS = [
    {
        text: "4 ".concat(localizedMessages.HOURS),
        value: 1,
    },
    {
        text: "5 ".concat(localizedMessages.HOURS),
        value: 2,
    },
    {
        text: "8 ".concat(localizedMessages.HOURS),
        value: 3,
    },
    {
        text: "10 ".concat(localizedMessages.HOURS),
        value: 4,
    },
    {
        text: "12 ".concat(localizedMessages.HOURS),
        value: 5,
    },
    {
        text: "14 ".concat(localizedMessages.HOURS),
        value: 6,
    },
];
export var WORKER_STATUS_OPTIONS = [
    {
        text: localizedMessages.WORKER_REQUEST_UNDER_REVIEW,
        value: 1,
    },
    {
        text: localizedMessages.CURRENTLY_ONBOARDING,
        value: 2,
    },
    {
        text: localizedMessages.APPROVED_TO_START_WORK,
        value: 3,
    },
    {
        text: localizedMessages.PAID_IN_FULL,
        value: 4,
    },
];
