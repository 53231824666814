var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { create } from "zustand";
import { getVendorPaymentList, addVendorPayment, deleteVendorPayment, addVendorPaymentDetails, fetchVendorPaymentDetailsList, fetchVendorPaymentDetailsListVZ, deleteVendorPaymentDetail, fetchVendorPaymentById, postSignAndSaveVendorPayment, fetchVendorPaymentDetailsById, deleteVendorPaymentFile, getVendorPaymentBackup, uploadPaymentBackupReceipt, fetchVendorDocumentList, postVendorDocument, getVendorSearch, getVendorPaymentStatus, getSearchVendorPayment, checkVendorInsurance, getApproveVendorPayment, vendorPaymentValidation, postApproveSelectedVendorPayment, saveReturntoVendor, getVendorPaymentStatusHistory, recallVendorPayment, saveNewAccount, saveNewSubAccount, saveNewSubAccountVZ, getVendorCode, getVendorInfo, getDDInfo, getAccountType, getVendorDocuments, getDocumentDetails, getVendorWebSettings, getVendorDarwinInformation, } from "services/vendors/vendors";
import { ComponentConstant } from "common/constant";
export var useVendorPaymentViewContext = create(function (set, get) { return ({
    vendorPaymentList: [],
    vendorPaymentDetailsList: [],
    vendorPaymentBackupList: [],
    vendorDocumentList: [],
    vendorSearch: [],
    vendorDetailsList: [],
    vendorPaymentStatus: [],
    vendorPaymentStatusSelectAll: [],
    searchVendorPayment: [],
    vendorPaymentDetailsListVZ: [],
    vendorInsurance: {},
    vendorPaymentStatusHistoryList: [],
    approveVendorPaymentList: [],
    vendorCodeForAddVendor: "",
    vendorInfo: {},
    ddInfo: {
        id: null,
        accountTypeId: null,
        accountNumber: "",
        routingNumber: "",
        ssn: "",
    },
    accountType: [],
    documentsList: [],
    editDocumentDetails: {},
    vendorWebSettings: {},
    darwinInformation: [],
    fetchVendorPaymentList: function (vendorID, vendorPaymentStatus) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVendorPaymentList(vendorID, vendorPaymentStatus)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorPaymentList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    postVendorPayment: function (postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, addVendorPayment(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    postVendorPaymentDerails: function (postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, addVendorPaymentDetails(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    deleteVendorPayment: function (vendorPaymentId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteVendorPayment(vendorPaymentId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchVendorPaymentDetailsList: function (vendorPaymentId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchVendorPaymentDetailsList(vendorPaymentId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorPaymentDetailsList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchVendorPaymentDetailsListVZ: function (vendorPaymentId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchVendorPaymentDetailsListVZ(vendorPaymentId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorPaymentDetailsListVZ: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    deleteVendorPaymentDetails: function (vendorPaymentDetailId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, deleteVendorPaymentDetail(vendorPaymentDetailId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchVendorPaymentById: function (vendorPaymentId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchVendorPaymentById(vendorPaymentId)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchVendorPaymentDetailsById: function (vendorPaymentDetailId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchVendorPaymentDetailsById(vendorPaymentDetailId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorDetailsList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    signAndSaveVendorPayment: function (postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, postSignAndSaveVendorPayment(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    deleteVendorPaymentFile: function (queryString) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, deleteVendorPaymentFile(queryString)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    fetchVendorPaymentBackup: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVendorPaymentBackup(queryString)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorPaymentBackupList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    uploadPaymentBackupReceipt: function (postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, uploadPaymentBackupReceipt(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    fetchVendorDocument: function (VendorID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetchVendorDocumentList(VendorID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorDocumentList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    addVendorDocument: function (postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, postVendorDocument(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    saveReturntoVendor: function (postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, saveReturntoVendor(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    fetchVendorSearch: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVendorSearch(queryString)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorSearch: response.data.items })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchVendorPaymentStatus: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVendorPaymentStatus()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorPaymentStatus: response.data, vendorPaymentStatusSelectAll: __spreadArray([
                            {
                                text: ComponentConstant.CHECK_ALL_DROPDOWN_VALUE,
                                value: null,
                            }
                        ], response.data, true) })); });
                    return [2 /*return*/];
            }
        });
    }); },
    fetchSearchVendorPayment: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getSearchVendorPayment(queryString)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { searchVendorPayment: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    resetSearchVendorPayment: function () {
        set(function (state) { return (__assign(__assign({}, state), { searchVendorPayment: [] })); });
    },
    checkVendorInsurance: function (VendorID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, checkVendorInsurance(VendorID)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorInsurance: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    checkVendorValidation: function (VendorID) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, vendorPaymentValidation(VendorID)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    fetchApproveVendorPayment: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getApproveVendorPayment(queryString)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { approveVendorPaymentList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    saveSelectedApproveVendorPayment: function (postData) { return __awaiter(void 0, void 0, void 0, function () { return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, postApproveSelectedVendorPayment(postData)];
            case 1: return [2 /*return*/, _a.sent()];
        }
    }); }); },
    getVendorPaymentStatusHistory: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVendorPaymentStatusHistory(queryString)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorPaymentStatusHistoryList: response.data })); });
                    return [2 /*return*/, response];
            }
        });
    }); },
    recallVendorPayment: function (dataObj) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, recallVendorPayment(dataObj)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveNewAccount: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveNewAccount(queryString)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveNewSubAccount: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveNewSubAccount(queryString)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    saveNewSubAccountVZ: function (queryString) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, saveNewSubAccountVZ(queryString)];
                case 1:
                    response = _a.sent();
                    return [2 /*return*/, response];
            }
        });
    }); },
    getVendorCode: function (clientId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVendorCode(clientId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorCodeForAddVendor: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getVendorInfo: function (vendorId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVendorInfo(vendorId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorInfo: response.data })); });
                    return [2 /*return*/, response.data];
            }
        });
    }); },
    getDDInfo: function (vendorId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDDInfo(vendorId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { ddInfo: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getAccountType: function () { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getAccountType()];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { accountType: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getVendorDocuments: function (vendorId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVendorDocuments(vendorId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { documentsList: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getDocumentDetails: function (vendorDocumentId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDocumentDetails(vendorDocumentId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { editDocumentDetails: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    resetEditVendorDocument: function () {
        set(function (state) { return (__assign(__assign({}, state), { editDocumentDetails: {} })); });
    },
    getVendorSettings: function (vendorId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVendorWebSettings(vendorId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { vendorWebSettings: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    getDarwinInformation: function (vendorId) { return __awaiter(void 0, void 0, void 0, function () {
        var response;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getVendorDarwinInformation(vendorId)];
                case 1:
                    response = _a.sent();
                    set(function (state) { return (__assign(__assign({}, state), { darwinInformation: response.data })); });
                    return [2 /*return*/];
            }
        });
    }); },
    resetVendorInfo: function () {
        set(function (state) { return (__assign(__assign({}, state), { vendorInfo: {} })); });
    },
}); });
