var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from "react";
import { AppConstant, BreadcrumbComponent, DialogBox, PageTitle, TopButtons, Transform, TranslationConstant } from "components/shared"; // Shared components for breadcrumbs and page titles
import { createEncodedURL, createQueryString, decodedURL, downloadDocument, getUserDetailsFromLocalStorage, localizedMessages, removeFalsyValueFromObj, } from "components/shared/utils"; // Utility to fetch logged-in user details
// Context to fetch client data for I9 verification
// Context to fetch supervisor list
// Context to fetch client work location list
import { PROJECT_DETAILS_BREADCRUMB, PROJECT_DETAILS_PAGE_TITLE } from "../constants"; // Constants for breadcrumbs and page title
import useProjectsStore from "store/projects/projectsStore";
import { ProjectSummary } from "./ProjectSummary";
import { WorkersGrid } from "./WorkersGrid";
import { useNavigate } from "react-router-dom";
import { TOP_BUTTON_DATA } from "../constants";
import { useI9VerificationViewContext } from "store/i9reverification/i9reverification";
import { WorkerFormDialog } from "./WorkerFormDialog";
import useUpdateDialogContent from "hooks/useUpdateDialogContent";
var pageTitle = PROJECT_DETAILS_PAGE_TITLE;
var ProjectDetails = function () {
    var navigate = useNavigate();
    var _a = decodedURL(), accountId = _a.accountId, accountProjectId = _a.accountProjectId, subAccountId = _a.subAccountId, subAccountProjectId = _a.subAccountProjectId, searchParamsData = _a.searchParamsData;
    var _b = useProjectsStore(), fetchProjectDetails = _b.fetchProjectDetails, fetchProjectWorkers = _b.fetchProjectWorkers, exportToExcel = _b.exportToExcel; // Fetch projects from the store
    var _c = useI9VerificationViewContext(), getClientData = _c.getClientData, clientData = _c.clientData;
    var backUrl = createEncodedURL("/projects", { searchParamsData: searchParamsData });
    PROJECT_DETAILS_BREADCRUMB[1].route = backUrl;
    // Dialog hook to update content and visibility
    var _d = useUpdateDialogContent(), dialogContent = _d.dialogContent, setDialogContent = _d.setDialogContent, showDialog = _d.showDialog, setShowDialog = _d.setShowDialog;
    // State to manage the visibility of the Worker Form modal
    var _e = useState(false), showWorkerForm = _e[0], setShowWorkerForm = _e[1];
    // State to hold worker data when editing
    var _f = useState(null), workerData = _f[0], setWorkerData = _f[1];
    // State to manage force refresh grid list
    var _g = useState(false), refreshWorkers = _g[0], setRefreshWorkers = _g[1];
    var _h = useState(), projectDetails = _h[0], setProjectDetails = _h[1];
    // Fetch logged-in user details from local storage
    var loggedInDetails = getUserDetailsFromLocalStorage(AppConstant.CORP_TYPE_APPLICATION);
    // Fetch client data based on the logged-in user's `ClientId`
    useEffect(function () {
        if (loggedInDetails === null || loggedInDetails === void 0 ? void 0 : loggedInDetails.ClientId) {
            getClientData(loggedInDetails.ClientId);
        }
    }, [loggedInDetails === null || loggedInDetails === void 0 ? void 0 : loggedInDetails.ClientId, getClientData]);
    // Update the page title and breadcrumb when the project alias is available
    useEffect(function () {
        if (clientData === null || clientData === void 0 ? void 0 : clientData.projectAlias) {
            pageTitle.title = "".concat(clientData.projectAlias, " ").concat(localizedMessages.DETAILS);
            PROJECT_DETAILS_BREADCRUMB[1].text = clientData.projectAlias;
            PROJECT_DETAILS_BREADCRUMB[2].text = "".concat(clientData.projectAlias, " ").concat(localizedMessages.DETAILS);
        }
    }, [clientData === null || clientData === void 0 ? void 0 : clientData.projectAlias]);
    useEffect(function () {
        var fetchData = function () { return __awaiter(void 0, void 0, void 0, function () {
            var queryString, responseData;
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        queryString = createQueryString(removeFalsyValueFromObj({
                            ClientId: loggedInDetails.ClientId,
                            accountId: accountId,
                            accountProjectId: accountProjectId,
                            subAccountId: subAccountId,
                            subAccountProjectId: subAccountProjectId,
                        }));
                        return [4 /*yield*/, fetchProjectDetails(queryString)];
                    case 1:
                        responseData = _b.sent();
                        setProjectDetails(responseData.data);
                        if ((_a = responseData.data) === null || _a === void 0 ? void 0 : _a.accountProjectId) {
                            fetchWorkers();
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        fetchData();
    }, [accountId, accountProjectId, subAccountId, subAccountProjectId]); // Dependencies - run when 'projects' updates
    useEffect(function () {
        fetchWorkers();
    }, [refreshWorkers]);
    var fetchWorkers = function () { return __awaiter(void 0, void 0, void 0, function () {
        var queryString;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    queryString = createQueryString(removeFalsyValueFromObj({ accountProjectId: accountProjectId, subAccountProjectId: subAccountProjectId }));
                    return [4 /*yield*/, fetchProjectWorkers(+loggedInDetails.ClientId, queryString)];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    var handleButtonClick = function (type) {
        switch (type.id) {
            case "back": {
                navigate(backUrl);
                break;
            }
            case "addWorker": {
                setWorkerData(null);
                setShowWorkerForm(true);
                break;
            }
            case "exportToExcel": {
                handleExport();
                break;
            }
            default:
                break;
        }
    };
    var handleExport = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, queryString, _a, base64string, fileName, fileExtension, e_1;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    _b.trys.push([0, 5, , 6]);
                    queryString = createQueryString(removeFalsyValueFromObj({
                        accountProjectId: accountProjectId,
                        subAccountProjectId: subAccountProjectId,
                    }));
                    return [4 /*yield*/, exportToExcel(queryString, loggedInDetails.ClientId)];
                case 1:
                    response = _b.sent();
                    if (!(AppConstant.HTTP_SUCCESS_CODES.includes(response.status) && response.data)) return [3 /*break*/, 3];
                    _a = response.data, base64string = _a.base64string, fileName = _a.fileName, fileExtension = _a.fileExtension;
                    return [4 /*yield*/, downloadDocument(base64string, fileName, "vnd.ms-excel", fileExtension)];
                case 2:
                    _b.sent();
                    return [3 /*break*/, 4];
                case 3: throw new Error();
                case 4: return [3 /*break*/, 6];
                case 5:
                    e_1 = _b.sent();
                    console.log(e_1);
                    setDialogContent({
                        headingText: TranslationConstant.FAILED,
                        displayMsg: TranslationConstant.SOMETHING_WENT_WRONG,
                    });
                    setShowDialog(true);
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); };
    var handleEditWorker = function (data) {
        setWorkerData(data);
        setShowWorkerForm(true); // Show the worker form modal
    };
    // Only render the forms and grid if `clientData` is available
    return (_jsxs("div", __assign({ className: "project-details" }, { children: [_jsx(BreadcrumbComponent, { items: PROJECT_DETAILS_BREADCRUMB }), _jsx(PageTitle, { data: pageTitle }), _jsx(TopButtons, { styleClass: "mb-2", buttonData: TOP_BUTTON_DATA, onClick: handleButtonClick }), projectDetails && (_jsx(ProjectSummary, { projectData: projectDetails, clientData: clientData })), _jsx("div", __assign({ className: "pad-x-20 col-12 mt-4 mb-2" }, { children: _jsx("h4", { children: _jsx(Transform, { mkey: TranslationConstant.WORKERS }) }) })), _jsx(WorkersGrid, { editWorkerData: handleEditWorker, handleRefreshGrid: function () { return setRefreshWorkers(!refreshWorkers); }, loggedInDetails: loggedInDetails }), showWorkerForm && (_jsx(WorkerFormDialog, { projectAccountIds: { accountProjectId: accountProjectId, subAccountProjectId: subAccountProjectId }, loggedInDetails: loggedInDetails, workerEditData: workerData, closeDialog: function () {
                    setRefreshWorkers(!refreshWorkers); // Function to refresh the list
                    setShowWorkerForm(false); // Function to close the form
                } })), _jsx(DialogBox, __assign({}, dialogContent, { isDialogOpen: showDialog, onModalChangeState: function () { return setShowDialog(false); }, cancelText: _jsx(Transform, { mkey: TranslationConstant.CLOSE_BUTTON }) }))] })));
};
export default ProjectDetails;
