var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { formatDate, Transform, TranslationConstant } from "components/shared";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { localizedMessages } from "components/shared/utils";
// Component to display the summary details of a project
export var ProjectSummary = function (_a) {
    var projectData = _a.projectData, clientData = _a.clientData;
    // Function to format project work locations into a readable HTML string
    var formattedProjectWorkLocations = function (projectWorkLocations) {
        return projectWorkLocations
            .map(function (location) {
            var _a;
            return "".concat(location.workLocationName, " - ").concat(location.workLocationAddress, ", ").concat((_a = location.workLocationCity) === null || _a === void 0 ? void 0 : _a.toUpperCase(), ", ").concat(location.stateShortName.toUpperCase(), ", ").concat(location.zipCode, ";");
        })
            .join("<br />");
    };
    return (_jsx(PanelBar, __assign({ className: "mt-4 transparent-accordion-panel project-summary" }, { children: _jsx(PanelBarItem, __assign({ title: "".concat(clientData.projectAlias || localizedMessages.LABEL_PROJECT, " ").concat(localizedMessages.HEADING_INFO), expanded: false }, { children: _jsx("div", __assign({ className: "p-2" }, { children: _jsxs("div", __assign({ className: "row" }, { children: [_jsxs("div", __assign({ className: "col-12 col-md-6 col-lg-4 mb-2" }, { children: [_jsxs("div", __assign({ className: "col-12 mb-2" }, { children: [_jsxs("strong", { children: [_jsx(Transform, { mkey: TranslationConstant.PROJECT_NAME }), ":", " "] }), projectData.projectName] })), _jsxs("div", __assign({ className: "col-12 mb-2" }, { children: [_jsxs("strong", { children: [_jsx(Transform, { mkey: TranslationConstant.LABEL_START_DATE }), ":", " "] }), formatDate(new Date(projectData.startDate), "MM/dd/yyyy")] })), _jsxs("div", __assign({ className: "col-6 mb-2" }, { children: [_jsxs("strong", { children: [_jsx(Transform, { mkey: TranslationConstant.LABEL_END_DATE }), ":", " "] }), projectData.endDate
                                            ? formatDate(new Date(projectData.endDate), "MM/dd/yyyy")
                                            : null] })), (clientData === null || clientData === void 0 ? void 0 : clientData.isOverrideProjectApprover) && (_jsxs(_Fragment, { children: [_jsxs("div", __assign({ className: "col-12 mb-2" }, { children: [_jsxs("strong", { children: [_jsx(Transform, { mkey: TranslationConstant.APPROVING_SUPERVISOR }), ":", " "] }), projectData.clientSupervisor1Name] })), _jsxs("div", __assign({ className: "col-12 mb-2" }, { children: [_jsxs("strong", { children: [_jsx(Transform, { mkey: TranslationConstant.SECONDARY_SUPERVISOR }), ":", " "] }), projectData.clientSupervisor2Name] }))] }))] })), _jsx("div", __assign({ className: "col-12 col-md-6 col-lg-4 mb-2" }, { children: _jsxs("div", __assign({ className: "col-12 mb-2" }, { children: [_jsxs("strong", { children: [_jsx(Transform, { mkey: TranslationConstant.WORK_LOCATION }), ":", " "] }), _jsx("span", { dangerouslySetInnerHTML: {
                                            __html: formattedProjectWorkLocations(projectData.projectWorkLocations),
                                        } })] })) })), _jsxs("div", __assign({ className: "col-12 col-md-6 col-lg-4 mb-2" }, { children: [_jsxs("div", __assign({ className: "col-12 mb-2" }, { children: [_jsxs("strong", { children: [_jsx(Transform, { mkey: TranslationConstant.DESCRIPTION }), ":", " "] }), projectData.description] })), _jsxs("div", __assign({ className: "col-12 mb-2" }, { children: [_jsxs("strong", { children: [_jsx(Transform, { mkey: TranslationConstant.LABEL_NOTES }), ":", " "] }), projectData.notes] })), _jsxs("div", __assign({ className: "col-12 mb-2" }, { children: [_jsxs("strong", { children: [_jsx(Transform, { mkey: TranslationConstant.INCLUDE_ON_INVOICE }), ":", " "] }), projectData.includeOnInvoice] }))] }))] })) })) })) })));
};
